import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginForm from "./login_form";
import Dashboard from "./dashboard";
import Groups from "./groups";
import Members from "./members";
import Users from "./users";
import Settings from "./settings";
import Report from "./reports";

import ResetPasswordForm from "./reset";
import Loans from "./loans";

class CustomRouter extends React.Component {
  constructor(props){
    super(props);
    this.handleAuth = this.handleAuth.bind(this);
  }
  handleAuth(){
    console.log("hello")
  }
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={LoginForm} />
          <Route exact path="/ingia" component={LoginForm} />
          <Route
            path="/mwanzo"
            render={(props) => <Dashboard user={null} {...props} />}
          />
           <Route
            path="/vikundi"
            render={(props) => <Groups data={null} {...props} />}
          />
            <Route
            path="/wanachama"
            render={(props) => <Members data={null} {...props} />}
          />
           <Route
            exact path="/mikopo"
            render={(props) => <Loans data={null} {...props} />}
          />
         
         <Route
            exact path="/watumiaji"
            render={(props) => <Users data={null} {...props} />}
          />
         
         <Route
            exact path="/ripoti"
            render={(props) => <Report />}
          />
         
         <Route
            exact path="/mipangilio"
            render={(props) => <Settings />}
          />
          <Route path="/toka" component={LoginForm} />
          <Route path="/nywila" component={ResetPasswordForm} />
          <Route component={LoginForm} />
        </Switch>
      </Router>
    );
  }
}
export default CustomRouter;
