import React from "react";
import Commons from "../utils/commons";

const RoleItem = (props)=>{
    var role = props.role;
    
    const roleLabels = Commons.getRoleListLabels();

    const handleEditClick=()=>{
        props.onEdit(role);
    }
    const handleDeleteClick=()=>{
        props.onDelete(role);
    }
    
    return(
        <div className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-start align-items-center text-left list-item "+((props.position%2==0) ? "list-item-shade":"bg-light")}>
            {role ? (
            roleLabels.map((d,i)=>{
                if(d.key == "edit") return (<div key={d.key} className={"col-lg-3 col-md-3 text-left" }><span className="material-icons" onClick={handleEditClick}>edit</span></div>);
                else if(d.key == "delete") return <div key={d.key} className={"col-lg-3 col-md-3 text-left text-danger" }><span className="material-icons" onClick={handleDeleteClick}>delete</span></div>;
                else return(<div key={i} className={"col-lg-3 col-md-3 text-left" }>{role[d.key]}</div>);
             
            })
              
            ):(
                <div className="text-center col-lg-12 col-md-12">Hakuna taarifa</div>
            )
            }
        </div>
    )
}
export default RoleItem;