import React from "react";
import {Bar, Line,Pie} from "react-chartjs-2"
import Commons from "../utils/commons";
import Header from "./header";
import LoginForm from "./login_form";
import Spinner from "./spinner";
import Popup from "./popup";
import SideBar from "./sidebar";
import commons from "../utils/commons";

class Dashboard extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isAuth:false,prevPath:"",isLoading:false,download:false,nospace:false
        }
        this.storage = window.localStorage;
        this.session = window.sessionStorage;
        this.loginState = (this.session.getItem("loginState") != null) ? JSON.parse(this.session.getItem("loginState")):null;
        this.storedData = {
            all_groups:[],all_members:[],groups:[],members:[],users:[],settings:[],igroups:[],imembers:[],group_fields:[],member_fields:[],roles:[]
        }
        this.forStorage = {
            users:[],settings:[],group_fields:[],member_fields:[],roles:[]
        }
        this.memberLoans = 0;
        this.groupLoans = 0;
        this.memberHisa = 0;
        this.groupHisa = 0;
        this.memberCount = 0;
        this.groupCount = 0;
        this.memberZoneTz =[];
        this.memberZoneZnz =[];
        this.memberAgeTz =[];
        this.memberAgeZnz =[];
        this.wilayas =[];
        this.groupZone =[];
        this.wilayaData =[];
        this.all_groups = [];
        this.all_members = [];

        this.handleAuth = this.handleAuth.bind(this);
        this.handleSignout = this.handleSignout.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.loadData = this.loadData.bind(this);
        this.prep=this.prep.bind(this);

        if(window.performance){
            if(performance.navigation.type === 1){
                this.loadData().then(result=>{
                    this.prep(result);
                   
                })
                .catch(e=>{
                    console.error("error: ",e);
                })
                .finally(()=>{
                    this.prepareData(this.storedData);
                    this.setState({isLoading:false});

                    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                        this.setState({download:true})
                         
                    }
                })
            }
        }
    }
    prep(result){
        this.all_groups = result.groups.map(g=>{
            let ng = g;ng.group_id = parseInt(g.group_id);return ng;
        })
        this.all_members = result.members.map(m=>{
            let mb = m;
                mb.mkopo = m.mkopo ? m.mkopo : 0;
                mb.marejesho = m.marejesho ? m.marejesho : 0;
                mb.hisa = m.hisa == null || m.hisa == "hakuna" || m.hisa == "" ? 0: m.hisa;
                mb.thamani_ya_hisa = m.thamani_ya_hisa == null || m.thamani_ya_hisa == "hakuna" || m.thamani_ya_hisa == "" ? 0:  m.thamani_ya_hisa;
                mb.idadi_ya_mikopo = m.idadi_ya_mikopo == null || m.idadi_ya_mikopo == "hakuna" || m.idadi_ya_mikopo == "" ? 0:  m.idadi_ya_mikopo;
                var dob = new Date(Date.now()).getFullYear() - (m.mwaka == null || m.mwaka == "hakuna" || m.mwaka == "" ? 0:m.mwaka);
                mb.umri = dob;
                return mb;
        })
        if(this.loginState.ngazi == Commons.NGAZI_ADMIN){
            this.forStorage.users = result.users.filter(u=>u.kanda == this.loginState.kanda);
            this.storedData.users = result.users.filter(u=>u.kanda == this.loginState.kanda);
            this.storedData.groups = result.groups.filter(g=>g.kanda == this.loginState.kanda);
            this.storedData.members = result.members.filter(m=>m.kanda == this.loginState.kanda);
        
        }
        else if(this.loginState.ngazi == Commons.NGAZI_MANAGER){
            this.forStorage.users = result.users.filter(u=>u.mkoa && u.mkoa.toLowerCase().replaceAll(" ","_")== this.loginState.mkoa.toLowerCase().replaceAll(" ","_"));
            this.storedData.users = result.users.filter(u=>u.mkoa && u.mkoa.toLowerCase().replaceAll(" ","_")== this.loginState.mkoa.toLowerCase().replaceAll(" ","_"));
            this.storedData.groups = result.groups.filter(g=>g.mkoa && g.mkoa.toLowerCase().replaceAll(" ","_")== this.loginState.mkoa.toLowerCase().replaceAll(" ","_"));
            this.storedData.members = result.members.filter(m=>m.mkoa && m.mkoa.toLowerCase().replaceAll(" ","_")== this.loginState.mkoa.toLowerCase().replaceAll(" ","_"));
            
        }
        else if(this.loginState.ngazi == Commons.NGAZI_SUPER) {
            this.forStorage.users = result.users;
            this.storedData.users = result.users;
            this.storedData.groups = result.groups;
            this.storedData.members = result.members;
        
        }
        else{
            this.storedData.groups = result.groups.filter(g=>g.wilaya && g.wilaya.toLowerCase() == this.loginState.wilaya.toLowerCase());
            this.storedData.members = result.members.filter(m=>m.wilaya && m.wilaya.toLowerCase() == this.loginState.wilaya.toLowerCase());
        
        }
        this.forStorage.roles = result.roles;
        this.forStorage.group_fields = result.group_fields.map(f=>f.Field.toLowerCase());
        this.forStorage.member_fields = result.member_fields.map(f=>f.Field.toLowerCase());

        this.storage.setItem("data",JSON.stringify(this.forStorage));
    }
    loadData=()=>{
        return new Promise((resolve,reject)=>{
           
            if(this.loginState){
                let user_url = Commons.getURL("data")+"/index.php";
                var options={
                    method:"POST",
                    body:JSON.stringify({auth:this.loginState}),
                    headers:{'Content-type':'application/json'}
                }
                fetch(user_url,options)
                .then(res=>res.json())
                .then(result=>{
                    let hasMore = {members:0,groups:0};
                    if(result.groups.length > commons.PAGE_LIMIT) hasMore.groups = result.groups.length;
                    if(result.members.length > commons.PAGE_LIMIT) hasMore.members = result.members.length;
                    this.session.setItem("more",JSON.stringify(hasMore));
                    resolve(result);
                })
                .catch(e=>{
                    console.log(e);
                    reject("Pole... kuna tatizo limetokea");
                })
                
            }
            
        })
    }
    handleSignout=(e)=>{
        e.preventDefault();
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.location.href = "/";
    }
    prepareData = (data)=>{
        this.memberLoans = 0;
        this.memberCount = 0;
        this.memberHisa = 0;
        this.groupCount = 0;
        this.groupLoans = 0;
        this.groupHisa = 0; 
        var groups = data.groups;
        var members = data.members.map(m=>{
            let mb = m;
            var dob = new Date(Date.now()).getFullYear() - (m.mwaka == null || m.mwaka == "hakuna" || m.mwaka == "" ? 0:m.mwaka);
            mb.umri = dob;
            mb.kikundi = groups.filter(g=>g.id == parseInt(m.kikundi))[0];
            mb.hisa = m.hisa == null || m.hisa == "hakuna" || m.hisa == "" ? 0 : parseInt(m.hisa);
            mb.mkopo = m.mkopo == null || m.mkopo == "hakuna" || m.mkopo == "" ? 0 : parseInt(m.mkopo);
            mb.marejesho = m.marejesho == null || m.marejesho == "hakuna" || m.marejesho == "" ? 0 : parseInt(m.marejesho);
            
            return mb;
        });



        this.groupCount = groups.length;
        this.memberCount = members.length;
        
        members.forEach(l=>{
            let loan = l.mkopo=="" || l.mkopo == null || l.mkopo == "hakuna" ? 0:parseInt(l.mkopo);
           
            this.memberLoans +=loan;
            let hisa = l.thamani_ya_hisa=="" || l.thamani_ya_hisa == null || l.thamani_ya_hisa == "hakuna" ? 0:parseInt(l.thamani_ya_hisa);
            this.memberHisa += hisa;
            // this.groupHisa += parseInt(l.hisa);
        });
        groups.forEach(l=>{
            let kiasi = l.mkopo_kiasi=="" || l.mkopo_kiasi == null || l.mkopo_kiasi == "hakuna" ? 0:parseInt(l.mkopo_kiasi);
            this.groupLoans +=kiasi;
            let hisa = l.thamani_ya_hisa=="" || l.thamani_ya_hisa == null || l.thamani_ya_hisa == "hakuna" ? 0:parseInt(l.thamani_ya_hisa);
            this.groupHisa += hisa;
        });
        this.groupZone = [this.all_groups.filter(g=>parseInt(g.kanda) == 1).length,this.all_groups.filter(g=>parseInt(g.kanda) == 0).length];
        
        
        this.memberAgeZnz = [this.all_members.filter(m=>m.umri < 18 && m.kanda == 1).length,this.all_members.filter(m=>m.umri > 17 && m.umri < 36 && m.kanda == 1).length,this.all_members.filter(m=>m.umri >35 && m.umri < 61 && m.kanda == 1).length,this.all_members.filter(m=>m.umri >60 && m.kanda == 1).length];
        this.memberAgeTz = [this.all_members.filter(m=>m.umri < 18 && m.kanda == 0).length,this.all_members.filter(m=>m.umri > 17 && m.umri < 36 && m.kanda == 0).length,this.all_members.filter(m=>m.umri >35 && m.umri < 61 && m.kanda == 0).length,this.all_members.filter(m=>m.umri > 60&& m.kanda == 0).length]
       
        this.memberZoneZnz = [this.all_members.filter(m=>m.jinsia == 0 && m.kanda == 1).length,this.all_members.filter(m=>m.jinsia == 1 && m.kanda == 1).length];
        this.memberZoneTz = [this.all_members.filter(m=>m.jinsia == 0 && m.kanda == 0).length,this.all_members.filter(m=>m.jinsia == 1 && m.kanda == 0).length]
        this.wilayas = [... new Set(groups.map(g=>g.wilaya.toUpperCase()))];
        this.wilayaData = [];
        this.wilayas.forEach(w=>{
            this.wilayaData.push(groups.filter(g=>g.wilaya.toUpperCase() == w.toUpperCase()).length)
        })
    }
    
    handleAuth=(u)=>{
        this.loginState = JSON.parse(this.session.getItem("loginState"));
        this.storedData = JSON.parse(this.storage.getItem("data"));
        this.prepareData(this.storedData);
    }
   componentDidMount(){     
        if(this.loginState){
            this.setState({isAuth:true,isLoading:true},()=>{
                this.loadData().then(result=>{
                   this.prep(result);
                   
                })
                .catch(e=>{
                    console.error("error: ",e);
                })
                .finally(()=>{
                    this.prepareData(this.storedData);
                    this.setState({isLoading:false});

                    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                        this.setState({download:true})
                         
                    }
                })
            })
        }

    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            this.setState({ prevPath: this.props.location })
        }
    }
    render(){
        if(this.state.isAuth){
            return(
                <div>
                    {this.state.download ? <Popup type="download" msg="Tafadhali pakua app ya FGMS kwa ajili ya simu ya android" title="Pakua Toleo la Android" action="pakua"/>:(this.state.nospace ?
                     <Popup type="space" msg="Taarifa ni nyingi sana; tafadhali kuwa mvumilivu wakati nakuletea taarifa hizo" title="Taarifa Nyingi Sana" action="SAWA"/>:null)}
                <Header auth={this.loginState} />
                <div className="container-fluid">
                    <div className="row">
                      
                       <SideBar selected="mwanzo" auth={this.loginState} />
                        <main className="mx-auto col-md-10 col-lg-10 d-flex flex-wrap">
                        {this.state.isLoading ? <Spinner isShowing={this.state.isLoading} size="spinner-large"/>:<>
                            <div className="col-md-12 col-lg-12 my-2 mx-auto d-flex flex-wrap justify-content-center row ">
                                <h2 className="py-2">{this.loginState.ngazi == commons.NGAZI_SUPER ? "KARIBU FGMS": "KARIBU "+ (this.loginState.kanda == 0 ? "FGMS - TANZANIA BARA":"FGMS - ZANZIBAR")}</h2>
                                {this.loginState.ngazi != Commons.NGAZI_ADMIN && this.loginState.ngazi != Commons.NGAZI_SUPER ?<h3 className="py-2">{this.loginState.ngazi == Commons.NGAZI_DATA || this.loginState.ngazi == Commons.NGAZI_EVAL ? "WILAYA YA "+ (this.loginState.wilaya.replaceAll("_"," ").toUpperCase()) :"MKOA WA "+(this.loginState.mkoa.replaceAll("_"," ").toUpperCase()) }</h3>:null}
                            <div id="sum" className="shadow col-sm-5 offset-sm-1 col-md-3 mx-auto col-lg-3 mx-1 my-2 border summary-card">
                                    <p>Jumla ya Vikundi</p>
                                    <p className="text-success text-center fw-bold">{Commons.thousandSeparator(this.groupCount)}</p>
        
                                </div>
                                <div id="sum" className="shadow col-sm-5 offset-sm-1 col-md-3 mx-auto col-lg-3 mx-1 my-2 border summary-card">
                                    <p>Mikopo ya Vikundi</p>
                                    <p className="text-success text-center fw-bold">Tsh. {Commons.thousandSeparator(this.groupLoans)}</p>
        
                                </div>
                                <div id="sum" className="shadow col-sm-5 offset-sm-1 col-md-3 mx-auto col-lg-3 mx-1 my-2 border summary-card">
                                    <p>Hisa za Vikundi</p>
                                    <p className="text-success text-center fw-bold">TSh. {Commons.thousandSeparator(this.groupHisa)}</p>
        
                                </div>
                                </div>
                                <div className="col-md-12 col-lg-12 my-2 mx-auto d-flex flex-wrap justify-content-center row ">
                            
                                <div id="sum" className="shadow col-sm-5 offset-sm-1 col-md-3 mx-auto col-lg-3 mx-1 my-2 border summary-card">
                                    <p >Jumla ya Wanachama</p>
                                    <p className="text-success text-center fw-bold">{Commons.thousandSeparator(this.memberCount)}</p>
                                </div>
                                <div id="sum" className="shadow col-sm-5 offset-sm-1 col-md-3 mx-auto col-lg-3 mx-1 my-2 border summary-card">
                                    <p>Mikopo ya Wanachama</p>
                                    <p className="text-success text-center fw-bold">Tsh. {Commons.thousandSeparator(this.memberLoans)}</p>
                                </div>
                                <div id="sum" className="shadow col-sm-5 offset-sm-1 col-md-3 mx-auto col-lg-3 mx-1 my-2 border summary-card">
                                    <p >Hisa za Wanachama</p>
                                    <p className="text-success text-center fw-bold">{Commons.thousandSeparator(this.memberHisa)}</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-12 my-2 mx-auto shadow row">
                                <div id="chart2" className="col-md-4 ms-sm-auto col-lg-4 my-4 py-4 mx-1">
                                    <h5>Usajili wa Vikundi kwa Kanda</h5>
                                <Pie options={{responsive:true,datalabels:{display:true,anchor:'end'},maintainAspecRatio:true}} data={{labels:["Zanzibar","Bara"],datasets:[{label:"Mgawanyo wa Vikundi kwa Kanda",data:this.groupZone,backgroundColor: ['rgba(54, 162, 235, 0.2)','rgb(255, 99, 132)'],borderColor: ['rgba(54, 162, 235, 0.2)','rgba(255, 99, 132, 0.2)']}]}} 
                                />
                                </div>
                                <div id="chart3" className="col-md-6 ms-sm-auto col-lg-6 my-4 mx-1 py-4">
                                <h5>Usajili wa Vikundi kwa Wilaya</h5>
                               <Line data={{labels:this.wilayas,datasets:[{label:"Vikundi kwa Wilaya",data:this.wilayaData,backgroundColor: ['rgb(255, 99, 132)'],borderColor: ['rgba(255, 99, 132, 0.2)'],}]}} 
                               options={{scales: {yAxes: [{ticks: {beginAtZero: true}}]}}} />   
                            </div>
                            </div>
                            <div className="col-md-12 col-lg-12 my-2 mx-auto shadow row">
                                <div id="chart4" className="shadow col-md-5 mx-auto col-lg-5 my-4 py-4 mx-1 ">
                                    <h5>Usajili wa Wanachama Kwa Kanda</h5>
                                <Bar data={{labels:["Wanawake","Wanaume"],datasets:[{label:"Zanzibar",data:this.memberZoneZnz,backgroundColor: ['#d44d8199'],borderColor: ['rgba(54, 162, 235, 0.2)']},{label:"Bara",data:this.memberZoneTz,backgroundColor: ['#a88bd899'],borderColor: ['#a88bd899']}]}} 
                                options={{scales: {yAxes: [{ticks: {beginAtZero: true}}]}}} />
                                </div>
                                <div className="shadow col-md-5 mx-auto col-lg-5 my-4 py-4 mx-1 ">
                                    <h5>Umri wa Wanachama</h5>
                                <Bar data={{labels:["0-17","18-35","36-60","Zaidi ya 60"],datasets:[{label:"Zanzibar",data:this.memberAgeZnz,backgroundColor: ['#d44d8199'],borderColor: ['rgba(54, 162, 235, 0.2)']},{label:"Bara",data:this.memberAgeTz,backgroundColor: ['#a88bd899'],borderColor: ['#a88bd899']}]}} 
                                options={{scales: {yAxes: [{ticks: {beginAtZero: true}}]}}} />
                                </div>
                            </div>
                            </>}
                        </main>
                    </div>
                </div>
            </div>
            )
            
        }
        return(<LoginForm onAuth={(user=>this.handleAuth(user))} target={"/mwanzo"}/>)
    }
    
}
export default Dashboard;

//if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
 // some code..
//}