import React, { useEffect,useState } from "react";
import logo from "../logo.png";
import Spinner from "./spinner";
import validator from "validator";
import commons from "../utils/commons";
const LoginForm =(props)=>{

    const [isLoading,setIsLoading] = useState(false);
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [hasError,setHasError] = useState(false);
    const [errorMsg,setErrorMsg] = useState('')
    var storage = window.localStorage;
    storage.setItem("offline","yes");
    var session = window.sessionStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    // var userStore = storage.getItem("userStore") ? JSON.parse(storage.getItem("userStore")):[];
    
    const signIn= (email,password)=>{
        return new Promise((resolve,reject)=>{
            var url = commons.getURL("login")+"/index.php";
            var options = {
                body:JSON.stringify({email:email,password:password,btnLogin:"login"}),
                method:"POST",
                headers:{'Content-type':'application/json'}
            }
            fetch(url,options)
            .then(res=>res.json())
            .then(result=>{
                setIsLoading(false)
                resolve(result);
            })
            .catch(e=>{
                setIsLoading(false)
                reject("Pole, kuna tatizo limetokea!");
                
            })
        });
        
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        let email = document.getElementById("email").value.trim().toLowerCase();
        let password = document.getElementById("password").value.trim();
            setIsLoading(true);

           signIn(email,password)
           .then(result=>{
                setHasError(!result.status);
                setErrorMsg(result.message);
                if(result.status){
                    storeUser(result.user);
                    let dataObject = {
                        all_groups:[],all_members:[],groups:[],members:[],users:[],settings:[],igroups:[],imembers:[],group_fields:[],member_fields:[],roles:[]
                    }
                    storage.setItem("data",JSON.stringify(dataObject));
                    window.location.pathname = (props.target) ? props.target : "/mwanzo";
                    // loadData().then(data=>{
                    //     console.log("txd: ",data.users);
                    //     var sData = JSON.parse(storage.getItem("data"));
                    //     var myUsers = [];
                    //     var myMembers=[];
                    //     var myGroups = [];
                    //     var myGF = [];
                    //     var myRoles = [];
                    //     if(result.user.ngazi == commons.NGAZI_SUPER){
                    //         myUsers = data.users;
                    //         myMembers = data.members;
                    //         myGroups = data.groups;
                    //         myGF = data.group_fields;
                    //     }
                    //     else if(result.user.ngazi == commons.NGAZI_ADMIN){
                    //         myUsers = data.users.filter(u=>u.kanda == session.loginState.kanda);
                    //         myMembers = data.members.filter(u=>u.kanda == session.loginState.kanda);
                    //         myGroups = data.groups.filter(u=>u.kanda == session.loginState.kanda);
                    //         myGF = data.group_fields;
                    //         myRoles = data.roles;
                    //     }
                    //     else{
                    //         myRoles = data.roles;
                    //         myGF = data.group_fields;
                    //         myMembers = data.members.filter(m=>m.wilaya.toLowerCase() == result.user.wilaya.toLowerCase());
                    //         myGroups = data.groups.filter(m=>m.wilaya.toLowerCase() == result.user.wilaya.toLowerCase());
                    //     }
                    //     sData.users = myUsers;
                    //     sData.groups = myGroups.map(g=>{
                    //         let ng = g;
                    //         ng.group_id = parseInt(g.group_id);
                    //         return ng;
                    //     });
                    //     sData.roles = myRoles;
                    //     sData.member_fields = data.member_fields.map(f=>f.Field.toLowerCase());
                    //     sData.group_fields = data.group_fields.map(f=>f.Field.toLowerCase());
                    //     sData.members = myMembers.map(m=>{
                    //         let mb = m;
                    //         mb.mkopo = m.mkopo ? m.mkopo : 0;
                    //         mb.marejesho = m.marejesho ? m.marejesho : 0;
                    //         mb.hisa = m.hisa ? m.hisa : 0;
                    //         mb.thamani_ya_hisa = m.thamani_ya_hisa ? m.thamani_ya_hisa:0;
                    //         mb.idadi_ya_mikopo = m.idadi_ya_mikopo ? m.idadi_ya_mikopo:0
                    //         var dob = new Date(Date.now()).getFullYear() - (m.mwaka == null || m.mwaka == "hakuna" || m.mwaka == "" ? 0:m.mwaka);
                    //         mb.umri = dob;
                    //         return mb;
                    //     });
                    //     sData.all_groups = data.groups.map(g=>{
                    //         let ng = g;ng.group_id = parseInt(g.group_id);return ng;
                    //     })
                    //     sData.all_members = data.members.map(m=>{
                    //         let mb = m;
                    //         mb.mkopo = m.mkopo ? m.mkopo : 0;
                    //         mb.marejesho = m.marejesho ? m.marejesho : 0;
                    //         mb.hisa = m.hisa ? m.hisa : 0;
                    //         mb.thamani_ya_hisa = m.thamani_ya_hisa ? m.thamani_ya_hisa:0;
                    //         mb.idadi_ya_mikopo = m.idadi_ya_mikopo ? m.idadi_ya_mikopo:0
                    //         var dob = new Date(Date.now()).getFullYear() - (m.mwaka == null || m.mwaka == "hakuna" || m.mwaka == "" ? 0:m.mwaka);
                    //         mb.umri = dob;
                    //         return mb;
                    //     })
                    //     storage.setItem("data",JSON.stringify(sData));
                    //     storedData = JSON.parse(storage.getItem("data"));
                    //     window.location.pathname = (props.target) ? props.target : "/mwanzo";
                    // })
                    // .catch(e=>{
                    //     console.log(e);
                    // })
                };

           }).catch(e=>{
            console.log("error: ",e);
            setHasError(true);
            setErrorMsg(e.toString());
           });
        }
    const storeUser = (u)=>{
        session.setItem("loginState",JSON.stringify(u));
    }
    const handleChange = (e)=>{
        if(validator.isEmail(e.target.value)){
           setEmail(e.target.value.trim());
           setHasError(false);
           setErrorMsg("");

        }
        else{
            setHasError(true);
            setErrorMsg("Barua-pepe si sahihi")
        }
    }
    const handlePasswordChange = (e)=>{
        setPassword(e.target.value.trim());
    }
    // const loadData=()=>{
    // return new Promise((resolve,reject)=>{
    //     let loginState = JSON.parse(session.getItem("loginState"));
    //     if(loginState){
    //         let user_url = commons.getURL("data")+"/index.php";
    //         var options={
    //             method:"POST",
    //             body:JSON.stringify({auth:loginState}),
    //             headers:{'Content-type':'application/json'}
    //         }
    //         fetch(user_url,options)
    //         .then(res=>res.json())
    //         .then(result=>{
    //             resolve(result);
    //         })
    //         .catch(e=>{
    //             console.log(e);
    //             reject("Pole... kuna tatizo limetokea");
    //         })
    //         .finally(()=>{
    //             setIsLoading(false);
    //         })
    //     }
        
    // })
    // }
    return(
        <main className="my-4 py-4 text-center">
            <img src={logo} className="front_logo mx-auto" alt="nembo ya taifa"/>
            <form className="mx-auto col-md-8 col-lg-8 col-sm-10 offset-2 offset-sm-1 p-2" onSubmit={handleSubmit}>
                <div className="primary-text my-3 col-md-8 col-lg-8 col-sm-10 offset-lg-2 offset-md-2 offset-sm-1 text-center mx-auto">Fisheries Groups Monitoring System</div>
                {hasError ? <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1 alert-danger p-1 ">{errorMsg}</div>:null}
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                    {/* <label for="email" className="text-left">Username/E-mail address</label> */}
                    <input type="email" id="email" name="email" placeholder="Barua-pepe" className="form-control" required={true} onChange={handleChange}/>
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                    {/* <label for="password" className="text-left">Password</label> */}
                    <input type="password" id="password" name="password" placeholder="Neno la siri" className="form-control" onChange={handlePasswordChange}/>
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1">
                    {isLoading ? <Spinner isShowing={true} size="spinner-medium"/> : <input type="submit" value="INGIA" className="btn-success form-control" />}
                </div>
                <div className="form-group my-2 col-md-4 col-lg-4 col-sm-10 offset-lg-4 offset-md-4 offset-sm-1 text-left">
                    <a href="/nywila">Nimesahau neno la siri</a>    
                </div>
            </form>

        </main>
    )
   
}
export default LoginForm;
