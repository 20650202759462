import React, { useState } from "react";
import Popup from "./popup";

const Header=(props)=>{
    const loginState = props.auth;
    
    const [showDialog,setShowDialog] = useState(false);
    const handleSearch=(e)=>{
        props.onSearch(e.target.value.toLowerCase())
    }
    const handleSignout=()=>{
        setShowDialog(true);
    }
    
    return(
        <header className="navbar navbar-dark sticky-top bg-dark d-flex justify-content-between flex-md-nowrap p-0 shadow">
            
            <div className="d-flex d-flex-row justify-content-between col-lg-2 col-md-2 col-sm-12 col-xs-12 w-sm-100">
                <a className="navbar-brand me-0 px-3">FGMS v.1.0</a>
                <button type="button" className="navbar-toggler d-md-none collapsed" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Menu">
                    <span className="navbar-toggler-icon"></span>
                </button>
            </div>
            {props.onSearch ? <input className="form-control form-control-dark px-4" type="text" placeholder="tafuta" aria-label="Search" onChange={handleSearch}/>:null}
            <div className="col-lg-2 col-md-2 col-sm-12 col-xm-12 d-flex d-flex-row justify-content-end">
            {loginState ? <span className="nav-item text-nowrap d-flex d-flex-row align-items-center" onClick={()=>window.location.pathname = "/mipangilio"}>
                <span className="material-icons text-white">person</span>
                <a className="nav-link " href="/mipangilio">{loginState.jina.split(" ")[0]}</a>
                </span> :null}
                <span className="nav-item text-nowrap d-flex d-flex-row align-items-center" onClick={handleSignout}>
                <span className="material-icons text-white">logout</span>
                    <span className="nav-link px-3 text-white btn">Toka</span>
                </span>
            </div>
            {showDialog ? <Popup title="Kutoka" msg="Una hakika unataka kutoka?" action="toka" type="logout"/>:null}
        </header>
        )
}
export default Header;