import React, { useEffect, useState } from "react";
import Commons from "../utils/commons";
const RegisterGroupForm =(props)=>{
    var storage = window.localStorage;
    var session = window.sessionStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(session.getItem("loginState"));
    var members = (storage.getItem("data")) ? JSON.parse(storage.getItem("data")).members :[];  
    const [zone,setZone] = useState(parseInt(loginState.kanda));
    const [wilaya,setWilaya] = useState([]);
    const [mikoa,setMikoa] = useState([]);
    const [hasMkopo,setHasMkopo] = useState(false);
    const [aina_ya_usajili,setUsajili] = useState("CMG");
    const [feedback,setFeedback] = useState("");
    const [hasError,setHasError] = useState(false);
    const [validMPhone,setValidMPhone] = useState();
    const [validKPhone,setValidKPhone] = useState();
    const [validCPhone,setValidCPhone] = useState();
    const [hasKatiba,setHasKatiba] = useState(0);
    const regionData = Commons.getRegionalData();
    const handleCancelForm = (target)=>{
        props.onCancel(target);
    }
    const handlePhoneChange = (e)=>{
        let phone = e.target.value;
        if(phone.length > 0){
            let valid = Commons.validatePhone(phone);
            switch(e.target.id){
                case "group-phone":
                    setValidMPhone(valid);
                    break;
                case "group-phone2":
                    setValidKPhone(valid);
                    break;
                case "group-cbt-phone":
                    setValidCPhone(valid);
                    break;
            }
            
        }
        
    }
    const populateWilaya = (region)=>{
       
       if(region != 0){
        region = region.toLowerCase().replaceAll(" ","_");
        
        let districts = regionData[zone].regions.filter(r=>r.key == region)[0].districts;
        setWilaya(districts);
       }
       
    }
    const makeTime=(e)=>{
        let val = parseInt(e.target.value);
        let options = ["Asubuhi","Jioni/Usiku"];
        if(val < 6){
            options = ["Asubuhi","Usiku"];
        }
        else if(val <10){
            options = ["Usiku","Mchana"];
        }
        else{
            options = ["Asubuhi","Jioni/Usiku"]
        }
        let am = document.getElementById("group-meeting-am");
        Array.from(am.children).forEach(c=>am.removeChild(c));
        options.forEach((o,i)=>{
            am.options.add(new Option(o,i));
        })
    }
   const updateGroups=(groups)=>{
        props.onSave(groups);
        handleCancelForm();
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
        var inputs = Array.from(e.target.elements);
        if(inputs[2].value == 0){
            inputs[2].focus();
            return;
        }
        if(inputs[3].value == 0){
            inputs[3].focus();
            return;
        }
        var formData = {};
        let myTime = "00:00:00";
        if(inputs[20].checked){
            let hour = parseInt(inputs[30].value);
            let h = hour > 6 ? hour-6 : hour+6;
            myTime = h+":"+inputs[31].value+":00";
            myTime = (parseInt(inputs[32]) === 0) ? myTime+" AM" : myTime+" PM";
            let cbt_mwl = "";
            let cbt_jinsi = "";
            let cbt_phone = "";
            let cbt_anuani="";
            if(inputs[8].value == "VSLA"){
                cbt_mwl = inputs[39].value;
                cbt_jinsi = inputs[40].value;
                cbt_phone = inputs[41].value;
                cbt_anuani = inputs[42].value;
            }
            formData = {kanda:inputs[1].value,mkoa:inputs[2].value,wilaya:inputs[3].value,kata:inputs[4].value,mtaa:inputs[5].value,jina:inputs[7].value,aina_ya_ushirika:inputs[8].value,aina_ya_usajili:inputs[9].value,namba_ya_usajili:inputs[10].value,katiba_ya_chama:(inputs[12].value =="on" ? 1:0),mwaka_wa_usajili:inputs[13].value,kazi:inputs[14].value,
                aina_ya_biashara:inputs[15].value,idadi_ya_wanachama:inputs[16].value,tarehe_ukaguzi:inputs[17].value,akaunti_benki:inputs[18].value,pesa_benki:inputs[19].value,mkopo:1,
                mkopo_kiasi:inputs[22].value,mkopo_tarehe:inputs[23].value,mkopo_benk:inputs[24].value,idadi_ya_hisa:inputs[25].value,thamani_ya_hisa:inputs[26].value,tarehe_uchaguzi:inputs[27].value,
            tarehe_mkutano:inputs[28].value,siku_ya_mikutano:inputs[29].value,muda_wa_mikutano:myTime,changamoto:inputs[34].value,mwenyekiti:inputs[35].value,katibu:inputs[36].value,
            simu_mwenyekiti:inputs[37].value,simu_katibu:inputs[38].value,mwalimu_cbt:cbt_mwl,jinsia_cbt:cbt_jinsi,simu_cbt:cbt_phone,anuani_cbt:cbt_anuani};
       }
       else{
        let hour = parseInt(inputs[27].value);
            let h = hour > 6 ? hour-6 : hour+6;
            myTime = h+":"+inputs[28].value+":00";
            myTime = (parseInt(inputs[29]) === 0) ? myTime+" AM" : myTime+" PM";
        formData = {kanda:inputs[1].value,mkoa:inputs[2].value,wilaya:inputs[3].value,kata:inputs[4].value,mtaa:inputs[5].value,jina:inputs[7].value,aina_ya_ushirika:inputs[8].value,aina_ya_usajili:inputs[9].value,namba_ya_usajili:inputs[10].value,katiba_ya_chama:(inputs[12].value =="on" ? 1:0),mwaka_wa_usajili:inputs[13].value,kazi:inputs[14].value,
            aina_ya_biashara:inputs[15].value,idadi_ya_wanachama:inputs[16].value,tarehe_ukaguzi:inputs[17].value,akaunti_benki:inputs[18].value,pesa_benki:inputs[19].value,mkopo:0,
            idadi_ya_hisa:inputs[22].value,thamani_ya_hisa:inputs[23].value,tarehe_uchaguzi:inputs[24].value,
            tarehe_mkutano:inputs[25].value,siku_ya_mikutano:inputs[26].value,muda_wa_mikutano:myTime,changamoto:inputs[31].value,mwenyekiti:inputs[32].value,katibu:inputs[33].value,
            simu_mwenyekiti:inputs[34].value,simu_katibu:inputs[35].value,mwalimu_cbt:inputs[36].value,jinsia_cbt:inputs[37].value,simu_cbt:inputs[38].value,anuani_cbt:inputs[39].value,}
        
       }
       
        console.log("fd: ",formData);
        registerNewGroup(formData)
        .then(result=>{
            console.log("result: ",result);
            if(result.status){
                updateGroups(result.groups)
                
            }
            else{
                setHasError(true);
                setFeedback(result.message);
            }
        })
        .catch(e=>{
            setHasError(true);
            setFeedback("Pole! Kuna tatizo limetokea "+e);
        });
    }
  
    const handleZoneChange =(e)=>{
        
        let zId = parseInt(e.target.value);
        let zone = regionData[zId];
        const regionEl = document.getElementById("group-region");
        Array.from(regionEl.children).forEach((c,i)=>{if(i>0)regionEl.removeChild(c);});
        zone.regions.forEach(r=>{
            let reg = r.name;
            regionEl.options.add(new Option(reg,r.key));
        })
        
        setZone(zId);
        let usaj = (zId == 0) ?"CMG":"Usajili wa Vyama vya Ushirika";
        setUsajili(usaj);
    }
    const handleRegionChange=(e)=>{
        let region = e.target.value.toLowerCase();
        if(region != 0) populateWilaya(region);
        
    }
    const checkMkopo=(status)=>{
        setHasMkopo(status);
        if(status) document.getElementById("group-mkopo-yes").checked = true;
        else document.getElementById("group-mkopo-no").checked = true;
    }
    const registerNewGroup=(group)=>{
        return new Promise((resolve,reject)=>{
            let url = Commons.getURL("groups")+"/create.php";
            let body={auth:loginState,data:group}
            let options = {
                method:"POST",body:JSON.stringify(body),
                headers:{"Content-type":"application/json"}
            }
            fetch(url,options)
            .then(res=>res.json())
            .then(result=>{
                resolve(result);
            })
            .catch(e=>{
                console.log("registerNewGroup(): ",e);
                reject("Pole! Kuna tatizo limetokea")
            })
        })
    }
    useEffect(()=>{
        checkMkopo(hasMkopo);
        let mkoa = (loginState.ngazi == Commons.NGAZI_ADMIN ||loginState.ngazi == Commons.NGAZI_SUPER) ? regionData[zone].regions[0].key : loginState.mkoa; 
        populateWilaya(mkoa);
        
    },[hasMkopo])
    return(
        <form onSubmit={handleSubmit} className="border border-secondary bg-light my-4 col-md-10 col-lg-10 col-sm-10 offset-md-1 mx-auto offset-lg-1 offset-sm-1 row">
            <div className="text-medium">Usajili wa Kikundi/Ushirika</div>
            {feedback.length > 0 ? <div className={"my-2 "+(hasError ? "alert-danger" : "alert-success")}>{feedback}</div>:null}
    
            
        <div className="col-sm-10 col-md-10 col-lg-10 text-white bg-secondary bg-gradient offset-1 mx-auto mt-2 mb-0 ">1. Mahali</div>
            
            <fieldset className="col-md-10 col-lg-10 offset-1 mx-auto border m-2 mt-0 row">
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-zone">Kanda *</label>
                    
                        {loginState.ngazi == Commons.NGAZI_DATA || loginState.ngazi == Commons.NGAZI_EVAL || loginState.ngazi == Commons.NGAZI_MANAGER ? 
                        <select className="form-control" id="group-zone" name="group-zone" onChange={handleZoneChange}>
                            <option value={loginState.kanda}>{parseInt(loginState.kanda) == 0 ? "Bara":"Zanzibar"}</option>
                        </select>:
                        <select className="form-control" id="group-zone" name="group-zone" value={zone} onChange={handleZoneChange}>
                            <option value="0">Bara</option>
                            <option value="1">Zanzibar</option>)
                        </select>}
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-region">Mkoa *</label>
                    <select className="form-control" id="group-region" name="group-region" onChange={handleRegionChange}>
                        {/* <option value={0}>--chagua mkoa--</option> */}
                    {loginState.ngazi == Commons.NGAZI_ADMIN ||loginState.ngazi == Commons.NGAZI_SUPER ? regionData[parseInt(loginState.kanda)].regions.map(city=>{
                            return(<option key={city.key} value={city.key}>{city.name}</option>)
                        }):regionData[parseInt(loginState.kanda)].regions.filter(m=>m.key.toLowerCase() == loginState.mkoa.toLowerCase()).map(city=>{
                            return(<option key={city.key} value={city.key}>{city.name}</option>)
                        })}
                    </select>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-district">Wilaya *</label>
                    {loginState.ngazi == Commons.NGAZI_EVAL || loginState.ngazi == Commons.NGAZI_DATA ? 
                    <select required={true} className="form-control" id="group-district" name="group-district">
                    <option value={loginState.wilaya}>{loginState.wilaya.replaceAll("_"," ")}</option>
                    </select> :
                     <select required={true} className="form-control" id="group-district" name="group-district">
                     <option value={0}>--chagua wilaya--</option>
                     {wilaya.map(w=>{
                         return (<option key={w.name.replaceAll(" ","_").toLowerCase()} value={w.name.replaceAll(" ","_").toLowerCase()}>{w.name}</option>)
                     })}
                     </select>}
                </div>
                
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-kata">Kata/Wadi *</label>
                    <input required={true} className="form-control" type="text" id="group-kata" name="group-kata" placeholder="Kata" />
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-mtaa">Mtaa/Kijiji/Shehia *</label>
                    <input required={true} className="form-control" type="text" id="group-mtaa" name="group-mtaa" placeholder="Mtaa au kijiji" />
                </div>
                
            </fieldset>
        <div className="col-sm-10 col-md-10 col-lg-10 text-white bg-secondary bg-gradient offset-1 mx-auto mt-2 mb-0 ">2. Uthibitisho</div>
            
        <fieldset className="col-md-10 col-lg-10 offset-1 mx-auto border m-2 mt-0 row">
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-name">Jina la Kikundi *</label>
                    <input required={true} className="form-control" type="text" id="group-name" name="group-name" placeholder="Jina la kikundi"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-type">Aina ya Ushirika</label>
                    <select className="form-control" id="group-type" name="group-type" >
                        <option>VSLA</option>
                        <option>Ushirika</option>
                    </select>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-registration">Aina ya Usajili</label>
                    <input className="form-control" type="text" id="group-registration" name="group-registration" defaultValue={aina_ya_usajili}/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-regnumber">Namba ya Usajili</label>
                    <input className="form-control" type="text" id="group-regnumber" name="group-regnumber" placeholder="Namba ya usajili"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="">Kikundi kina katiba/Mwongozo?</label>
                    <div className="col-lg-10 col-md-10 col-sm-12 row">
                        <div className="d-flex flex-row align-items-center col-lg-6 col-md-6 col-sm-6">
                            <input className="mx-2" type="radio" required={true} id="group-katiba-yes" name="group-katiba" checked={hasKatiba} onChange={()=>{setHasKatiba(true)}}/>
                            <label htmlFor="group-katiba-yes">Ndiyo</label>
                        </div>
                        <div className="d-fleex flex-row align-items-center col-lg-6 col-md-6 col-sm-6">
                            <input className="mx-2" type="radio" required={true}  id="group-katiba-no" name="group-katiba" checked={!hasKatiba} onChange={()=>{setHasKatiba(false)}}/>
                            <label htmlFor="group-katiba-no">Hapana</label>
                        </div>
                    </div>
                    
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-regyear">Mwaka wa Usajili</label>
                    <select id="group-regyear" name="group-regyear" className="form-control">
                        {Commons.generateYears(2000).map(y=>{
                            return <option key={y}>{y}</option>
                        })}
                    </select>
                 </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-12 col-lg-12">
                    <label htmlFor="group-kazi">Kazi za Msingi</label>
                    <textarea className="form-control" rows="5" id="group-kazi" name="group-kazi" placeholder="Ainisha kila kazi kwenye mstari mpya"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-business">Aina ya Biashara</label>
                    <input className="form-control" type="text" id="group-business" name="group-business" placeholder="Aina ya biashara"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-idadi">Idadi ya Wanachama</label>
                    <input required={true} min={10} max={30} className="form-control" type="text" id="group-idadi" name="group-idadi" placeholder="Idadi ya wanachama"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-ukaguzi">Tarehe ya Ukaguzi wa Mwisho</label>
                    <input className="form-control" type="date" id="group-ukaguzi" name="group-ukaguzi"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                   
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-benki">Namba ya Akaunti ya Benki</label>
                    <input className="form-control" type="text" id="group-benki" name="group-benki" placeholder="Akaunti ya benki"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-balance">Pesa Iliopo Benki</label>
                    <input className="form-control" type="number" id="group-balance" name="group-balance" placeholder="Kiasi cha pesa kilichopo benki"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="">Kikundi kina Mkopo wa benki?</label>
                    <div className="col-lg-10 col-md-10 col-sm-12 row">
                        <div className="d-flex flex-row align-items-center col-lg-6 col-md-6 col-sm-6">
                            <input className="mx-2" type="radio" required={true} id="group-mkopo-yes" name="group-mkopo" onChange={()=>checkMkopo(true)}/>
                            <label htmlFor="group-mkopo-yes">Ndiyo</label>
                        </div>
                        <div className="d-fleex flex-row align-items-center col-lg-6 col-md-6 col-sm-6">
                            <input className="mx-2" type="radio" required={true}  id="group-mkopo-no" name="group-mkopo" onChange={()=>checkMkopo(false)}/>
                            <label htmlFor="group-mkopo-no">Hapana</label>
                        </div>
                    </div>
                    
                </div>
                {
                    hasMkopo ? 
                    <>
                        <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                            <label htmlFor="group-mkopo-amount">Kiasi cha Mkopo</label>
                            <input className="form-control" type="number" id="group-mkopo-amount" name="group-mkopo-amount" placeholder="Kiasi cha mkopo"/>
                        </div>
                        <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                            <label htmlFor="group-mkopo-mwisho">Mkopo unaisha lini?</label>
                            <input className="form-control" type="date" id="group-mkopo-mwisho" name="group-mkopo-mwisho" placeholder="Kiasi cha pesa kilichopo benki"/>
                        </div>
                        <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                            <label htmlFor="group-lender">Benki/Taasisi Iliyokopesha</label>
                            <input className="form-control" type="text" id="group-lender" name="group-lender" placeholder="Jina la benki/taasisi iliyowakopesha"/>
                        </div>
                    </>:null
                }
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-hisa">Idadi ya Hisa za Kikundi</label>
                    <input className="form-control" type="number" id="group-hisa" name="group-hisa" placeholder="idadi ya hisa za chama"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-hisa-value">Thamani ya kila hisa</label>
                    <input className="form-control" type="number" id="group-hisa-value" name="group-hisa-value" placeholder="Thamani ya hisa moja"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-uchaguzi">Tarehe ya Uchaguzi wa Viongozi</label>
                    <input className="form-control" type="date" id="group-uchaguzi" name="group-uchaguzi"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-agm">Tarehe Mwisho ya Mkutano Mkuu</label>
                    <input className="form-control" type="date" id="group-agm" name="group-agm" placeholder=""/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-meeting-day">Siku ya Mikutano</label>
                    <select className="form-control" id="group-meeting-day" name="group-meeting-day">
                        <option>--chagua siku--</option>
                        <option>Jumatatu</option>
                        <option>Jumanne</option>
                        <option>Jumatano</option>
                        <option>Alhamisi</option>
                        <option>Ijumaa</option>
                        <option>Jumamosi</option>
                        <option>Jumapili</option>
                    </select>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-meeting-hour">Muda wa Mikutano</label>
                    <div className="d-flex flex-row justify-content-start align-items-center">
                        <select onChange={makeTime} className="form-control timebox mx-1" id="group-meeting-hour" name="group-meeting-hour">{Commons.generateHours().map(h=>{
                            return <option key={h}>{h}</option>
                        })}</select>:
                        <select className="form-control timebox mx-1" id="group-meeting-minute" name="group-meeting-minute">{Commons.generateMinutes().map(m=>{
                            return <option key={m}>{m}</option>
                        })}</select>
                        <select className="form-control timebox-medium mx-1" id="group-meeting-am" name="group-meeting-am">
                            <option value="0">Asubuhi</option>
                            <option value="1">Usiku/Jioni</option>
                            </select>
                    </div>
                </div>
            </fieldset>
        <div className="col-sm-10 col-md-10 col-lg-10 text-white bg-secondary bg-gradient offset-1 mx-auto mt-2 mb-0 ">3. Mengineyo</div>
            
        <fieldset className="col-md-10 col-lg-10 offset-1 mx-auto border m-2 mt-0 row">
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-12 col-lg-12">
                    <label htmlFor="group-changamoto">Maoni ya Kikundi</label>
                    <textarea className="form-control" rows="5" id="group-changamoto" name="group-changamoto" placeholder="Ainisha kila changamoto kwenye mstari mpya"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-chairperson">Jina la Mwenyekiti</label>
                    <input className="form-control" id="group-chairperson" name="group-chairperson" placeholder="jina la mwenyekiti"/>
                     
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-katibu">Jina la Katibu</label>
                    <input className="form-control" id="group-katibu" name="group-katibu" placeholder="jina la kaibu"/>
                     
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-phone">Simu ya Mwenyekiti</label>
                    <input onInput={handlePhoneChange}  className={"form-control "+(!validMPhone ? "border border-danger": "border border-success")}  type="number" id="group-phone" name="group-phone" placeholder="Na. ya simu ya mwenyekiti"/>
                    {!validMPhone ? <small className="text-danger">Namba ya simu si sahihi</small>:null}
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-phone2">Simu ya Katibu</label>
                    <input onInput={handlePhoneChange}  className={"form-control "+(!validKPhone? "border border-danger":"border border-success" )}  type="number" id="group-phone2" name="group-phone2" placeholder="Na. ya simu ya katibu"/>
                    {validKPhone ==false ? <small className="text-danger">Namba ya simu si sahihi</small>:null}</div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-cbt-name">Jina la Mwalimu CBT</label>
                    <input className="form-control" type="text" id="group-cbt-name" name="group-cbt-name" placeholder="jina la mwalimu"/>
               
                </div>   
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-cbt-jinsia">Jinsi</label>
                    <select className="form-control" id="group-cbt-jinsia" name="group-cbt-jinsia">
                    <option value="0">Ke</option>
                    <option value="1">Me</option>
                    </select>
                </div> 
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-cbt-phone">Namba ya Simu</label>
                    <input onInput={handlePhoneChange}  className={"form-control "+(!validCPhone ? "border border-danger" : "border border-success")}  type="number" id="group-cbt-phone" name="group-cbt-phone" placeholder="Na. ya simu ya CBT"/>
                    {validCPhone === false?<small className="text-danger">Namba ya simu si sahihi</small>:null}
                </div> 
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-cbt-location">Anuani</label>
                    <input className="form-control" type="text" id="group-cbt-location" name="group-cbt-location" placeholder="Anuani ya mwalimu"/>
               
                </div> 
                        
                <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row">
                    <input className="col-md-3 col-lg-2 col-sm-6 btn bg-primary w-30 text-white" type="submit" value="HIFADHI"/>
                    <input onClick={()=>handleCancelForm(null)} className="col-md-3 col-lg-2 col-sm-6 btn w-30 text-primary" type="button" value="GHAIRI"/>
                </div>
            </fieldset>
        </form>
               
    )
}
export default RegisterGroupForm;