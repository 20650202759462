import React, { useEffect, useState } from "react";
import LoanListItem from "./loan_list_item";
import ListHeadRow from "./header_row";
import Commons from "../utils/commons";
import LoginForm from "./login_form";
import Header from "./header";
import SideBar from "./sidebar";
import { setgroups } from "process";

const Loans=(props)=>{
    const storage = window.localStorage;
    const session = window.sessionStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(session.getItem("loginState"));
    const [showLoansList,setShowLoansList] = useState(true);
    const [loans,setLoans] = useState([]);
    const [mikopo,setMikopo] = useState(0);
    const [allLoans,setAllLons] = useState([]);
    const [members,setMembers] = useState([]);
    const [groups,setGroups] = useState([]);
    const [page,setPage] = useState(0);
    const [pageCount,setPageCount] = useState([]);
    const [allData,setAllData] = useState();
   
    const prepareData=(source)=>{
        let ms = [];
        let odata = source;
        if(source){
            if(mikopo === 0) {
                source = source.members;
            }
            else source = source.groups;
            let sourceData =  source.map(s=>{
                return s.map(m=>{
                    if(mikopo === 0){
                        let nm = m;
                        nm.baki = m.mkopo - m.marejesho;
                        var kikundi = odata.groups.find(g=>parseInt(g.group_id) == parseInt(m.group_id));
                        console.log("kikundi: ",kikundi);
                        nm.kikundi = (m.kikundi) ? m.kikundi:kikundi;
                        if(m.kanda == null || m.kanda == "") m.kanda = (kikundi.length > 0)?parseInt(kikundi[0].kanda):0;
                        return nm; 
                    }
                    else{
                        let nm = m;
                        nm.date = m.mkopo_tarehe;
                        nm.mkopo = m.mkopo_kiasi;
                        nm.benki = m.mkopo_benki;
                        return nm;
                    };
                   
                }).filter(g=>{
                    if(mikopo == 0){
                        return g.mkopo && g.mkopo > 0
                    }
                    else return g.mkopo_kiasi && g.mkopo_kiasi != "" && g.mkopo_kiasi != "hakuna" && g.mkopo_kiasi != 0;
                })
            })
            
            if(loginState.ngazi == Commons.NGAZI_ADMIN){
                ms = sourceData[page].filter(g=>g.kanda == loginState.kanda);
                console.log("admin: ",ms);
            }
            else if(loginState.ngazi == Commons.NGAZI_MANAGER){
                ms = sourceData[page].filter(g=>g.mkoa && g.mkoa.toLowerCase().replaceAll(" ","_")== loginState.mkoa.toLowerCase().replaceAll(" ","_"));
                console.log("mgr: ",ms);
            }
            else if(loginState.ngazi == Commons.NGAZI_SUPER) {
                // this.storedData.users = source.users;
                ms = sourceData[page];
                console.log("super: ",sourceData,page);
            
            }
            else{
                ms = sourceData[page].filter(g=>g.wilaya && g.wilaya.toLowerCase() == loginState.wilaya.toLowerCase());
                console.log("eval: ",ms);
            }
            let pages = sourceData.map((g,i)=>{
                return i+1;
            })
            let pageCount = [];
            let size = sourceData.length;
            for(let i=0; i<size;i++) {                
                if(sourceData[i].length > 0) pageCount.push(i+1);
            }    
        
            if(mikopo === 0) setMembers(ms);
            else setGroups(ms);
            setPageCount(pages);
            setPage(1);
            setLoans(ms);
        }

        console.log("vvvvv: ",source);
        
    }
    useEffect(()=>{
        const loadData=()=>{  
            return new Promise((resolve,reject)=>{
               
                if(loginState){
                    let source = mikopo === 0 ? "members":"groups";
                    let user_url = Commons.getURL(source)+"/index.php";
                    var options={
                        method:"POST",
                        body:JSON.stringify({auth:loginState}),
                        headers:{'Content-type':'application/json'}
                    }
                    fetch(user_url,options)
                    .then(res=>res.json())
                    .then(result=>{
                        
                        resolve(result);
                    })
                    .catch(e=>{
                        console.log(e);
                        reject("Pole... kuna tatizo limetokea");
                    })
                    
                }
                
            })
        }
        loadData().then(result=>{
            if(result.status){
                setAllData(result);
                prepareData(result);
            }
        })
        
    },[mikopo])   
   
   
    const handlePageChange=(p)=>{
        setPage(p-1);
        prepareData(allData);
        
    }
    const handleAuth=(u)=>{
        loginState = u;
        session.setItem("loginState",JSON.stringify(loginState));
        // loadDataFromStorage(storedData,()=>{})
    }
    const handleMikopoChange=(e)=>{
        setMikopo(e.target.value);
        
    }
    const search=(searchkey)=>{
        if(searchkey.length > 0){
            searchkey = searchkey.toLowerCase();
            let myloans = [];
            if(mikopo == 0){
                myloans = allLoans.filter(l=>l.kikundi.toLowerCase().includes(searchkey) || l.jina.toLowerCase().includes(searchkey) || l.wilaya.toLowerCase().includes(searchkey));
            }
            else{
                myloans = allLoans.filter(l=>l.benki.toLowerCase().includes(searchkey) || l.kikundi.toLowerCase().includes(searchkey) || l.wilaya.toLowerCase().includes(searchkey));
            }
            setLoans(myloans);
        }
        
         
    }
    // if(window.performance){
    //     if(performance.navigation.type === 1){
    //         // setPage(1);
    //         // setPageCount(1);
    //         // setMembers([]);
    //         // setMikopo(0);
    //         loadData()
    //         .then(result=>{
    //             let data = result.members.filter(m=>m.mkopo > 0).map(m=>{
    //                 let mb = m;
    //                 mb.kikundi = groups.find(g=>g.group_id == m.group_id);
    //                 return mb;
    //             });
                               
    //             // setMembers(result.members);
    //             // setGroups(result.groups);
    //             prepareData(data);
    //         }).catch(e=>{
    //             console.log(e);
    //         })
            
    //     }
    // }
    if(loginState){
         return(
        <div>
        <Header onSearch={search} auth={loginState}/>
        <div className="container-fluid">
            <div className="row">
            
            <SideBar selected="mikopo" auth={loginState} />
                <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4 mx-auto my-4">
                
                    <div className="col-lg-12 col-md-12 my-4 d-flex flex-row justify-content-between align-items-center">
                        <div className="col-lg-6 col-md-6 my-4 text-left d-flex align-items-center justify-content-start">
                             <h3>Taarifa za Mikopo</h3>
                        </div>
                        <div className="col-lg-3 col-md-3 my-4 d-flex flex-row justify-content-end align-items-center"> 
                            <select className="form-control" defaultValue={mikopo} onChange={handleMikopoChange}>
                                <option value={0}>Wanachama</option>
                                <option value={1}>Vikundi</option>
                            </select>
                        </div>
                    </div>
                    <div className="text-right">
                        {pageCount.length >1 ?<><span>Kurasa: </span>
                        {pageCount.map(p=>{
                            return <span key={Commons.randomKey()} className={"btn px-1 "+(page !== p-1 ? " text-primary":"")} onClick={(p)=>handlePageChange(p)}>[{p}]</span>
                        })
                        }</>:null}
                    </div>
                        <ListHeadRow data={Commons.getLoanListLabels(mikopo)}/>
                        {loans.length > 0 ?
                            loans.map((loan,i)=>{
                               let ln = loan;
                               Commons.getLoanListLabels(mikopo).forEach((l)=>{
                                ln[l.key] = loan[l.key];
                               })
                               return ln;
                            }).map((loan,i)=>{
                                return <LoanListItem loan={loan} key={Commons.randomKey()} position={i} labels={Commons.getLoanListLabels(mikopo)}/>
                            }):
                            <LoanListItem loan={null} position={0} labels={Commons.getLoanListLabels(mikopo)}/>
                        }

                 </main>
            </div>
        </div>
    </div>
    )
    }
    else{
        return (<LoginForm target="/mikopo" onAuth={(user=>handleAuth(user))} />)
    }
    
}
export default Loans;