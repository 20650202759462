import React from 'react';
import Commons from '../utils/commons'
const GroupListItem = (props)=>{

    var data = props.data;
  
    var regionData = Commons.getRegionalData();
    if(data != null && data != undefined){
        let zid = data.kanda == 0 || data.kanda == 1 ? data.kanda :(data.kanda == "Bara" || data.kanda == 'bara' ? 0:1);
        data.zone = regionData[zid];
        if(data.mkoa == "hakuna" || data.mkoa == undefined){
            console.log("sg: ",data);
            let mkoa = data.zone.regions.filter(r=>{
                let x = (data.wilaya) ? data.wilaya.toLowerCase():"";
                return r.districts.map(d=>d.name.toLowerCase()).includes(x)
            })[0];
            data.mkoa = (mkoa) ? mkoa.name : data.zone.regions[0].name;
        }
        if(data.simu =="") data.simu = "Hakuna";
       if(data.mwenyekiti && typeof(data.mwenyekiti)=="object") data.mwenyekiti = data.mwenyekiti.jina;
       if(data.katibu && typeof(data.katibu)=="object") data.katibu = data.katibu.jina;
       
    }
    
    var position = props.position;
    var groupLabels = Commons.getGroupListLabels();
    const handleClick=()=>{
        if(!props.imported) props.onClick(data);
    }
    return(
        data ? (
            <div onClick={handleClick} className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-start align-items-center text-left list-item "+((position%2==0) ? "list-item-shade":"bg-light")}>
            {
                groupLabels.map((d,i)=>{
                    let val = data[d.key];
                    if(val && d.key == "wilaya") val = val.toUpperCase();
                    return(<div key={Commons.randomKey()} className={"col-lg-2 col-md-2 text-left" }>{val}</div>)
                
                })
            }
            </div>  
            ):(
                <div className="text-center col-lg-12 col-md-12">Hakuna taarifa</div>
            )
            
        
                    
    )
}
export default GroupListItem;