import React from "react";
import XLSX from "xlsx";
import { useState,useEffect } from "react";
import Commons from "../utils/commons";
import ListHeadRow from "./header_row";
import MemberListItem from "./member_list_item";
import commons from "../utils/commons";
import Spinner from "./spinner";

const MemberImport = (props)=>{

    var importedData=[];
    const storage = window.localStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const [complete,setComplete] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [isLoadingFile,setIsLoadingFile] = useState(false);
    const [hasError,setHasError] = useState(false);
    const [errorMsg,setErrorMsg] = useState("");
    const [members,setMembers] = useState([]);
    var iMembers = [];
    var iGroups = props.groups;
    const findMkoa=(wilaya,kanda)=>{
        var regions = Commons.getRegionalData()[kanda].regions;
        let mkoa = regions.filter(r=>{
            return r.districts.map(d=>d.name.toLowerCase()).includes(wilaya.toLowerCase())
        });
        if(mkoa.length > 0) return mkoa[0].name;
        return "hakuna"

    }
    const checkExist=(member,dataset=null)=>{
        let index = -1;
        let jina = member.jina.toLowerCase();
        let wilaya = member.wilaya.toLowerCase();
        let kata = member.kata.toLowerCase();
        let mtaa = member.mtaa.toLowerCase();

        let grps = (dataset != null) ? dataset: members;
        let mg = grps.filter((g,i)=>{
            if(g.jina.toLowerCase() == jina && g.wilaya.toLowerCase() == wilaya && g.kata.toLowerCase() == kata && g.mtaa.toLowerCase() == mtaa){
                index = i;
                return true;
            }
            
        });
        return index;
    }
    const openFileDialog = ()=>{
        setIsLoadingFile(true);
        var fileInput = document.getElementById("member-import-file");
        fileInput.click();
        
        fileInput.addEventListener("change",(e)=>{
            var reader = new FileReader();
            
            reader.addEventListener("load",()=>{
                
                var wb = XLSX.read(reader.result,{type:'array'});
                let wSheet = wb.Sheets["wanachama"];

                importedData = XLSX.utils.sheet_to_json(wSheet,{header:1});
                var columns = importedData[0];
                if(columns.length >0){
                    columns.every((c,i)=>{
                        let index = storedData.member_fields.indexOf(c);
                        if(index <0){
                            setHasError(true);
                            setErrorMsg("Tafadhali hakikisha faili liko katika muundo husika");
                            setIsLoadingFile(false);
                            setComplete(true);
                            return false;
                        }
                    })
                }
                else{
                    setHasError(true);
                            setErrorMsg("Tafadhali hakikisha faili liko katika muundo husika");
                            setIsLoadingFile(false);
                            setComplete(true);
                }
                if(!hasError){
                    importedData = importedData.slice(1);
                    importedData = importedData.map((d,k)=>{
                     let id = k == 0 ? members.length : members.length +k;
                     var data={member_id:id};
                     data.group_id=-1;
                     columns.forEach((c,i)=>{
                         var val;
                         if(c.toLowerCase() == "kanda") {
                             val = (d[i] && d[i].toLowerCase() == "zanzibar") ?1:0;
                         }
                         else if(c.toLowerCase() == "jinsia") val = (d[i] && (d[i].toLowerCase() == "female" ||d[i].toLowerCase() == "ke"))? 0 : 1;
                         else if(c.toLowerCase() == "kikundi") {
                             if(iGroups.length > 0) {
                                 val = iGroups.find(g=>d[i] && g.jina.toLowerCase() == d[i].toLowerCase());
                                 data['group_id'] = val ? val.group_id :-1;
                             }
                             else val = d[i];
                         }
                         else if(c.toLowerCase() == "hisa") val = (d[i] == null || d[i] == undefined) ? 0 : parseInt(d[i]);
                         else if(c.toLowerCase() == "thamani_ya_hisa") val = (d[i] == null || d[i] == undefined) ? 0 : parseInt(d[i]);
                         else if(c.toLowerCase() == "idadi_ya_mikopo") val = (d[i] == null || d[i] == undefined) ? 0 : parseInt(d[i]);
                         else if(c.toLowerCase() == "mkopo") val = (d[i] == null || d[i] == undefined) ? 0 : parseInt(d[i]);
                         else if(c.toLowerCase() == "marejesho") val = (d[i] == null || d[i] == undefined) ? 0 : parseInt(d[i]);
                         else val = (d[i]) ? d[i]:"hakuna";
                         data[c.toLowerCase()] = (typeof(val) == "string" && val.includes("'")) ? val.replaceAll("'","#"):val;
                     });
                     if(data.mkoa == null || data.mkoa == undefined) data.mkoa = findMkoa(data.wilaya,data.kanda);
                     if(typeof(data.kikundi) == "object") data.group_id = data.kikundi.group_id;
                     return data;
                 });
                 
                 var cleaned = importedData.map((d,i)=>{
                     let x = d;
                     let index = checkExist(d,members);
                     if(index > 0){
                         let og = members[index];
                         x.member_id = og.member_id;
                         x.is_duplicate = true;
                         // duplicates.push(d);
                     }
                     else x.is_duplicate = false;
                     return x;
                 }).filter(d=>d.group_id != null && d.group_id != -1 && d != null && d != undefined);
                
                 iMembers = cleaned;

                setMembers(iMembers);
                 setComplete(true);
                 setIsLoading(false);
                 setIsLoadingFile(false)
                    
                     const sitishaBut = document.getElementById("btn-sitisha");
                     sitishaBut.classList.remove("hidden");
                     sitishaBut.addEventListener("click",(e)=>{
                         iMembers = [];
                         props.onCancel("members");
                         setComplete(false);
                     })
                     const hifadhBut = document.getElementById("btn-hifadhi");
                     hifadhBut.classList.remove("hidden");
                     hifadhBut.addEventListener("click",(e)=>{
                         setIsLoading(true);
                         hifadhi().then(result=>{
                             setIsLoading(false);
                             setHasError(!result.status);
                             setErrorMsg(result.message);
                             if(result.status){
                                 var data = [];
                                 result.members.forEach(m=>{
                                    data = data.concat(m);
                                 })
                                 let nMembers = [];
                                
                                 if(loginState.ngazi == Commons.NGAZI_ADMIN){
                                     nMembers = data.filter(m=>m.kanda == loginState.kanda);
                                 }
                                 else if(loginState.ngazi == Commons.NGAZI_SUPER){
                                     nMembers = data;
                                 }
                                 else if(loginState.ngazi == Commons.NGAZI_MANAGER){
                                    nMembers = data.filter(m=>m.mkoa.toLowerCase().replaceAll(" ","_") == loginState.mkoa.toLowerCase().replaceAll(" ","_"));
                                 }
                                 else{
                                     nMembers = data.filter(m=>m.wilaya.toLowerCase().replaceAll(" ","_") == loginState.wilaya.toLowerCase().replaceAll(" ","_"));
                                 }
                                 let newData = nMembers;
                                 if(nMembers.length > Commons.PAGE_LIMIT){
                                    newData = [];                           
                                    for(let i=0;i<nMembers.length;i=i+Commons.PAGE_LIMIT){
                                        let source = nMembers;     
                                        newData.push(source.slice(i,Commons.PAGE_LIMIT+i));
                                    }
                                    
                                 }
                                 setMembers(newData);
                                 props.onHifadhi(newData);
                                 props.onCancel("members");
                             
                             }  
                             
                         })
                         .catch(e=>{
                             setIsLoading(false);
                             setHasError(true);
                             setErrorMsg("Pole... Kuna tatizo limetokea, tafadhali jaribu tena baadae");
                             })
                         });
                     }
                
                 else{
                     setIsLoadingFile(false);
                 }
               
            },false);
            
            reader.readAsArrayBuffer(fileInput.files[0])
        })
    }
  
    const hifadhi=()=>{
        return new Promise((resolve,reject)=>{
            var data = iMembers;
            let columns = Object.keys(data[0]).filter(k=>k != "member_id" && k!="kikundi");
            data = data.map(d=>{
                let nd = d;
                if(!d.is_duplicate) delete nd.member_id;
                
                return nd;
            })
            let body = {data:data,auth:loginState,columns:columns};
            let url = commons.getURL("members")+"/batch.php";
            let options = {
                method:"POST",
                body:JSON.stringify(body),
                headers:{'Content-type':'application/json'}
            }
            fetch(url,options)
            .then(res=>res.json())
            .then(result=>{
                resolve(result);
            })
            .catch(e=>{
                reject("Pole... Kuna tatizo limejitokeza");
            })
        })
        
    }
    useEffect(()=>{
        setComplete(iMembers.length > 0);
    },[complete])
    return(
        <>
           
           <h3>Tafadhali pakia faili la Excel (.xls/.xlsx) lenye safu zifuatazo: </h3>
                
                <span className="text-primary col-md-10 col-lg-10 col-sm-10">{storedData.member_fields.filter(mf=>mf != "member_id" && mf != "group_id" && mf != "is_deleted" && mf !="date_created" && mf !="date_updated").map(ml=>{
                    return <span key={ml}>{ml+", "}</span>
                })}</span>
            <div className="my-5 d-flex justify-content-between">
                <span className="btn btn-primary" onClick={openFileDialog}>{isLoadingFile ? <Spinner size="spinner" isShowing={isLoadingFile}/>: "Pakia Faili"}</span>
                <input type="file" name="member-import-file" id="member-import-file" className="hidden" />
                <div>
                    <div className="btn bg-success hidden text-white" id="btn-hifadhi">{isLoading ? <Spinner size="spinner" isShowing={isLoading}/>: "SASISHA"}</div>
                    <div className="btn bg-danger text-white mx-2 hidden" id="btn-sitisha">SITISHA</div>
                </div>
            </div>
           
            {!complete ?
            <>
            {hasError ? <div className={"my-2 "+(hasError ? "alert-danger" : "alert-success")}>{errorMsg}</div>:null}
    
            <ListHeadRow data={Commons.getMemberListLabels()}/>
            {members.map((d,i)=>{
                return <MemberListItem key={Commons.randomKey()} position={i+1} id={d.member_id} imported={true} data={d}/>
            })
            }
            </>:null}
        </>
    )
}
export default MemberImport;
