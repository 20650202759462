import React, { useEffect, useState } from "react";
import Commons from "../utils/commons";
import Spinner from "./spinner";
const GroupEditForm =(props)=>{
    var storage = window.localStorage;
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    var detail = props.data;
    const [zone,setZone] = useState(parseInt(detail.kanda));
    const [mikoa,setMikoa] = useState([]);
    const [aina_ya_usajili,setUsajili] = useState("CMG");
    const [hasMkopo,setHasMkopo] = useState(detail.mkopo == 1);
    const [isLoading,setIsLoading] = useState(false);
    const [hasError,setHasError] = useState(false);
    const [errorMsg,setErrorMsg] = useState("");
    const [validMPhone,setValidMPhone] = useState();
    const [validKPhone,setValidKPhone] = useState();
    const [validCPhone,setValidCPhone] = useState();
    const [isVSLA,setIsVSLA] = useState(true);
    const [wilaya,setWilaya] = useState([]);
    const [mkoa,setMkoa]= useState(detail.mkoa);
    const [district,setDistrict] = useState(detail.wilaya);
    const regionData = Commons.getRegionalData();
    detail.zone = regionData[parseInt(detail.kanda)];
    
    if((detail.mkoa == "hakuna" || detail.mkoa == undefined || detail.mkoa == null || detail.mkoa == "") && detail.wilaya){
        let mkoa = detail.zone.regions.filter(r=>{
            return r.districts.map(d=>d.name.toLowerCase()).includes(detail.wilaya.toLowerCase())
        })[0];
        detail.mkoa = (mkoa) ? mkoa.name.toLowerCase().replaceAll(" ","_"):"-1";
    }
    else{
        detail.mkoa = detail.mkoa.toLowerCase().replaceAll(" ","_");
    }
    const getSwahiliTime = (time)=>{
        var result = {saa:1,dkk:0,wkt:0};
        if(time && time.length > 4){
            var h = parseInt(time.split(":")[0]);
            var m = parseInt(time.split(":")[1]);
            var am = parseInt(time.split(" ")[1]);
            var wkt = "Usiku";
            let saa = parseInt(h);
            if(h <= 6 ) saa += 6;
            else saa -= 6;
            if(am == "PM") wkt = 1;
            else wkt = 0;
          
            result.saa = saa;
            result.dkk = parseInt(m);
            result.wkt = wkt;
        }
        return result;
    }
    
    const handleCancelForm = (target)=>{
        props.onCancel(target);
    }
    const handlePhoneChange = (e)=>{
        let phone = e.target.value;
        if(phone.length > 0){
            let valid = Commons.validatePhone(phone);
            console.log("Valid: ",valid);
            switch(e.target.id){
                case "group_phone":
                    setValidMPhone(valid);
                    break;
                case "group_phone2":
                    setValidKPhone(valid);
                    break;
                case "group_cbt_phone":
                    setValidCPhone(valid);
                    break;
            }
            
        }
        
    }
    const populateWilaya = (region)=>{  
        
       if(region != 0){
        region = (region.includes(" ")) ? region.toLowerCase().replaceAll(" ","_"):region.toLowerCase();
        
        let districts = [];
        let regs = regionData[zone].regions.filter(r=>r.key == region);
        if(regs.length > 0) districts = regs[0].districts;
        setWilaya(districts);
        const wil = document.getElementById("group_district");
        wil.value = detail.wilaya;
        wil.dispatchEvent(new Event("change"))
       }
       
    }
    const populateMikoa = (z)=>{
        setMikoa(regionData[z].regions);
        let m = document.getElementById("group_region");
        m.value = detail.mkoa;
        m.dispatchEvent(new Event("change"));
        populateWilaya(m.value);

    }
    const checkMkopo=(status)=>{
        setHasMkopo(status);
        if(status) document.getElementById("group_mkopo-yes").checked = true;
        else document.getElementById("group_mkopo-no").checked = true;
    }
    const handleZoneChange=(e)=>{
        let zId = parseInt(e.target.value);
            setZone(zId);
            let usaj = (zId == 0) ?"CMG":"Usajili wa Vyama vya Ushirika";
            setUsajili(usaj);
            populateMikoa(zId);
        
    }
    const handleRegionChange=(e)=>{
        let region = e.target.value;
        if(region != 0) {
            populateWilaya(region.toLowerCase());
            setMkoa(region);
        }
        
    }
    const handleSubmit=(e)=>{
        e.preventDefault();
        setIsLoading(true);
        let formData = {};
        let form = e.target;
        formData.group_id = detail.group_id;
        formData.date_created = (detail.date_created) ? detail.date_created.split(" ")[0]:"10-01-1970";
        formData.kanda = form.group_zone.value;
        formData.mkoa = form.group_region.value;
        formData.wilaya = (form.group_district.value == "-1") ? detail.wilaya:form.group_district.value;
        formData.kata = form.group_kata.value;
        formData.mtaa = form.group_mtaa.value;
        formData.jina = form.group_name.value;
        formData.aina_ya_ushirika = form.group_type.value;
        formData.aina_ya_usajili = form.group_registration.value;
        formData.namba_ya_usajili = form.group_regnumber.value;
        formData.katiba_ya_chama = document.getElementById("group_katiba-yes").checked ? 1:0;
        formData.mwaka_wa_usajili = form.group_regyear.value;
        formData.kazi = form.group_kazi.value;
        formData.aina_ya_biashara = form.group_business.value;
        formData.idadi_ya_wanachama = form.group_idadi.value;
        formData.tarehe_ukaguzi= form.group_ukaguzi.value;

        formData.akaunti_benki = form.group_benki.value;
        formData.pesa_benki = form.group_balance.value;
        formData.mkopo = hasMkopo ? 1:0;

        if(formData.mkopo == 1){
            formData.mkopo_kiasi = form.group_mkopo_amount.value;
            formData.mkopo_benki = form.group_mkopo_lender.value;
            formData.mkopo_tarehe = form.group_mkopo_mwisho.value;
        }
        else{
            formData.mkopo_kiasi = detail.mkopo_kiasi;
            formData.mkopo_benki = detail.mkopo_benki;
            formData.mkopo_tarehe = detail.mkopo_tarehe;
        }
        formData.idadi_ya_hisa = form.group_hisa.value;
        formData.thamani_ya_hisa = form.group_hisa_value.value;
        formData.tarehe_uchaguzi = form.group_uchaguzi.value;

        formData.tarehe_mkutano = form.group_agm.value;
        formData.siku_ya_mikutano = form.group_meeting_day.value;
        let myTime = "00:00:00 AM";
        var am = form.group_meeting_am.value;
        var m = form.group_meeting_minute.value;
        let hour = parseInt(form.group_meeting_hour.value);
            let h = hour > 6 ? hour-6 : hour+6;
            myTime = h+":"+m+":00";
            myTime = (parseInt(am) === 0) ? myTime+" AM" : myTime+" PM";
        formData.muda_wa_mikutano = myTime;

        formData.changamoto = form.group_changamoto.value;
        formData.mwenyekiti = form.group_chairperson.value;
        formData.katibu = form.group_katibu.value;
        formData.simu_mwenyekiti = form.group_phone.value;
        formData.simu_katibu = form.group_phone2.value;

        if(form.group_type.value == "VSLA"){
                formData.mwalimu_cbt = form.group_cbt_name.value;
                formData.simu_cbt = form.group_cbt_phone.value;
                formData.jinsia_cbt = form.group_cbt_jinsia.value;
                formData.anuani_cbt = form.group_cbt_location.value;
        }
        
        updateGroup(formData).then(result=>{
            setIsLoading(false);
            
            if(result.status){
                props.onSave(result.groups);
                props.onCancel("groups");
                
            }
            else{
                setHasError(true);
                setErrorMsg(result.message);
            }
        })
        .catch(e=>{
            setIsLoading(false);
            setHasError(true);
            setErrorMsg(e);

        })
    }
    
    const updateGroup=(group)=>{
        return new Promise((resolve,reject)=>{
            let url = Commons.getURL("groups")+"/update.php";
            let body = {group:group,auth:loginState}
            let options = {
                method:"POST",
                body:JSON.stringify(body),
                headers:{
                    'Content-type':'application/json'
                }
                
            }
            console.log("gp: ",body);
            fetch(url,options)
            .then(res=>res.json())
            .then(result=>{
                console.log("res: ",result);
                resolve(result);
            })
            .catch(e=>{
                console.log("e: ",e)
                reject("Pole... Kuna tatizo limejitokeza");
            })
        })
    }
    const handleDateFocus=(e)=>{
        let tar = e.target;
        tar.type = "date";
        if(tar.id.includes("time")) tar.type = "time";
        
    }
    const makeTime=(e)=>{
        let val = parseInt(e.target.value);
        let options = ["Asubuhi","Jioni/Usiku"];
        if(val < 6){
            options = ["Asubuhi","Usiku"];
        }
        else if(val <10){
            options = ["Usiku","Mchana"];
        }
        else{
            options = ["Asubuhi","Jioni/Usiku"]
        }
        let am = document.getElementById("group_meeting_am");
        Array.from(am.children).forEach(c=>am.removeChild(c));
        options.forEach((o,i)=>{
            am.options.add(new Option(o,i));
        })
    }
    const handleWilayaChange=(e)=>{
        var val = e.target.value;
        if(val != "-1") setDistrict(val);
        else setDistrict(detail.wilaya);
    }
   
    const handleTypeChange=(e)=>{
        if(e.target.value == "Ushirika"){
            setIsVSLA(false)
        }
        else setIsVSLA(true);
    }
    useEffect(()=>{
        checkMkopo(hasMkopo);
        populateMikoa(detail.kanda);
        populateWilaya(detail.mkoa);

    },[hasMkopo]);

    detail.muda = getSwahiliTime(detail.muda_wa_mikutano);
    return(
        <form onSubmit={handleSubmit} className="border border-secondary bg-light my-4 col-md-10 col-lg-10 col-sm-10 offset-md-1 mx-auto offset-lg-1 offset-sm-1 row">
        <div className="text-medium">Hariri Taarifa za Kikundi</div>
        {errorMsg.length > 0 ? <div className={"my-2 "+(hasError ? "alert-danger" : "alert-success")}>{errorMsg}</div>:null}
    
        <div className="col-sm-10 col-md-10 col-lg-10 text-white bg-secondary bg-gradient offset-1 mx-auto mt-2 mb-0 ">1. Mahali</div>
            
        <fieldset className="col-md-10 col-lg-10 offset-1 mx-auto border m-2 mt-0 row">
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_zone">Kanda</label>
                <select onChange={handleZoneChange} className="form-control" id="group_zone" name="group_zone" defaultValue={detail.kanda}>
                    <option value="0">Bara</option>
                    <option value="1">Zanzibar</option>
                </select>
                
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_region">Mkoa</label>
                <select onChange={handleRegionChange} className="form-control" id="group_region" name="group_region" value={mkoa}>
                {loginState.ngazi == Commons.NGAZI_ADMIN || loginState.ngazi == Commons.NGAZI_SUPER ? mikoa.map(city=>{
                            return(<option key={city.key} value={city.key}>{city.name}</option>)
                        }):
                        mikoa.filter(m=>m.key.toLowerCase() == loginState.mkoa.toLowerCase().replaceAll(" ","_")).map(c=>{
                            return(<option key={c.key} value={c.key}>{c.name}</option>)
                        })}
                </select>
                   
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_district">Wilaya</label>
                <select className="form-control" id="group_district" name="group_district" value={district} onChange={handleWilayaChange}>
                    <option value="-1">--chagua wilaya--</option>
                    {wilaya.map(w=>{
                        return <option key={Commons.randomKey()} value={w.name.toLowerCase().replaceAll(" ","_")}>{w.name}</option>
                    })}
                </select>
                
            </div>
            
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_kata">Kata/Wadi</label>
                <input  className="form-control" type="text" id="group_kata" name="group_kata" defaultValue={detail.kata}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_mtaa">Mtaa/Kijiji/Shehia</label>
                <input  className="form-control" type="text" id="group_mtaa" name="group_mtaa" defaultValue={detail.mtaa}/>
            </div>
            
        </fieldset>
        <div className="col-sm-10 col-md-10 col-lg-10 text-white bg-secondary bg-gradient offset-1 mx-auto mt-2 mb-0 ">2. Uthibitisho</div>
            
        <fieldset className="col-md-10 col-lg-10 offset-1 mx-auto border m-2 mt-0 row">
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_name">Jina la Kikundi</label>
                <input  className="form-control" type="text" id="group_name" name="group_name" defaultValue={detail.jina}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_type">Aina ya Ushirika</label>
                <select className="form-control" id="group_type" name="group_type" defaultValue={detail.aina_ya_ushirika} onChange={handleTypeChange}>
                        <option>VSLA</option>
                        <option>Ushirika</option>
                    </select>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_registration">Aina ya Usajili</label>
                <input className="form-control" type="text" id="group_registration" name="group_registration" defaultValue={aina_ya_usajili}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_regnumber">Namba ya Usajili</label>
                <input  className="form-control" type="text" id="group_regnumber" name="group_regnumber" defaultValue={detail.namba_ya_usajili}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="">Kikundi kina katiba/Mwongozo?</label>
                <div className="col-lg-10 col-md-10 col-sm-12 row">
                    <div className="d-flex flex-row align-items-center col-lg-6 col-md-6 col-sm-6">
                        <input className="mx-2" type="radio"  id="group_katiba-yes" name="group_katiba" defaultChecked={detail.katiba_ya_chama == 1} />
                        <label htmlFor="group_katiba-yes">Ndiyo</label>
                    </div>
                    <div className="d-fleex flex-row align-items-center col-lg-6 col-md-6 col-sm-6">
                        <input className="mx-2" type="radio"   id="group_katiba-no" name="group_katiba"  defaultChecked={detail.katiba_ya_chama == 0 || detail.katiba_ya_chama == null} />
                        <label htmlFor="group_katiba-no">Hapana</label>
                    </div>
                </div>
                
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_regyear">Mwaka wa Usajili</label>
                <select id="group_regyear" name="group_regyear" className="form-control" defaultValue={detail.mwaka_wa_usajili}>
                   <option value="-1">--chagua mwaka--</option>
                    {Commons.generateYears(2000).map(y=>{
                        return(<option key={y}>{y}</option>)
                    })}
                </select>
             </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-12 col-lg-12">
                <label htmlFor="group_kazi">Kazi za Msingi</label>
                <textarea className="form-control" rows="5" id="group_kazi" name="group_kazi" defaultValue={detail.kazi_za_msingi}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_business">Aina ya Biashara</label>
                <input className="form-control" type="text" id="group_business" name="group_business" defaultValue={detail.aina_ya_biashara}  />
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_idadi">Idadi ya Wanachama</label>
                <input  max={30} min={10} className="form-control" type="number" id="group_idadi" name="group_idadi" defaultValue={detail.idadi_ya_wanachama} />
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_ukaguzi">Tarehe ya Ukaguzi wa Mwisho</label>
                <input onFocus={handleDateFocus} className="form-control" type="text" id="group_ukaguzi" name="group_ukaguzi" defaultValue={detail.tarehe_ukaguzi ? detail.tarehe_ukaguzi.split(" ")[0]:""}  />
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
               
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_benki">Namba ya Akaunti ya Benki</label>
                <input className="form-control" type="text" id="group_benki" name="group_benki" defaultValue={detail.akaunti_benki}   />
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_balance">Pesa Iliopo Benki</label>
                <input className="form-control" type="number" id="group_balance" name="group_balance"  defaultValue={detail.pesa_benki}   />
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="">Kikundi kina mkopo benki?</label>
                <div className="col-lg-10 col-md-10 col-sm-12 row">
                    <div className="d-flex flex-row align-items-center col-lg-6 col-md-6 col-sm-6">
                        <input className="mx-2" type="radio"  id="group_mkopo-yes" name="group_mkopo" value={parseInt(detail.mkopo) ==1}  onChange={()=>checkMkopo(true)} />
                        <label htmlFor="group_mkopo-yes">Ndiyo</label>
                    </div>
                    <div className="d-fleex flex-row align-items-center col-lg-6 col-md-6 col-sm-6">
                        <input className="mx-2" type="radio"   id="group_mkopo-no" name="group_mkopo" value={detail.mkopo == 0 || detail.mkopo == null}  onChange={()=>checkMkopo(false)} />
                        <label htmlFor="group_mkopo-no">Hapana</label>
                    </div>
                </div>
                
            </div>
            {
                hasMkopo ? 
                <>
                    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                        <label htmlFor="group_mkopo_amount">Kiasi cha Mkopo</label>
                        <input className="form-control" type="number" id="group_mkopo_amount" name="group_mkopo_amount" defaultValue={detail.mkopo_kiasi} />
                    </div>
                    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                        <label htmlFor="group_mkopo_mwisho">Mkopo unaisha lini?</label>
                        <input onFocus={handleDateFocus}  className="form-control" type="text" id="group_mkopo_mwisho" name="group_mkopo_mwisho" defaultValue={detail.mkopo_tarehe.split(" ")[0]} />
                    </div>
                    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                        <label htmlFor="group_mkopo_lender">Benki/Taasisi Iliyokopesha</label>
                        <input className="form-control" type="text" id="group_mkopo_lender" name="group_mkopo_lender" defaultValue={detail.mkopo_benki} />
                    </div>
                </>:null
            }
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_hisa">Idadi ya Hisa za Kikundi</label>
                <input className="form-control" type="number" id="group_hisa" name="group_hisa" defaultValue={detail.idadi_ya_hisa}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_hisa_value">Thamani ya kila hisa</label>
                <input className="form-control" type="number" id="group_hisa_value" name="group_hisa_value" defaultValue={detail.thamani_ya_hisa} />
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_uchaguzi">Tarehe ya Uchaguzi wa Viongozi</label>
                <input  onFocus={handleDateFocus} className="form-control" type="text" id="group_uchaguzi" name="group_uchaguzi"defaultValue={detail.tarehe_ukaguzi ? detail.tarehe_ukaguzi.split(" ")[0]:""} />
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_agm">Tarehe Mwisho ya Mkutano Mkuu</label>
                <input onFocus={handleDateFocus} className="form-control" type="text" id="group_agm" name="group_agm" defaultValue={detail.tarehe_mkutano ? detail.tarehe_mkutano.split(" ")[0]:""} />
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_meeting_day">Siku ya Mikutano</label>
                <select className="form-control" id="group_meeting_day" name="group_meeting_day" defaultValue={detail.siku_ya_mikutano}>
                    <option>Jumatatu</option>
                    <option>Jumanne</option>
                    <option>Jumatano</option>
                    <option>Alhamisi</option>
                    <option>Ijumaa</option>
                    <option>Jumamosi</option>
                    <option>Jumapili</option>
                </select>
                
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_meeting_time">Muda wa Mikutano</label>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    
                    <div className="d-flex flex-row justify-content-start align-items-center">
                        <select onChange={makeTime} className="form-control timebox mx-1" id="group_meeting_hour" name="group_meeting_hour" defaultValue={detail.muda.saa}>{Commons.generateHours().map(h=>{
                            return <option key={h}>{h}</option>
                        })}</select>:
                        <select className="form-control timebox mx-1" id="group_meeting_minute" name="group_meeting_minute" defaultValue={detail.muda.dkk}>{Commons.generateMinutes().map(m=>{
                            return <option key={m}>{m}</option>
                        })}</select>
                        <select className="form-control timebox-medium mx-1" id="group_meeting_am" name="group_meeting_am" defaultValue={detail.muda.wkt}>
                            <option value="0">Asubuhi</option>
                            <option value="1">Mchana/jioni</option>
                            </select>
                    </div>
                </div> </div>
        </fieldset>
        <div className="col-sm-10 col-md-10 col-lg-10 text-white bg-secondary bg-gradient offset-1 mx-auto mt-2 mb-0 ">3. Mengineyo</div>
            
        <fieldset className="col-md-10 col-lg-10 offset-1 mx-auto border m-2 mt-0 row">
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-12 col-lg-12">
                <label htmlFor="group_changamoto">Maoni ya Kikundi</label>
                <textarea className="form-control" rows="5" id="group_changamoto" name="group_changamoto" defaultValue={detail.changamoto} />
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_chairperson">Jina la Mwenyekiti</label>
                <input className="form-control" id="group_chairperson" name="group_chairperson" defaultValue={detail.mwenyekiti} />
                 
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_katibu">Jina la Katibu</label>
                <input className="form-control" id="group_katibu" name="group_katibu" defaultValue={detail.katibu} />
                 
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_phone">Simu ya Mwenyekiti</label>
                <input   className={"form-control "+(!validMPhone ? "border border-danger": "border border-success")}  type="number" id="group_phone" name="group_phone" defaultValue={detail.simu_mwenyekiti}  onInput={handlePhoneChange}/>
                {validMPhone== false ? <small className="text-danger">Namba ya simu si sahihi</small>:null}
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_phone2">Simu ya Katibu</label>
                <input   className={"form-control "+(!validKPhone ? "border border-danger": "border border-success")}  type="number" id="group_phone2" name="group_phone2" defaultValue={detail.simu_katibu}  onInput={handlePhoneChange}/>
                {validKPhone== false ? <small className="text-danger">Namba ya simu si sahihi</small>:null}
            </div>
            {isVSLA ? <>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
            <label htmlFor="group_cbt_name">Jina la Mwalimu CBT</label>
                <input className="form-control" type="text" id="group_cbt_name" name="group_cbt_name" defaultValue={detail.mwalimu_cbt} />
           
            </div>   
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
            <label htmlFor="group_cbt_jinsia">Jinsi</label>
                <select className="form-control" id="group_cbt_jinsia" name="group_cbt_jinsia" defaultValue={detail.jinsia_cbt}>
                    <option value="0">Ke</option>
                    <option value="1">Me</option>
                </select>
                
            </div> 
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_cbt_phone">Namba ya Simu</label>
                <input   className={"form-control "+(!validCPhone ? "border border-danger": "border border-success")}  type="number" id="group_cbt_phone" name="group_cbt_phone" defaultValue={detail.simu_cbt} onInput={handlePhoneChange}/>
                {validCPhone== false ? <small className="text-danger">Namba ya simu si sahihi</small>:null}
            </div> 
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group_cbt_location">Anuani</label>
                <input className="form-control" type="text" id="group_cbt_location" name="group_cbt_location" defaultValue={detail.anuani_cbt} />
           
            </div> 
            </>:null}
            <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row">
                {isLoading ? <Spinner isShowing={isLoading} size="spinner"/>: <input className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" type="submit" value="HIFADHI"/>}
                <input onClick={()=>handleCancelForm(null)} className="col-md-3 col-lg-2 col-sm-6 btn text-primary" type="button" value="GHAIRI"/>
            </div>
        </fieldset>
    </form>
          
    )
}
export default GroupEditForm;