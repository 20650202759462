import '../App.css';
import React from 'react';
import logo from "../logo.png";
import Header from './header'; 
import {Link}from 'react-router-dom';
class Error extends React.Component {
    constructor(props) {
      super(props);
     
    }
    
    render() {
        return (
          <><Header />
              <main className="my-4 py-4 text-center">
                <img src={logo} className="front_logo mx-auto"/>
                <div className="mx-auto col-md-8 col-lg-8 col-sm-10 offset-2 m-2 p-4">
                      <div className="mx-auto col-md-8 col-lg-8 col-sm-10 offset-2 offset-sm-1 m-2 p-4 alert-danger border-alert">
                        
                <h1><span className="material-icons display-1">bug_report</span></h1>
                        <p>Kuna tatizo limetokea; Tafadhali wasiliana na mtawala wa mfumo huu</p>

                        </div>
                      <a href="/mwanzo" className="btn btn-primary">RUDI DASHIBODI</a>
                </div>
              </main>
              </>
              )
            }
      
  }
  export default Error;