import React from 'react';
import Commons from '../utils/commons';
const LoanSummaryItem = (props)=>{
    
    var position = props.position;
    var data = props.loan;
    
    var loanLabels =Commons.getHisaMikopoLabels();
    
    let width = "col-lg-2 col-md-2";
    if(loanLabels.length > 6) width = "col-lg-1 col-md-1";
    if(loanLabels.length == 4) width = "col-lg-3 col-md-3";
    if(loanLabels.length == 3) width = "col-lg-4 col-md-4";

    return(
        <div className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-start align-items-center text-left list-item "+((position%2==0) ? "list-item-shade":"bg-light")}>
            {data? (
            loanLabels.map((d,i)=>{
                let val = data[d.key];
                let align = "text-left";
                if(d.key.includes("thamani") || d.key.includes("idadi")) {
                    val = Commons.thousandSeparator(val);
                  
                }
                return(<div key={Commons.randomKey()} className={width+" "+align }>{val}</div>)
             
            })  
            ):(
                <div className="text-center col-lg-12 col-md-12">Hakuna Taarifa</div>
            )
            }
        </div>
                    
    )
}
export default LoanSummaryItem;