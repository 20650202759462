import { randomUUID } from 'crypto';
import React from 'react';
import Commons from '../utils/commons';
import ListHeadRow from './header_row';
const LoanSummaryGrouped = (props)=>{
    
    var data = props.data;
    if(props.type == "hisa_mikopo"){
        data = props.data.map(d=>{
            let dm = d;
            dm.thamani_ya_mikopo = d.mkopo;
            dm.idadi_ya_hisa = d.hisa;
            dm.thamani_ya_hisa = d.thamani_ya_hisa;
            return dm;
        })
    }
    var loanLabels = props.labels;
    let width = "col-lg-2 col-md-2";
    if(loanLabels.length > 6) width = "col-lg-1 col-md-1";
    if(loanLabels.length == 4) width = "col-lg-3 col-md-3";
    if(loanLabels.length == 3) width = "col-lg-4 col-md-4";
    
    return(<>
        <ListHeadRow data={loanLabels} />
        {
            data.map((gd,k)=>{
                return (<div key={gd.key}>
                    {props.type == "mikopo_vikundi" ?
                    <div className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-start align-items-center text-left list-item bg-success text-white"}>
                    <div className={"col-md-8 col-lg-8"}>{
                    gd.key.toUpperCase()
                    
                    }</div>
                  
                    <div  className={width+" text-right"}>{
                    Commons.thousandSeparator(gd.mkopo)
                    
                    }</div>
                   
                  </div>:(props.type == "mikopo_wanachama" ?
                    <div className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-start align-items-center text-left list-item bg-success text-white"}>
                      <div className={width+""}>{
                      gd.key.toUpperCase()
                      
                      }</div>
                      <div  className={width+" text-right"}>{
                    //   Commons.thousandSeparator(gd.mkopo)
                      
                      }</div>
                      <div  className={width+" text-right"}>{
                      Commons.thousandSeparator(gd.mkopo)
                      
                      }</div>
                      <div  className={width+" text-right"}>{
                      Commons.thousandSeparator(gd.idadi_ya_mikopo)
                      
                      }</div>
                      <div  className={width+" text-right"}>{
                      Commons.thousandSeparator(gd.marejesho)
                      
                      }</div>
                      <div  className={width+" text-right"}>{
                      Commons.thousandSeparator(gd.baki)
                      
                      }</div>
                    </div>:
                    <div className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-start align-items-center text-left list-item bg-success text-white"}>
                      <div className={width+""}>{
                      gd.key.toUpperCase()
                      
                      }</div>
                     
                    </div>)}
                    {
                       props.type == "vsla" || props.type == "ushirika" || props.type == "akaunti_benki" ||  props.type == "no_akaunti_benki"|| props.type == "cbt" || props.type == "cbt_no" || props.type == "mikopo_wanachama" || props.type == "mikopo_vikundi" || props.type == "nafasi_wanachama" || props.type == "ajira_wanachama" ?
                        (gd.val.map((v,p)=>{
                            
                            return(
                            <div key={Commons.randomKey()} className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-start align-items-center text-left list-item"}>
                                {loanLabels.map(d=>{
                                    let val = v[d.key];
                                    
                                    if(val && d.key == "kikundi" && typeof(v[d.key]) == "object") val = v[d.key].jina;
                                    if(val && d.key == "mwenyekiti" && typeof(v[d.key]) == "object") val = v[d.key].jina;
                                    if(val && d.key == "katibu" && typeof(v[d.key]) == "object") val = v[d.key].jina;
                                    let align = "text-left";
                                    if(d.key.includes("marejesho")|| d.key.includes("mkopo") || d.key.includes("baki") || d.key.includes("thamani") || d.key.includes("idadi")) {
                                        
                                        val = Commons.thousandSeparator(val);
                                        align = "text-right";
                                    }
                                    if(val && d.key.includes("date") || d.key.includes("tarehe")){
                                        let d = new Date(Date.parse(val));
                                        val = d.getDate()+"-"+Commons.getMonth(d.getMonth())+"-"+d.getFullYear();
                                        align = "text-right";
                                    }
                                    if(val && d.key == "mkoa"){
                                        val = val.toUpperCase();
                                    }
                                    if(val && d.key == "wilaya"){
                                        val = val.toUpperCase();
                                    }
                                    return(<div key={Commons.randomKey()} className={width+" "+align }>{val}</div>)
                                
                                    })  
                                }
                            </div>)
                        })
                        ):
                        (
                            <div className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-start align-items-center text-left list-item"}>
                                {loanLabels.map((d,i)=>{
                                    let val = gd[d.key];
                                    
                                    let align = "text-left";
                                    if(d.key.includes("marejesho")|| d.key.includes("mkopo") || d.key.includes("baki") || d.key.includes("thamani") || d.key.includes("idadi")) {
                                        
                                        val = Commons.thousandSeparator(val);
                                      
                                    }
                                    return(<div key={Commons.randomKey()} className={width+" "+align }>{val}</div>)
                                
                                    })  
                                }
                            </div>

                        )
                        }
                    
                </div>)
            })
        }
       </>)
}
export default LoanSummaryGrouped;
