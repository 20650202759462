import React, { useEffect, useState } from "react";
import UserForm from "./user_form";
import UserItem from "./user_list_item";
import ListHeadRow from "./header_row";
import Commons from "../utils/commons";
import UserDetail from "./user_detail";
import UserEditForm from "./user_edit_form";
import LoginForm from "./login_form";
import Header from "./header";
import SideBar from "./sidebar";
const Users=(props)=>{
    const storage = window.localStorage;
    const session = window.sessionStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(session.getItem("loginState"));
    const [showUsersList,setShowUsersList] = useState(true);
    const [showUserForm,setShowUserForm] = useState(false);
    const [showUserEditForm,setShowUserEditForm] = useState(false);
    const [selectedUser,setSelectedUser] = useState();
    // const [groups,setGroups] = useState(false);
    const [users,setUsers] = useState([]);
    useEffect(()=>{
        loadDataFromStorage(storedData,()=>{
            
        })
    },[]);
    const sortUsers=(users)=>{
        return users.sort((a,b)=>{
            if(a.jina.toLowerCase() < b.jina.toLowerCase()) return -1;
            else if(a.jina.toLowerCase() > b.jina.toLowerCase()) return 1;
            else return 0;
         });
    }
    const search=(searchKey)=>{
        if(searchKey.length > 0){
             var gps = storedData.users.filter(m=>{
                 return m.jina.toLowerCase().includes(searchKey)||m.wilaya.toLowerCase().includes(searchKey)
             });
             setUsers(sortUsers(gps));
        }
        else{

            setUsers(sortUsers(storedData.users));
        }
    }
    
    const updateUsers=(data)=>{
        var users = data.map(m=>{
            let u=m;
            // u.ngazi = (parseInt(m.ngazi) === 0) ? "Mtumiaji wa Kawaida" : "Mtawala wa Mfumo";
            return u;
        });
        setUsers(users);
    }
   
    const userLabels = Commons.getUserListLabels();
   
    const loadDataFromStorage = (storedData,callback)=>{
        if(storedData){
            var users = (storedData.users) ? storedData.users:[];
            setUsers(users);
            callback();
        }
    }
    const handleAuth=(u)=>{
        session.setItem("loginState",JSON.stringify(u));
        loginState = u;
        storedData = JSON.parse(storage.getItem("data"));
        loadDataFromStorage(storedData);
    }
    const handleShowUserForm=(tag)=>{
        switch(tag){
            case "users":
                setShowUsersList(true);
                setShowUserForm(false);
                setShowUserEditForm(false);
                break;
            case "user_form":
                setShowUsersList(false);
                setShowUserForm(true);
                setShowUserEditForm(false);
                break;
            case "user_edit":
                setShowUsersList(false);
                setShowUserForm(false);
                setShowUserEditForm(true);
            break;
            case "user_detail":
                setShowUsersList(false);
                setShowUserForm(false);
                setShowUserEditForm(false);
                break;
            case null:
            default:
                setShowUsersList(true);
                setShowUserForm(false);
                setShowUserEditForm(false);
        }
       
    }
    const showDetails=(data)=>{
        setSelectedUser(data);
        setShowUsersList(false);
        setShowUserForm(false);
        setShowUserEditForm(false);
    }
    if(loginState){
        return(
            <div>
           <Header onSearch={search} auth={loginState}/>
            <div className="container-fluid">
                <div className="row">
               
                <SideBar selected="watumiaji" auth={loginState} />
                    <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4 mx-auto my-4">
                    {showUsersList ? 
                     <>
                        <div className="col-lg-12 col-md-12 my-4 d-flex flex-row justify-content-end justify-content-sm-center align-items-center row">
                            <h3 className="col-lg-6 col-md-6 col-sm-12 my-4 text-left d-flex align-items-center justify-content-md-start justify-content-lg-start justify-content-sm-center">Taarifa za Watumiaji</h3>
                          
                            <div className="col-lg-6 col-md-6 my-4 d-flex flex-row justify-content-end align-items-center"> 
                                <span onClick={()=>handleShowUserForm("user_form")} className="col-md-4 col-lg-4 btn mx-1 btn-secondary d-flex flex-row justify-content-center align-items-center"><span className="material-icons">add</span>&nbsp;Mtumiaji</span>
                            </div>
                        </div>
                            <ListHeadRow data={userLabels}/>
                            {users.length > 0 ?
                                users.map((user,i)=>{
                                    return <UserItem user={user} key={Commons.randomKey()} position={i} onClick={(d)=>showDetails(d)}/>
                                })
                                :
                                <UserItem id={null} position={0}/>
                            }
    
                        
                        </>:(
                            showUserForm ? <UserForm onCancel={()=>handleShowUserForm("users")} onSave={(mbrs)=>updateUsers(mbrs)}/>
                            : (showUserEditForm ? <UserEditForm  user={selectedUser} onCancel={()=>handleShowUserForm("users")}  onSave={(mbrs)=>updateUsers(mbrs)} /> :<UserDetail user={selectedUser} onCancel={(tag)=>handleShowUserForm(tag)} />)
                        )
                        }
                     </main>
                </div>
            </div>
        </div>
        )
    }
    else return (<LoginForm target="/watumiaji" onAuth={(user=>handleAuth(user))} />);
    
}
export default Users;