import {v4 as uuid} from 'uuid';

class Commons{
    MINIMUM_AGE = 17;
    PAGE_LIMIT = 100;
    NGAZI_ADMIN = 3;
    NGAZI_DATA = 0;
    NGAZI_MANAGER = 2;
    NGAZI_EVAL = 1;
    NGAZI_SUPER = 5;
    DEFAULT_PORT = "4000";
    LOCATION = window.location;
    BASE_URL = "https://fgms.u/vuvi.go.tz";
    // BASE_URL = "http://localhost";
    URLS = [{endpoint:"roles",path:"api/roles"},{endpoint:"login",path:"api/auth"},{endpoint:"data",path:"api/data"},{endpoint:"users",path:"api/users"},{endpoint:"members",path:"api/members"},{endpoint:"groups",path:"api/groups"},];
    getRegionalData = ()=>{
        return [
            {
                "id":0,
                "name":"Bara",
                "regions":[
                            {   "key":"arusha",
                                "name":"Arusha",
                                "districts":[
                                    {
                                        "name":"Arusha CBD",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Arusha",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Karatu",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Longido",
                                        "wards":[]
                                    },{
                                        "name":"Meru",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Monduli",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Ngorongoro",
                                        "wards":[]
                                    }
                                ]
                                },
                            {"key":"dar_es_salaam","name":"Dar es Salaam",
                                "districts":[
                                    {
                                        "name":"Ilala CBD",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Kigamboni",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Kinondoni",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Temeke",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Ubungo",
                                        "wards":[]
                                    }
                                ]
                            },
                            {"key":"dodoma",
                                "name":"Dodoma",
                                "districts":[
                                    {
                                        "name":"Bahi",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Chamwino",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Chemba",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Dodoma",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Kondoa",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Kongwa",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Mpwampwa",
                                        "wards":[]
                                    }
                                ]
                            },
                            {"key":"geita",
                                "name":"Geita",
                                "districts":[
                                    {
                                        "name":"Bukombe",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Chato",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Geita",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Mbogwe",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Nyang'hwale",
                                        "wards":[]
                                    },
                                    
                                ]
                            },
                            {"key":"iringa",
                                "name":"Iringa",
                                "districts":[
                                    {
                                        "name":"Iringa Mjini",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Iringa Vijijini",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Kilolo",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Mafinga",
                                        "wards":[]
                                    },
                                    {
                                        "name":"Mufindi",
                                        "wards":[]
                                    },
                                    
                                ]
                            },
                            {"key":"kagera",
                                "name":"Kagera",
                            "districts":[
                                {
                                    "name":"Biharamulo",
                                    "wards":[]
                                },
                                {
                                    "name":"Bukoba Mjini",
                                    "wards":[]
                                },
                                {
                                    "name":"Bukoba Vijijini",
                                    "wards":[]
                                },
                                {
                                    "name":"Karagwe",
                                    "wards":[]
                                },
                                {
                                    "name":"Kyerwa",
                                    "wards":[]
                                },
                                {
                                    "name":"Missenyi",
                                    "wards":[]
                                },
                                {
                                    "name":"Muleba",
                                    "wards":[]
                                },
                                {
                                    "name":"Ngara",
                                    "wards":[]
                                }
                            ]
                            },
                            
                            {"key":"katavi",
                                "name":"Katavi",
                                "districts":[{
                                    "name":"Mlele","wards":[]
                                },{
                                    "name":"Mpanda Mjini","wards":[]
                                },{
                                    "name":"Mpanda Vijijini","wards":[]
                                },
                            ]
                            },{"key":"kigoma",
                                "name":"Kigoma",
                                "districts":[{
                                    "name":"Buhigwe","wards":[]
                                },{
                                    "name":"Kakonko","wards":[]
                                },{
                                    "name":"Kasulu Mjini","wards":[]
                                },{
                                    "name":"Kasulu Vijijini","wards":[]
                                },{
                                    "name":"Kibondo","wards":[]
                                },{
                                    "name":"Kigoma Mjini","wards":[]
                                },{
                                    "name":"Kigoma Ujiji","wards":[]
                                },{
                                    "name":"Uvinza","wards":[]
                                },
                            ]
                            },
                            {"key":"kilimanjaro",
                                "name":"Kilimanjaro",
                                "districts":[{
                                    "name":"Hai","wards":[]
                                },{
                                    "name":"Moshi Mjini","wards":[]
                                },{
                                    "name":"Moshi Vijijini","wards":[]
                                },{
                                    "name":"Mwanga","wards":[]
                                },{
                                    "name":"Rombo","wards":[]
                                },{
                                    "name":"Same","wards":[]
                                },{
                                    "name":"Siha","wards":[]
                                }
                            ]
                            },
                            
                            {"key":"lindi",
                                "name":"Lindi",
                                "districts":[{
                                    "name":"Kilwa","wards":[]
                                },{
                                    "name":"Lindi","wards":[]
                                },{
                                    "name":"Lindi Vijijini","wards":[]
                                },{
                                    "name":"Liwale","wards":[]
                                },{
                                    "name":"Nachingwea","wards":[]
                                },{
                                    "name":"Ruangwa","wards":[]
                                },{
                                    "name":"Mtama","wards":[]
                                }
                            ]
                            },
                            {"key":"manyara",
                                "name":"Manyara",
                                "districts":[{
                                    "name":"Babati Mjini","wards":[]
                                },{
                                    "name":"Babati Vijijini","wards":[]
                                },{
                                    "name":"Hanang","wards":[]
                                },{
                                    "name":"Kiteto","wards":[]
                                },{
                                    "name":"Mbulu","wards":[]
                                }
                                
                            ]
                            },{"key":"mara",
                                "name":"Mara",
                                "districts":[{
                                    "name":"Bunda","wards":[]
                                },{
                                    "name":"Butiama","wards":[]
                                },{
                                    "name":"Musoma Mjini","wards":[]
                                },{
                                    "name":"Musoma Vijijini","wards":[]
                                },{
                                    "name":"Rorya","wards":[]
                                },{
                                    "name":"Serengeti","wards":[]
                                },{
                                    "name":"Tarime","wards":[]
                                }
                            ]
                            },{"key":"mbeya",
                                "name":"Mbeya",
                                "districts":[{
                                    "name":"Busokelo","wards":[]
                                },{
                                    "name":"Chunya","wards":[]
                                },{
                                    "name":"Kyela","wards":[]
                                },{
                                    "name":"Mbarali","wards":[]
                                },{
                                    "name":"Mbeya CBD","wards":[]
                                },{
                                    "name":"Mbeya Vijijini","wards":[]
                                },{
                                    "name":"Rungwe","wards":[]
                                }
                            ]
                            },{"key":"morogoro",
                                "name":"Morogoro",
                                "districts":[{
                                    "name":"Gairo","wards":[]
                                },{
                                    "name":"Kilombero","wards":[]
                                },{
                                    "name":"Kilosa","wards":[]
                                },{
                                    "name":"Malinyi","wards":[]
                                },{
                                    "name":"Morogoro Mjini","wards":[]
                                },{
                                    "name":"Morogoro Vijijini","wards":[]
                                },{
                                    "name":"Mvomero","wards":[]
                                },{
                                    "name":"Ulanga","wards":[]
                                },
                            ]
                            },
                            {"key":"mtwara",
                                "name":"Mtwara",
                                "districts":[{
                                    "name":"Masasi Mjini","wards":[]
                                },{
                                    "name":"Masasi Vijijini","wards":[]
                                },{
                                    "name":"Mtwara Mjini","wards":[]
                                },{
                                    "name":"Mtwara Vijijini","wards":[]
                                },{
                                    "name":"Nanyumbu","wards":[]
                                },{
                                    "name":"Newala","wards":[]
                                },{
                                    "name":"Tandahimba","wards":[]
                                }
                            ]
                            },
                            {"key":"mwanza",
                                "name":"Mwanza",
                                "districts":[{
                                    "name":"Ilemela","wards":[]
                                },{
                                    "name":"Kwimba","wards":[]
                                },{
                                    "name":"Magu","wards":[]
                                },{
                                    "name":"Misungwi","wards":[]
                                },{
                                    "name":"Nyamagana","wards":[]
                                },{
                                    "name":"Sengerema","wards":[]
                                },{
                                    "name":"Ukerewe","wards":[]
                                }
                            ]
                            },
                            {"key":"njombe",
                                "name":"Njombe",
                                "districts":[{
                                    "name":"Ludewa","wards":[]
                                },{
                                    "name":"Makambako","wards":[]
                                },{
                                    "name":"Makete","wards":[]
                                },{
                                    "name":"Njombe Mjini","wards":[]
                                },{
                                    "name":"Njombe Vijijini","wards":[]
                                },{
                                    "name":"Wanginge","wards":[]
                                }
                            ]
                            },
                            {"key":"pwani",
                                "name":"Pwani",
                                "districts":[{
                                    "name":"Bagamoyo","wards":[]
                                },{
                                    "name":"Kibaha Mjini","wards":[]
                                },{
                                    "name":"Kibaha Vijijini","wards":[]
                                },{
                                    "name":"Kisarawe","wards":[]
                                },{
                                    "name":"Mafia","wards":[]
                                },{
                                    "name":"Mkuranga","wards":[]
                                },{
                                    "name":"Rufiji","wards":[]
                                }
                            ]
                            },
                            {"key":"rukwa",
                                "name":"Rukwa",
                                "districts":[{
                                    "name":"Kalambo","wards":[]
                                },{
                                    "name":"Nkasi","wards":[]
                                },{
                                    "name":"Sumbawanga Mjini","wards":[]
                                },{
                                    "name":"Sumbawanga Vijijini","wards":[]
                                }
                            ]
                            },
                            {"key":"ruvuma",
                                "name":"Ruvuma",
                                "districts":[{
                                    "name":"Mbinga","wards":[]
                                },{
                                    "name":"Namtumbo","wards":[]
                                },{
                                    "name":"Nyasa","wards":[]
                                },{
                                    "name":"Songea Mjini","wards":[]
                                },{
                                    "name":"Songea Vijijini","wards":[]
                                }
                            ]
                            },
                            {"key":"shinyanga",
                                "name":"Shinyanga",
                                "districts":[{
                                    "name":"Kahama Mjini","wards":[]
                                },{
                                    "name":"Kahama Vijijini","wards":[]
                                },{
                                    "name":"Kishapu","wards":[]
                                },{
                                    "name":"Shinyanga Mjini","wards":[]
                                },{
                                    "name":"Shinyanga Vijijini","wards":[]
                                }
                            ]
                            },
                            {"key":"simiyu",
                                "name":"Simiyu",
                                "districts":[{
                                    "name":"Bariadi","wards":[]
                                },{
                                    "name":"Buswega","wards":[]
                                },{
                                    "name":"Itilima","wards":[]
                                },{
                                    "name":"Maswa","wards":[]
                                },{
                                    "name":"Meatu","wards":[]
                                }
                            ]
                            },
                            {"key":"singida",
                                "name":"Singida",
                                "districts":[{
                                    "name":"Ikungi","wards":[]
                                },{
                                    "name":"Iramba","wards":[]
                                },{
                                    "name":"Manyoni","wards":[]
                                },{
                                    "name":"Mkalama","wards":[]
                                },{
                                    "name":"Singida Mjini","wards":[]
                                },{
                                    "name":"Singida Vijijini","wards":[]
                                }
                            ]
                            },
                            {"key":"songwe",
                                "name":"Songwe",
                                "districts":[{
                                    "name":"Ileje","wards":[]
                                },{
                                    "name":"Mbozi","wards":[]
                                },{
                                    "name":"Momba","wards":[]
                                },{
                                    "name":"Songwe","wards":[]
                                }
                            ]
                            },
                            {"key":"tabora",
                                "name":"Tabora",
                                "districts":[{
                                    "name":"Igunga","wards":[]
                                },{
                                    "name":"Kaliua","wards":[]
                                },{
                                    "name":"Nzega","wards":[]
                                },{
                                    "name":"Sikonge","wards":[]
                                },{
                                    "name":"Tabora","wards":[]
                                },{
                                    "name":"Urambo","wards":[]
                                },{
                                    "name":"Uyui","wards":[]
                                }
                            ]
                            },
                            {"key":"tanga",
                                "name":"Tanga",
                                "districts":[{
                                    "name":"Handeni","wards":[]
                                },{
                                    "name":"Kilindi","wards":[]
                                },{
                                    "name":"Korogwe","wards":[]
                                },{
                                    "name":"Lushoto","wards":[]
                                },{
                                    "name":"Muheza","wards":[]
                                },{
                                    "name":"Mkinga","wards":[]
                                },{
                                    "name":"Pangani","wards":[]
                                },{
                                    "name":"Tanga","wards":[]
                                }
                            ]
                            }
                ]
                    },
            {
                "id":1,
                "name":"Zanzibar",
                "regions":[
                    {"key":"kaskazini_pemba",
                        "name":"Kaskazini Pemba",
                        "districts":[{
                            "name":"Micheweni","wards":[]
                        },{
                            "name":"Wete","wards":[]
                        },
                    ]
                    },
                    {"key":"kaskazini_unguja",
                        "name":"Kaskazini Unguja",
                        "districts":[{
                            "name":"Kaskazini A","wards":[]
                        },{
                            "name":"Kaskazini B","wards":[]
                        },
                    ]
                    },
                    {"key":"kusini_pemba",
                        "name":"Kusini Pemba",
                        "districts":[{
                            "name":"Chake chake","wards":[]
                        },{
                            "name":"Mkoani","wards":[]
                        }
                    ]
                    },
                    {"key":"kusini_unguja",
                        "name":"Kusini Unguja",
                        "districts":[{
                            "name":"Kati","wards":[]
                        },{
                            "name":"Kusini","wards":[]
                        }
                    ]
                    },
                    {"key":"mjini_magharibi",
                    "name":"Mjini Magharibi",
                        "districts":[{
                            "name":"Magharibi A","wards":[]
                        },{
                            "name":"Magharibi B","wards":[]
                        },{
                            "name":"Mjini","wards":[]
                        }
                    ]
                    },
                ]
            }
            ]
    }
    randomId=()=>{
        var chars=["a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z,0,1,2,3,4,5,6,7,8,9"];
        let result = "";
        for(let i=0;i<6;i++){
            result += chars[Math.floor(Math.random()*chars.length)];
        }
       
        return result;
    }
    randomKey=()=>{
        return uuid();
    }
    getHisaMikopoLabels=()=>{
        return [{key:"idadi_ya_hisa",label:"Idadi ya Hisa"},{key:"thamani_ya_hisa",label:"Thamani ya Hisa"},{key:"idadi_ya_mikopo",label:"Idadi ya Mikopo"},{key:"thamani_ya_mikopo",label:"Thamani ya Mikopo"}]
    }
    getMemberListLabels=()=>{
        return [{key:"jina",label:"Jina"},{key:"kikundi",label:"Kikundi"},{key:"nafasi",label:"Nafasi"},{key:"wilaya",label:"Wilaya"},{key:"mtaa",label:"Mtaa/Kijiji/Shehia"},{key:"simu",label:"Simu"}]
    }
    getGroupListLabels=()=>{
        return [{key:"jina",label:"Jina"},{key:"wilaya",label:"Wilaya"},{key:"mtaa",label:"Mtaa/Kijiji/Shehia"},{key:"mwenyekiti",label:"Mwenyekiti"},{key:"katibu",label:"Katibu"},{key:"mwalimu_cbt",label:"CBT Mwalimu"}];
        // {key:"idadi_ya_hisa",label:"Idadi ya Hisa"},{key:"simu",label:"Simu"}
    }
    getLoanListLabels=(tag)=>{
        if(tag == 0){
            return [{key:"jina",label:"Jina la Mkopaji"},{key:"kikundi",label:"Kikundi"},{key:"mkopo",label:"Mkopo (Tshs)"},{key:"idadi_ya_mikopo",label:"Idadi ya Mikopo"},{key:"marejesho",label:"Rejesho (Tshs)"},{key:"baki",label:"Deni (Tshs)"}];//,{key:"kipindi",label:"Kipindi (Miezi)"},{key:"tarehe",label:"Tarehe ya Mkopo"}
        }
        else{
            return [{key:"jina",label:"Jina la Kikundi"},{key:"wilaya",label:"Wilaya"},{key:"mwenyekiti",label:"Mwenyekiti"},{key:"benki",label:"Taasisi Iliyokopesha"},{key:"mkopo",label:"Mkopo (Tshs)"},{key:"date",label:"Tarehe ya Kuisha"}];//,{key:"kipindi",label:"Kipindi (Miezi)"},{key:"tarehe",label:"Tarehe ya Mkopo"}
        }
    }
    getUserListLabels=()=>{
        return [{key:"jina",label:"Jina"},{key:"mkoa",label:"Mkoa"},{key:"wilaya",label:"Wilaya"},{key:"email",label:"Barua-pepe"},{key:"ngazi",label:"Ngazi ya Utawala"},{key:"simu",label:"Na. ya Simu"}];
    }
    getRoleListLabels=()=>{
        return [{key:"role_name",label:"Jina"},{key:"role_description",label:"Maelezo"},{key:"edit",label:"Hariri"},{key:"delete",label:"Ondoa"}];
    }
    getPreferences=()=>{
        return {
            mipangilio:{
                riba:{label:"Riba",value:10},
                wadhamini:{label:"Idadi ya Wadhamini",value:4},
                tarehe:{label:"Muundo wa tarehe",value:"DDMMYYYY"},
                lugha:{label:"Lugha",value:"Kiswahili"},
            }
        }

    }
    thousandSeparator =(x)=> {
        return (x === undefined || x === null) ? "": x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    validatePhone=(phone)=>{
        let result = true;
        let charSet = ["0","1","2","3","4","5","6","7","8","9"];
        if(phone == null) return false;
        if(typeof(phone) != "string") phone = phone.toString();
        if(phone.includes("+")) phone = phone.replaceAll("+","");
        if(phone.includes(" ")) phone = phone.replaceAll(" ","");
        if(phone.includes("-")) phone = phone.replaceAll("-","");
        
        for(let i=0;i<phone.length;i++){
            if(charSet.indexOf(phone[i]) === -1){
                result = false;
                break;
            }
        }
        if(!result) return result;
        if(phone.length != 12 && phone.length != 10) {
            return false;
        }
        if(phone.length === 10 && phone[0] != "0"){
            return false;
        }
        if(phone.length === 12 && phone.substring(0,3) !== "255"){
            return false;
        }
        return result;
    }
    groupBy=(data,group=null,withSum=false,sumColumns=[])=>{
        let workData =[];
        if(group == null || group == -1) return data;
        if(group === "jinsia" || group === "kanda"){
            workData = data.map(d=>{

                let nd = d;
                nd.jinsia = parseInt(d.jinsia) === 0 ? "Wanawake" : "Wanaume";
                nd.kanda = parseInt(d.kanda) === 0 ? "Bara" : "Zanzibar";
                return nd;
            })
        }
        else if(group == "mkoa"){
            workData = data.map(d=>{
                let zid = d.kanda == 0 || d.kanda ==1 ? d.kanda :((d.kanda == "Bara" || d.kanda =="bara") ? 0:1);
                let nd = d;
                nd.zone = d.kanda;
                let col = this.getRegionalData()[parseInt(zid)].regions.filter(g=>g.key == d.mkoa.toLowerCase().replaceAll(" ","_") || g.name.toLowerCase() == d.mkoa.toLowerCase().replaceAll("_"," "));
                nd.mkoa = col.length > 0 ? col[0].name : d.mkoa;
                return nd;
            })
        }
        else if(group == "wilaya"){
            workData = data.map(d=>{
                let zid = d.kanda == 0 || d.kanda ==1 ? d.kanda :((d.kanda == "Bara" || d.kanda =="bara") ? 0:1);
                let nd = d;
                // let col = this.getRegionalData()[parseInt(zid)].regions.map(g=>g.districts).filter(d=>d.name.toLowerCase() == d.wilaya.toLowerCase());
                nd.wilaya = d.wilaya.toLowerCase();
                return nd;
            })  
        }
       
        var res = [];

        var keys=[...new Set(workData.map(d=>d[group]))];
        keys.forEach(key=>{
            let p = {};
            let x = workData.filter(d=>d[group] === key);
            if(withSum && sumColumns.length > 0){
                sumColumns.forEach(col=>{
                    let sum = 0;
                    x.forEach(xv=>{
                        if(Object.keys(xv).map(k=>k.toLowerCase()).includes(col.toLowerCase())){
                            sum += parseInt(xv[col]);
                        }
                    })
                    p[col] = sum;
                })
            }
            p.key = key;
            p.val = x;
            res.push(p);
        });
        return res;
    }
    getURL=(endpoint)=>{
        if(endpoint) endpoint = endpoint.toLowerCase();
        var URL = this.URLS.filter(u=>u.endpoint.toLowerCase() === endpoint);
        if(URL.length > 0){
            var path = URL[0].path;
            // return this.BASE_URL +":"+this.DEFAULT_PORT +"/"+ path;
            return "/"+path;
        }
        return null;
    }
    //get months short
    getMonth=(index)=>{
        return ["Jan","Feb","Mac","Apr","Mei","Jun","Jul","Ago","Sep","Okt","Nov","Des"][index];
    }
    getMonths=()=>{
        return ["Jan","Feb","Mac","Apr","Mei","Jun","Jul","Ago","Sep","Okt","Nov","Des"];
    }
    //generate years
    //generates a list of year from start to end inclusive; if end is not provided the current year is set
    generateYears=(start,end=null)=>{
        let result = [];
        if(end === null || typeof(end) != "number"){
            end = new Date(Date.now()).getFullYear() -this.MINIMUM_AGE;
        }
        if(typeof(start) === "number" && start < end){
            for(let i=0;i<=(end-start);i++){
                result.push(end - i);
            }
        }
        return result;
    }
    generateHours=()=>{
        let result = [];
        for(let i=1;i<=12;i++){
            result.push(i);
        }
        return result;
    }
    generateMinutes=()=>{
        let result = [];
        for(let i=0;i<60;i++){
            let m = i;
            if(i < 10) m = "0"+i;
            result.push(m);
        }
        return result;
    }
}
export default new Commons();