import React, { useEffect, useState } from "react";
import LoginForm from "./login_form";
import commons from "../utils/commons";
import PasswordValidator from "password-validator";
import Spinner from "./spinner";
import RoleItem from "./role_list_item";
import ListHeadRow from "./header_row";
import Header from "./header";
import SideBar from "./sidebar";

const Settings=(props)=>{
    const storage = window.localStorage;
    const session = window.sessionStorage;
    var loginState = JSON.parse(session.getItem("loginState"));
    var storedData = JSON.parse(storage.getItem("data"));
    var passwordScheme = new PasswordValidator();
    const [pwdChanged,setPwdChanged] = useState(false);
    const [newPwd,setNewPwd] = useState("");
    const [pwdError,setPwdError] = useState(null);
    const [errorMsg,setErrorMsg] = useState("");
    const [hasError,setHasError] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [validPhone,setValidPhone] = useState(true);
    const [kanda,setkanda] = useState(loginState.kanda);
    const [mkoa,setMkoa] = useState(loginState.mkoa.toLowerCase().replaceAll(" ","_"));

    passwordScheme
    .is().min(8)
    .is().max(16)
    .has().uppercase()
    .has().lowercase()
    .has().symbols()
    .has().digits(1);
    
    const getDistricts=(mkoa)=>{
        var regions =  getRegions(kanda);
        var region = regions.find(r=>r.key.toLowerCase() == mkoa.toLowerCase().replaceAll(" ","_"));
        return region ? region.districts : [];
        
    }
    const getRegions=(kanda)=>{
        return commons.getRegionalData()[parseInt(kanda)].regions;
    }
   
    const checkPassword=(e)=>{
        let pwd = e.target.value;
        if(pwd.length >0){
            if(passwordScheme.validate(pwd)){
                setNewPwd(pwd);
                setPwdChanged(true);
                setPwdError(null);
            }
            else{
                setPwdError("Neno la siri halikidhi viwango");
            }
        }
        else{
            setNewPwd("");
            setPwdChanged(false);
            setPwdError(null);
        }
    }
    const handleSubmit=(e)=>{
        e.preventDefault();
        setIsLoading(true);
        var inputs = Array.from(e.target.elements);
        var fd = {};
        fd.user_id = loginState.user_id;
        fd.ngazi = loginState.ngazi;
        fd.kanda = loginState.kanda;
        fd.mkoa = loginState.mkoa;
        fd.wilaya = loginState.wilaya;
        fd.jina = inputs[0].value.trim();
        fd.email = loginState.email;
        fd.simu = inputs[3].value.trim();
        if(pwdChanged){
            fd.password = newPwd;
            fd.oldpassword = inputs[6].value.trim();
        }        
        
        console.log("fd1: ",fd);
        saveUser(fd).then(result=>{
            setIsLoading(false);
            console.log("result: ",result);
            if(result.status){
                loginState = result.user;
                session.setItem("loginState",JSON.stringify(loginState));
                setHasError(false);
                setErrorMsg(result.message);
                inputs[6].value = "";
                inputs[7].value = "";
            }
            else{
                setHasError(!result.status);
                setErrorMsg(result.message);
            }
            
        }).catch(e=>{
            setIsLoading(false);
            setHasError(true);
            setErrorMsg(e.toString());
        })

        
    }
    const handleAuth =(u)=>{
        if(loginState){
            loginState = u;
            session.setItem("loginState",JSON.stringify(loginState));
        }
        else{
            session.setItem("loginState",JSON.stringify(u));
        }
        loginState = JSON.parse(session.getItem("loginState"));
    }
    const saveUser=(user)=>{
        return new Promise((resolve,reject)=>{
            var url = commons.getURL("users")+"/update.php";
            var options = {
                body:JSON.stringify({user:user,auth:loginState,source:"settings"}),
                method:"POST",
                headers:{'Content-type':'application/json'}
            }

        fetch(url,options)
        .then(res=>res.json())
        .then(result=>{
            resolve(result)
        })
        .catch(e=>{
            console.log("err: ",e);
            reject("Pole! Haikuwezekana kuhifadhi taarifa mpya za mtumiaji ")
        })
        })
        
    }
    const saveRole=(role)=>{
        role.auth = loginState;
        return new Promise((resolve,reject)=>{
            var url = commons.getURL("roles")+(role.role_id ? "/update.php" : "/create.php");
            var options = {
                body:JSON.stringify(role),
                method:"POST",
                headers:{'Content-type':'application/json'}
            }

        fetch(url,options)
        .then(res=>res.json())
        .then(result=>{
            resolve(result);
        })
        .catch(e=>{
            console.log("err: ",e);
            reject("Pole! Haikuwezekana kuhifadhi taarifa mpya za ngazi")
        })
        })
        
    }
    const handleEdit=(role)=>{
        const roleForm = document.getElementById("role_form");
        roleForm.role_jina.value = role.role_name;
        roleForm.role_level.value = role.level;
        roleForm.role_description.value = role.role_description;
        roleForm.role_id.value = role.role_id;
    }
    const handleDelete=(role)=>{
        role.is_deleted = 1;
        if(window.confirm("Kama kuna watumiaji katika ngazi hii hawataweza kutumia mfumo; Ondoa?")){
            setIsLoading(true);
            saveRole(role).then((result=>{
                setIsLoading(false);
                if(result.status){
                    storedData.roles = result.roles;
                storage.setItem("data",JSON.stringify(storedData));
                storedData = JSON.parse(storage.getItem("data"));
                setHasError(false);
                setErrorMsg(result.message);
                }
                else{
                    setHasError(true);
                    setErrorMsg(result.message); 
                }
            }))
            .catch(e=>{
                setIsLoading(false);
                setHasError(true);
                setErrorMsg(e.toString());
            })
        }
    }
    const handleNgaziSubmit=(e)=>{
        e.preventDefault();
        var inputs = Array.from(e.target.elements);
        let u ={role_name:inputs[0].value,role_description:inputs[1].value,level:parseInt(inputs[2].value)};
        u.auth = loginState;
        if(inputs[3].id == "role_id") u.role_id = parseInt(inputs[3].value);
        console.log("test: ",u);
        saveRole(u).then(result=>{
            setIsLoading(false);
            console.log("result: ",result);
            if(result.status){
                storedData.roles = result.roles;
                storage.setItem("data",JSON.stringify(storedData));
                storedData = JSON.parse(storage.getItem("data"));
                setHasError(false);
                setErrorMsg(result.message);
            }
            else{
                setHasError(!result.status);
                setErrorMsg(result.message);
            }
        })
        .catch(e=>{
            setIsLoading(false);
            setHasError(true);
            setErrorMsg(e.toString());
        })
        .finally(()=>{
            e.target.reset();
        })
    }
    const handlePhoneChange=(e)=>{
        let phone = e.target.value;
        if(phone.length > 0){
            let valid = commons.validatePhone(phone);
            setValidPhone(valid);
        }
    }
    const toggleVisibility=(e)=>{
        if(e.target.id == "tg1"){
            if(e.target.textContent == "visibility"){
                e.target.textContent = "visibility_off";
                document.querySelector("#settings-password").type = "text";
            }
            else{
                e.target.textContent = "visibility";
                document.querySelector("#settings-password").type = "password";
            }
        }
        else{
            if(e.target.textContent == "visibility"){
                e.target.textContent = "visibility_off";
                document.querySelector("#settings-password1").type = "text";
            }
            else{
                e.target.textContent = "visibility";
                document.querySelector("#settings-password1").type = "password";
            }
        }
    }
    const handleMkoaChange=(e)=>{
        setMkoa(e.target.value);
    }
    const handleZoneChange=(e)=>{
        setkanda(parseInt(e.target.value));
    }

    if(loginState){
        return(
                <div>
                   <Header auth={loginState}/>    
                    <div className="container-fluid">
                        <div className="row">
                        
                       <SideBar selected="mipangilio" auth={loginState} />
                        <main className="col-md-9 ms-sm-auto col-lg-9 px-md-4 mx-auto my-4">
                        
                            <div className="col-lg-12 col-md-12 my-4 d-flex flex-row justify-content-end align-items-center">
                                <div className="col-lg-6 col-md-6 my-4 text-left d-flex align-items-center justify-content-start">
                                    <h3>Mipangilio</h3>
                                </div>
                                <div className="col-lg-6 col-md-6 my-4 d-flex flex-row justify-content-end align-items-center"> 
                                    {/* <span onClick={handleShowForm} className="col-md-4 col-lg-4 btn mx-1 btn-secondary d-flex flex-row justify-content-center align-items-center"><span className="material-icons">add</span>&nbsp;Mkopo</span>
                                    <span onClick={handleShowLoanForm} className="col-md-4 col-lg-4 mx-1 btn btn-secondary d-flex flex-row justify-content-center align-items-center"><span className="material-icons">add</span>&nbsp;Rejesho</span> */}
                                </div>
                            </div>
                            
                        <div> 
                            {errorMsg.length > 0 ?<div className={"p-2 col-lg-12 col-md-12 d-flex justify-content-center "+(hasError ? "alert-danger":"alert-success")}>{errorMsg}</div>:null}
                            
                            <form onSubmit={handleSubmit} className="row bg-light border my-2 p-2" autoComplete={"off"}>
                            
                                {/* <input type="hidden" autoComplete={false}/> */}
                                <div className="form-group col-lg-12 col-md-12 d-flex justify-content-end">
                                    <h4 className="py-2">Mtumiaji</h4>
                                </div>
                                <div className="my-2 form-group col-lg-6 col-md-6 d-flex flex-column align-items-start justify-content-start">
                                    <label htmlFor="settings-name">Jina</label>
                                    <input className="form-control" type="text" id="settings-name" name="settings-name" placeholder="Jina la mtumiaji" defaultValue={loginState.jina}/>
                                </div>
                                <div className="my-2 form-group col-lg-6 col-md-6 d-flex flex-column align-items-start justify-content-start">
                                    <label htmlFor="settings-ngazi">Ngazi</label>
                                    <select disabled={true} className="form-control" id="settings-ngazi" name="settings-ngazi" placeholder="ngazi ya mtumiaji" defaultValue={loginState.ngazi}>
                                        {storedData.roles.filter(r=>r.level == loginState.ngazi).map(r=>{
                                            return<option key={r.role_id} value={r.level}>{r.role_name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="my-2 form-group col-lg-6 col-md-6 d-flex flex-column align-items-start justify-content-start">
                                    <label htmlFor="settings-email">Barua-pepe</label>
                                    <input className="form-control" type="text" id="settings-email" name="settings-email" defaultValue={loginState.email} disabled={true}/>
                                </div>
                                <div className="my-2 form-group col-lg-6 col-md-6 d-flex flex-column align-items-start justify-content-start"><label htmlFor="settings-simu">Simu</label>
                                    <input className={"form-control "+(validPhone ? "":"border border-danger")  } type="number" id="settings-simu" name="settings-simu" defaultValue={loginState.simu} onChange={handlePhoneChange}/>
                                    {validPhone ? null:<small className="text-danger">Namba ya simu si sahihi</small>}
                                </div>
                                <div className="my-2 form-group col-lg-4 col-md-4 d-flex flex-column align-items-start justify-content-start">
                                    <label htmlFor="settings-kanda">Kanda</label>
                                    <select onChange={handleZoneChange} className={"form-control "} id="settings-kanda" name="settings-kanda" defaultValue={loginState.kanda == 0 ? 'Bara':"Zanzibar"} disabled={loginState.ngazi != commons.NGAZI_ADMIN && loginState.ngazi != commons.NGAZI_SUPER}>
                                        <option value={0}>Bara</option>
                                        <option value={1}>Zanzibar</option>
                                       
                                    </select>
                                </div>
                                <div className="my-2 form-group col-lg-4 col-md-4 d-flex flex-column align-items-start justify-content-start">
                                    <label htmlFor="settings-mkoa">Mkoa</label>
                                    <select className={"form-control "} id="settings-mkoa" name="settings-mkoa" defaultValue={loginState.mkoa.replaceAll(" ","_").toLowerCase()} disabled={loginState.ngazi != commons.NGAZI_ADMIN && loginState.ngazi != commons.NGAZI_SUPER} onChange={handleMkoaChange}>
                                        {getRegions(kanda).map(w=>{
                                            return <option key={w.key} value={w.key}>{w.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="my-2 form-group col-lg-4 col-md-4 d-flex flex-column align-items-start justify-content-start"><label htmlFor="settings-wilaya">Wilaya</label>
                                    <select className={"form-control "} id="settings-wilaya" name="settings-wilaya" defaultValue={loginState.wilaya.replaceAll("_"," ").toUpperCase()} disabled={loginState.ngazi != commons.NGAZI_ADMIN && loginState.ngazi != commons.NGAZI_SUPER}>
                                        {getDistricts(mkoa).map(w=>{
                                            return <option key={w.name} value={w.name.toLowerCase().replaceAll(" ","_")}>{w.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="form-group col-lg-12 col-md-12 d-flex justify-content-end my-2">
                                    <h4 className="py-2">Neno la Siri</h4>
                                </div>
                                <div className="my-2 form-group col-lg-6 col-md-6 d-flex flex-column align-items-start justify-content-start">
                                    <label htmlFor="settings-password">Neno la siri</label>
                                    <div className="form-control d-flex flex-row flex-justify-content-start flex-align-items-center">
                                    <input className={"form-control border border-white"} type="password" id="settings-password" name="settings-password"/>
                                    <span id="tg1" onClick={toggleVisibility} className="material-icons btn text-success">visibility</span>
                                    </div>
                                </div>

                                <div className="my-2 form-group col-lg-6 col-md-6 d-flex flex-column align-items-start justify-content-start">
                                    <label htmlFor="settings-password1">Neno la siri jipya</label>
                                    <div className="form-control d-flex flex-row flex-justify-content-start flex-align-items-center">
                                    <input onChange={(event)=>checkPassword(event)} className={"form-control border border-white"+(pwdError == null ? "" :"border border-danger")} type="password" id="settings-password1" name="settings-password1"/>
                                    <span id="tg2" onClick={toggleVisibility} className="material-icons btn text-success">visibility</span>
                                    </div>
                                    {pwdError ?<small className="text-left font-italic text-danger">Urefu usipungue 8, Angalau herufi 1 kubwa, 1 ndogo, tarakimu 1 na alama 1</small>:null}
                                </div>

                                
                                <div className="my-4 form-group d-flex align-items-center justify-content-between flex-row">
                                    {isLoading ? <span className="col-md-2 col-lg-2 col-sm-6 btn bg-primary text-white"><Spinner size="spinner" isShowing={isLoading}/></span>:(pwdError || !validPhone ? <input  className={"col-md-2 col-lg-2 col-sm-6 btn bg-primary text-white"} disabled/>:<input  className={"col-md-2 col-lg-2 col-sm-6 btn bg-primary text-white"} type="submit" value="SASISHA" />)}
                                    {/* <input onClick={()=>handleCancelForm("g")} className="text-primary col-md-2 col-lg-2 col-sm-6 btn w-30" type="button" value="GHAIRI"/> */}
                                </div>
                            </form>
                            {parseInt(loginState.ngazi) === commons.NGAZI_SUPER ?
                            <form id="role_form" onSubmit={handleNgaziSubmit} className="row bg-light border my-2 p-2" autoComplete={"off"}>
                            
                                {/* <input type="hidden" autoComplete={false}/> */}
                                <div className="form-group col-lg-12 col-md-12 d-flex justify-content-end">
                                    <h4 className="py-2">Ngazi za Watumiaji</h4>
                                </div>
                                <div className="form-group col-lg-12 col-md-12 d-flex justify-content-start">
                                    <ListHeadRow data={commons.getRoleListLabels()}/>
                                    
                                </div>
                                <div className="form-group col-lg-12 col-md-12 d-flex flex-column justify-content-start">
                                    
                                    {storedData.roles.map((r,i)=>{
                                        return(<RoleItem key={r.role_id} role={r} onEdit={(r)=>handleEdit(r)} onDelete={(r)=>handleDelete(r)} position={i}/>)
                                    })}
                                </div>
                                <div className="form-group col-lg-4 col-md-4 d-flex flex-column align-items-start justify-content-start mt-4">
                                        <label htmlFor="role_jina">Jina</label>
                                        <input className="form-control" required={true} type="text" id="role_jina" name="role_jina" placeholder="Jina la ngazi" />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 d-flex flex-column align-items-start justify-content-start mt-4">
                                        <label htmlFor="role_description">Maelezo</label>
                                        <input className="form-control" type="text" id="role_description" name="role_description" />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 d-flex flex-column align-items-start justify-content-start mt-4">
                                        <label htmlFor="role_level">Kazi</label>
                                        <select className="form-control" id="role_level" name="role_level">
                                            <option value="0">Kuingiza Taarifa</option>
                                            <option value="1">Kutathmini Vikundi</option>
                                            <option value="2">Kusimamia Vikundi</option>
                                            <option value="3">Utawala wa Mfumo</option>
                                            <option value="5">Mtawala Mkuu</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 d-flex flex-column align-items-start justify-content-start mt-4">
                                        <input type="hidden" name="role_id" id="role_id"/>
                                    </div>
                                    <div className="my-4 form-group d-flex align-items-center justify-content-between flex-row">
                                        {isLoading ? <span className="col-md-2 col-lg-2 col-sm-6 btn bg-primary text-white"><Spinner size="spinner" isShowing={isLoading}/></span>:<input className="col-md-2 col-lg-2 col-sm-6 btn bg-primary text-white" type="submit" value="HIFADHI"/>}
                                        {/* <input onClick={()=>handleCancelForm("g")} className="text-primary col-md-2 col-lg-2 col-sm-6 btn w-30" type="button" value="GHAIRI"/> */}
                                    </div>
                            </form>:null}
                            
                        </div>
                        </main>
                        </div>
                    </div>
                </div>
            )
    }
    else return (<LoginForm target="/mipangilio" onAuth={(u)=>handleAuth(u)}/>)
    
}
export default Settings;