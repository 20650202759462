
import React from "react";
import LoanListItem from "./loan_list_item";
import LoanSummaryItem from "./loan_summary_item";
import ListHeadRow from "./header_row";
import Commons from "../utils/commons";
import LoanSummaryGrouped from "./loan_summary_grouped";
import MemberListItem from "./member_list_item";
import GroupListItem from "./group_list_item";
import MyChart from "./my_chart";
import LoginForm from "./login_form";
import Header from "./header";
import SideBar from "./sidebar";
import PrintProvider, {Print} from 'react-easy-print';
import logo from "../logo.png";
class Report extends React.Component{
    constructor(props){
        super(props);
        this.storage = window.localStorage;
        this.session = window.sessionStorage;
        this.storedData = {members:[],groups:[]};
        this.loginState = JSON.parse(this.session.getItem("loginState"));
        this.regionData = Commons.getRegionalData();
        this.ids = ["1"];
        this.state = {
            data:[],
            type:"-1",
            columnHeads:[],
            groupBy:"-1",
            muundo:"list",
            isAuthenticated:this.loginState,
            title:""
        }   
        this.handleReportType = this.handleReportType.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.groupBy = this.groupBy.bind(this);
        this.handleMuundo = this.handleMuundo.bind(this);
        this.setMuundo = this.setMuundo.bind(this);
        this.handleAuth = this.handleAuth.bind(this);
        
    }
    groupBy=(g)=>{
        this.setState({groupBy:g})
        this.prepareData(this.state.type,g);
        this.setMuundo("list");
    }
  
    componentDidMount(){
        if(this.loginState){
            this.prepareData(this.state.type)
            var mk =[];
            this.regionData.forEach(z=>{
                z.regions.forEach(r=>{
                    mk.push(r);
                });
            
            });
            var wil = [];
            this.regionData.forEach(z=>{
                z.regions.forEach(r=>{
                    r.districts.forEach(d=>{
                        wil.push(d);
                    })
                })
            })
            this.loadData().then(result=>{
                this.storedData.groups = result.groups.map(g=>{
                    let ng = g;ng.group_id = parseInt(g.group_id);return ng;
                    
                })
                this.storedData.members = result.members.map(m=>{
                    let mb = m;
                        mb.mkopo = m.mkopo ? m.mkopo : 0;
                        mb.marejesho = m.marejesho ? m.marejesho : 0;
                        mb.hisa = m.hisa == null || m.hisa == "hakuna" || m.hisa == "" ? 0: m.hisa;
                        mb.thamani_ya_hisa = m.thamani_ya_hisa == null || m.thamani_ya_hisa == "hakuna" || m.thamani_ya_hisa == "" ? 0:  m.thamani_ya_hisa;
                        mb.idadi_ya_mikopo = m.idadi_ya_mikopo == null || m.idadi_ya_mikopo == "hakuna" || m.idadi_ya_mikopo == "" ? 0:  m.idadi_ya_mikopo;
                        var dob = new Date(Date.now()).getFullYear() - (m.mwaka == null || m.mwaka == "hakuna" || m.mwaka == "" ? 0:m.mwaka);
                        mb.umri = dob;
                        let kikundi = result.groups.find(g=>g.group_id === m.group_id);
                        mb.kikundi = kikundi ? kikundi : m.kikundi;
                        mb.baki = m.mkopo_kiasi - m.marejesho;
                        return mb;
                })
                if(this.loginState.ngazi == Commons.NGAZI_ADMIN){
                    this.storedData.users = result.users.filter(u=>u.kanda == this.loginState.kanda);
                    this.storedData.users = result.users.filter(u=>u.kanda == this.loginState.kanda);
                    this.storedData.groups = result.groups.filter(g=>g.kanda == this.loginState.kanda);
                    this.storedData.members = result.members.filter(m=>m.kanda == this.loginState.kanda);
                
                }
                else if(this.loginState.ngazi == Commons.NGAZI_MANAGER){
                    this.storedData.users = result.users.filter(u=>u.mkoa && u.mkoa.toLowerCase().replaceAll(" ","_")== this.loginState.mkoa.toLowerCase().replaceAll(" ","_"));
                    this.storedData.groups = result.groups.filter(g=>g.mkoa && g.mkoa.toLowerCase().replaceAll(" ","_")== this.loginState.mkoa.toLowerCase().replaceAll(" ","_"));
                    this.storedData.members = result.members.filter(m=>m.mkoa && m.mkoa.toLowerCase().replaceAll(" ","_")== this.loginState.mkoa.toLowerCase().replaceAll(" ","_"));
                    
                }
                else if(this.loginState.ngazi == Commons.NGAZI_SUPER) {
                    this.storedData.users = result.users;
                    this.storedData.groups = result.groups;
                    this.storedData.members = result.members;
                
                }
                else{
                    this.storedData.groups = result.groups.filter(g=>g.wilaya && g.wilaya.toLowerCase() == this.loginState.wilaya.toLowerCase());
                    this.storedData.members = result.members.filter(m=>m.wilaya && m.wilaya.toLowerCase() == this.loginState.wilaya.toLowerCase());
                
                }
                this.storedData.roles = result.roles;
                this.storedData.group_fields = result.group_fields.map(f=>f.Field.toLowerCase());
                this.storedData.member_fields = result.member_fields.map(f=>f.Field.toLowerCase());
        
            }).catch(e=>{
                console.log(e)
            })
        }
        
    }
    loadData=()=>{
        return new Promise((resolve,reject)=>{
           
            if(this.loginState){
                let user_url = Commons.getURL("data")+"/index.php";
                var options={
                    method:"POST",
                    body:JSON.stringify({auth:this.loginState}),
                    headers:{'Content-type':'application/json'}
                }
                fetch(user_url,options)
                .then(res=>res.json())
                .then(result=>{
                    
                    resolve(result);
                })
                .catch(e=>{
                    console.log(e);
                    reject("Pole... kuna tatizo limetokea");
                })
                
            }
            
        })
    }
   
    prepareData = (t,g=null)=>{
        if(this.storedData){
            var d;
            var loaners =this.storedData.members.filter(m=>m.mkopo > 0);
            var loans = loaners.map(m=>{
                let loan = {id:m.member_id};
                loan.kikundi = this.storedData.groups.find(g=>g.group_id == m.group_id);
                loan.wilaya = m.wilaya;
                loan.jina = m.jina;
                loan.thamani_ya_hisa = (m.thamani_ya_hisa) ? m.thamani_ya_hisa:0;
                loan.hisa = m.hisa;
                loan.idadi_ya_mikopo = m.idadi_ya_mikopo;
                loan.jinsia = m.jinsia;
                loan.mkopo = m.mkopo;
                loan.marejesho = m.marejesho;
                loan.baki = loan.mkopo -loan.marejesho;
                loan.kanda = m.kanda;
                loan.mkoa = m.mkoa;
                loan.wilaya = m.wilaya;
                return loan;
            });
            
            switch(t){
                case "mikopo_wanachama":
                    
                    d = Commons.groupBy(loans,g,true,['idadi_ya_mikopo','mkopo','thamani_ya_hisa','marejesho','baki']);
                    this.setState({data:d,columnHeads:Commons.getLoanListLabels(0),type:t,groupBy:g},()=>{
                        console.log("cl: ",this.state.columnHeads,d);
                    });
                    break;
                case "mikopo_vikundi":
                    var loaners =this.storedData.groups.filter(g=>g.mkopo_kiasi !="hakuna" && g.mkopo_kiasi !=null && g.mkopo_kiasi !="");
                    var loans = loaners.map(m=>{
                        let loan = {id:m.group_id};
                        loan.jina = m.jina;
                        loan.wilaya = m.wilaya;
                        loan.benki = (m.mkopo_benki && m.mkopo_benki !="" && m.mkopo_benki !="hakuna") ? m.mkopo_benki : "Hakuna";
                        loan.date = m.mkopo_tarehe;
                        loan.mwenyekiti = m.mwenyekiti;
                        loan.mkopo = m.mkopo_kiasi;
                        loan.thamani_ya_hisa = (m.thamani_ya_hisa) ? m.thamani_ya_hisa:0;
                        loan.hisa = m.idadi_ya_hisa;
                        loan.kanda = m.kanda;
                        loan.mkoa = m.mkoa;
                        loan.wilaya = m.wilaya;
                        return loan;
                    });
                    d = Commons.groupBy(loans,g,true,['mkopo','thamani_ya_hisa']);
                    
                    this.setState({data:d,columnHeads:Commons.getLoanListLabels(1),type:t,groupBy:g},()=>{
                        
                    });
                break;
                case "hisa_mikopo":
                    var res =[];
                    let columnHeads = Commons.getHisaMikopoLabels();
                    var sumMikopo = 0;
                    var sumIdadiHisa = 0;
                    var sumHisaThamani = 0;
                    var sumIdadiMikopo = 0;
                    if(g == null || g == -1){
                        loans.forEach(l=>{
                            sumMikopo += parseInt(l.mkopo);
                            sumIdadiHisa += parseInt(l.hisa);
                            sumIdadiMikopo += parseInt(l.idadi_ya_mikopo);
                            sumHisaThamani += parseInt(l.thamani_ya_hisa)
                        })
                        res.push({idadi_ya_hisa:sumIdadiHisa,thamani_ya_hisa:sumHisaThamani,idadi_ya_mikopo:sumIdadiMikopo,thamani_ya_mikopo:sumMikopo})
                    
                    }
                    else{
                        res = Commons.groupBy(loans,g,true,['idadi_ya_mikopo','mkopo','hisa','thamani_ya_hisa']);
                
                    }
                    
                    this.setState({data:res,columnHeads:columnHeads,type:t,groupBy:g},()=>{
                       
                    });   
                
                    break;
                case "nafasi_wanachama":
                    let mbrs = this.storedData.members.map(m=>{
                        let mb = m;
                        let g = this.storedData.groups.find(g=>g.group_id == m.group_id);
                        if(g) mb.kikundi = g;
                        return mb;
                    }).filter(m=>{
                        if(m.nafasi){
                            return (m.nafasi.toLowerCase() == "m/kiti" || m.nafasi.toLowerCase() == "mwenyekiti" || m.nafasi.toLowerCase() == "katibu" || m.nafasi.toLowerCase() == "muhazini"|| m.nafasi.toLowerCase() == "mwekahazina")
                        }
                    })
                    d = Commons.groupBy(mbrs,g,false,[]);
                    this.setState({data:d,columnHeads:Commons.getMemberListLabels(),type:t,groupBy:g},()=>{
                        console.log("pcl: ",this.state.columnHeads,d);
                    });
                    break;
                case "ajira_wanachama":
                    let w_ajira = this.storedData.members;//.filter(m=>m.kazi != "hakuna" && m.kazi != null && m.kazi != undefined)
                    d = Commons.groupBy(w_ajira,g,false,[]);
                    this.setState({data:d,columnHeads:Commons.getMemberListLabels(),type:t,groupBy:g},()=>{
                        // console.log("cl: ",this.state.columnHeads,d);
                    });
                    break;
                case "cbt":
                    let cbt = this.storedData.groups;
                    d = Commons.groupBy(cbt,g,false,[]);
                    this.setState({data:d,columnHeads:Commons.getGroupListLabels(),type:t,groupBy:g},()=>{
                        // console.log("cl: ",this.state.columnHeads,d);
                    });
                    break;
                case "akaunti_benki":
                case "no_akaunti_benki":
                    let benkgroups = this.storedData.groups.map(g=>{
                        let gp = g;

                        gp.akaunti_benki = (g.akaunti_benki && g.akaunti_benki != "" && g.akaunti_benki !="hakuna") ? g.akaunti_benki:"Hakuna";
                        return gp;
                    })
                    if(t === "akaunti_benki") benkgroups = benkgroups.filter(g=>g.akaunti_benki.toLowerCase() != "hakuna");
                    if(t === "no_akaunti_benki") benkgroups = benkgroups.filter(g=>g.akaunti_benki.toLowerCase() == "hakuna");
                    d = Commons.groupBy(benkgroups,g,false,[]);
                    this.setState({data:d,columnHeads:Commons.getGroupListLabels(),type:t,groupBy:g},()=>{
                        console.log("cl: ",this.state.columnHeads,d);
                    });
                break;
                case "vsla":
                case "ushirika":
                    let ushirika = this.storedData.groups.map(g=>{
                        let gp = g;
                        gp.ushirika = (g.aina_ya_ushirika && g.aina_ya_ushirika != "" && g.aina_ya_ushirika !="hakuna") ? g.aina_ya_ushirika:"Haijulikani";
                        return gp;
                    }).filter(g=>g.ushirika.toLowerCase() == t);
                    d = Commons.groupBy(ushirika,g,false,[]);
                    this.setState({data:d,columnHeads:Commons.getGroupListLabels(),type:t,groupBy:g},()=>{
                        console.log("cl: ",this.state.columnHeads,d);
                    });
                    break;
                
                case "-1":
                case null:
                    default:
                    this.setState({data:[],type:null,groupBy:g})
                    break;
            }
        }
    }
    handleReportType = (e)=>{
        var gb = document.getElementById("report-groupby");
        gb.value = "-1";    
        gb.dispatchEvent(new Event("change"));
        let tar = e.target;
        let val = tar.value;
        let title = val;
        if(val === "no_akaunti_benki") title = "Vikundi Visivyo na Akaunti";
        if(val === "akaunti_benki") title = "Vikundi Vyenye Akaunti ya Benki";
        if(val === "ushirika"|| val==="vsla") title = "Vikundi vya "+val;
        
        this.setState({groupBy:"-1",type:val,title:title},()=>{
            if(tar.id == "report-mikopo"){
                document.getElementById("report-wanachama").value = "-1";
               document.getElementById("report-vikundi").value = "-1";
           }
           if(tar.id == "report-wanachama"){
               document.getElementById("report-mikopo").value = "-1";
               document.getElementById("report-vikundi").value = "-1";
           }
           if(tar.id == "report-vikundi"){
               document.getElementById("report-wanachama").value = "-1";
               document.getElementById("report-mikopo").value = "-1";
           }
           if(val == "mikopo_vikundi" || tar.id == "report-vikundi"){
               Array.from(gb.options).forEach((o,i)=>{
                   if(o.value == "jinsia") gb.removeChild(o);
               });
               
           }
           else{
               if(!Array.from(gb.options).map((o,i)=>o.value).includes("jinsia")){
                   var opt = document.createElement("option");
                   opt.value = "jinsia";
                   opt.textContent = "Jinsia";
                   gb.appendChild(opt);
               }
           }
           this.prepareData(val,"-1");
           this.setMuundo("list");
       })
     
   }
   handleGroupBy=(e)=>{
       let tar = e.target;
       let val = tar.value;
       this.groupBy(val);
   }
   handleMuundo = (e)=>{
       let tar = e.target;
       let val = tar.value;
       this.setMuundo(val);
   }
   setMuundo=(muundo)=>{
       this.setState({muundo:muundo},()=>{
           document.getElementById("report-muundo").value = muundo;
       })
   }
  
   handleAuth=(u)=>{
       if(u){
           this.loginState = u;
           this.setState({isAuthenticated:true},()=>{
               this.loadData()
           });
       }
   }
 
   render(){
       if(this.state.isAuthenticated){
           return(
                   <div>
                  <Header auth={this.loginState}/>
                   <div className="container-fluid">
                       <div className="row">
                           
                      <SideBar selected="ripoti" auth={this.loginState} />
                           <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4 mx-auto my-4">
                           
                               <div className="col-lg-12 col-md-12 my-4 d-flex flex-row justify-content-between align-items-center">
                               <h3>Ripoti</h3>
                               {this.state.data.length>0 ?<span className="material-icons btn text-primary" onClick={()=>window.print()}>print</span>:null}
                               </div>
                               
                               <div className="bg-light border p-2">
                                   <form className="row p-2">
                                   <div className="form-group col-lg-12 col-md-12 d-flex justify-content-start ">
                                           <label className="py-2">Taarifa za Ripoti</label>
                                       </div>
                                       <div className="form-group col-lg-2 col-md-2 d-flex flex-column align-items-start justify-content-start">
                                           <label htmlFor="report-mikopo">Mikopo</label>
                                           <select className="form-control" id="report-mikopo" name="report-mikopo" onChange={this.handleReportType}>
                                               <option value="-1">--chagua--</option>
                                               <option value="mikopo_wanachama">Mikopo ya Wanachama</option>
                                               <option value="mikopo_vikundi">Mikopo ya Vikundi</option> 
                                               <option value="hisa_mikopo">Hisa na Mikopo</option>    
                                           </select>
                                       </div>
                                       <div className="form-group col-lg-3 col-md-3 d-flex flex-column align-items-start justify-content-start">
                                           <label htmlFor="report-wanachama">Wanachama</label>
                                           <select className="form-control" id="report-wanachama" name="report-wanachama" onChange={this.handleReportType}>
                                               <option value="-1">--chagua--</option>
                                               <option value="nafasi_wanachama">Viongozi</option>
                                               <option value="ajira_wanachama">Ajira</option>
                                               
                                           </select>
                                       </div>
                                       <div className="form-group col-lg-3 col-md-3 d-flex flex-column align-items-start justify-content-start">
                                           <label htmlFor="report-vikundi">Vikundi</label>
                                           <select className="form-control" id="report-vikundi" name="report-vikundi" onChange={this.handleReportType}>
                                               <option value="-1">--chagua--</option>
                                               <option value="cbt">CBT</option>
                                               <option value="vsla">VSLA</option>
                                               <option value="ushirika">Ushirika</option>
                                               <option value="akaunti_benki">Akaunti Benki</option>
                                               <option value="no_akaunti_benki">Havina Akaunti Benki</option>
                                           
                                           </select>
                                       </div>  <div className="form-group col-lg-2 col-md-2 d-flex flex-column align-items-start justify-content-start">
                                           <label htmlFor="report-groupby">Kusanya Kwa</label>
                                           <select className="form-control" id="report-groupby" name="report-groupby" onChange={this.handleGroupBy} defaultValue={this.state.groupBy}>
                                               <option value="-1">--chagua--</option>
                                               <option value="kanda">Kanda</option>
                                               <option value="mkoa">Mkoa</option>
                                               <option value="wilaya">Wilaya</option>
                                               <option value="jinsia">Jinsia</option>
                                           
                                           </select>
                                       </div>
                                       <div className="form-group col-lg-2 col-md-2 d-flex flex-column align-items-start justify-content-start">
                                           <label htmlFor="report-muundo">Muundo</label>
                                           <select className="form-control" id="report-muundo" name="report-muundo" onChange={this.handleMuundo}>
                                               <option value="list">Orodha</option>
                                               <option value="line">Chati ya Mstari</option>
                                               <option value="bar">Chati ya Baa</option>
                                           
                                           </select>
                                       </div>
                               </form>
                               
                           </div>
                           <PrintProvider>
                           <Print name="doc" single force={true}>
                           <div className="bg-light border p-2 my-2 section-to-print">
                               
                               {this.state.title == "" ? null:<h2 className="py-2">{(this.state.muundo == "list" ? "ORODHA":"CHATI")+ " YA "+this.state.title.replaceAll("_"," YA ").toUpperCase() + (this.state.groupBy =="-1" ?"":" KWA "+this.state.groupBy.toUpperCase())}</h2>}
                               <Print name="signature" printOnly><span className="print-signature">Printed by {this.loginState.jina}</span>
                                   </Print>
                                   {
                                   this.state.groupBy == null || this.state.groupBy == -1 ? (
                                       
                                           <>
                                               <ListHeadRow data={this.state.columnHeads} />
                                               {this.state.type == "mikopo_wanachama" ? (
                                                   this.state.data.map((loan,i)=>{
                                                       let ln = {};
                                                       this.state.columnHeads.forEach((l)=>{
                                                           ln[l.key] = loan[l.key];
                                                       })
                                                       return ln;
                                                   }).map((loan,i)=>{
                                                       return <LoanListItem loan={loan} key={Commons.randomKey()} position={i} labels={this.state.columnHeads}/>
                                                   })
                                           
                                               ):null}
                                               {this.state.type == "mikopo_vikundi" ? (
                                                   this.state.data.map((loan,i)=>{
                                                       let ln = {};
                                                       this.state.columnHeads.forEach((l)=>{
                                                           ln[l.key] = loan[l.key];
                                                       })
                                                       return ln;
                                                   }).map((loan,i)=>{
                                                       return <LoanListItem loan={loan} key={Commons.randomKey()} position={i} labels={this.state.columnHeads}/>
                                                   })
                                           
                                               ):null}
                                               {this.state.type == "hisa_mikopo" ? (
                                                   this.state.data.map((d,i)=>{
                                                       return <LoanSummaryItem key={Commons.randomKey()} loan={d} position={i} isGrouped={this.state.groupBy != -1} group={this.state.groupBy} />
                                                       })
                                               ) :null}
                                               {this.state.type == "nafasi_wanachama" || this.state.type == "ajira_wanachama" || this.state.type == "member_no_occupation"? (
                                                   this.state.data.map((d,i)=>{
                                                       return <MemberListItem key={Commons.randomKey()} id={d.member_id} position={i+1} onClick={()=>{}} data={d}/>
                                                       })
                                               ) :null}
                                               {this.state.type == "cbt" || this.state.type == "ushirika" || this.state.type == "vsla" || this.state.type == "akaunti_benki"|| this.state.type == "no_akaunti_benki" ? (
                                                   this.state.data.map((d,i)=>{
                                                       return <GroupListItem key={Commons.randomKey()} id={d.group_id} position={i+1} onClick={()=>{}} data={d}/>
                                                       })
                                               ) :null}
                                           </>):(
                                           <>
                                           
                                       {this.state.muundo == "list" ?
                                           <LoanSummaryGrouped type={this.state.type} data={this.state.data} group={this.state.groupBy} labels={this.state.columnHeads}/>:
                                           <MyChart data={this.state.data} type={this.state.type} muundo={this.state.muundo} groupBy={this.state.groupBy} />}
                                       </> 
                                   )
                                   }
                           </div>
                           </Print></PrintProvider>
                           </main>
                       </div>
                   </div>
                   </div>
               )
       }
       else return (<LoginForm target="/ripoti" onAuth={(user=>this.handleAuth(user))} />);
   
 }
   
   
}
export default Report;
