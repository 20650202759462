import React, { useEffect, useState } from "react";
import postcodes from 'postcodes-tz';
import Commons from "../utils/commons";
import Spinner from "./spinner";

class MemberForm extends React.Component{
    constructor(props){
        super(props);
        this.state={
            validPhone:false,zone:0,group:{},hasError:false,errorMsg:"",isLoading:false,mikoa:[],wilaya:[]
        }
        this.storage = window.localStorage;
        this.storedData = JSON.parse(this.storage.getItem("data"));
        this.loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
        this.groups = this.props.groups;
        this.members= this.storedData.members;
        this.regionData = Commons.getRegionalData();
        this.handleCancelForm = this.handleCancelForm.bind(this);
        this.updateMembers=this.updateMembers.bind(this);
        this.handleSubmit =this.handleSubmit.bind(this);
        this.populateMkoa = this.populateMkoa.bind(this);
        this.populateWilaya = this.populateWilaya.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
        this.saveMember = this.saveMember.bind(this);
        this.handleHisaChange = this.handleHisaChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
    }
    saveMember = (member)=>{
        return new Promise((resolve,reject)=>{
            let url = Commons.getURL("members")+"/create.php";
            let body = {data:member,auth:this.loginState};
            let options = {
                method:"POST",body:JSON.stringify(body),
                headers:{'Content-type':'application/json'}
            }
            fetch(url,options)
            .then(res=>res.json())
            .then(result=>{
                resolve(result);
            })
            .catch(e=>{
                console.log("error: ",e);
                reject("Pole! Kuna tatizo limetokea");
            })
        })
    }
    handleCancelForm = (target)=>{
        this.props.onCancel(target);
    }
   updateMembers=(members)=>{
        this.props.onSave(members);
        // this.handleCancelForm();
    }
    handleSubmit = (e)=>{
        e.preventDefault();
        var inputs = Array.from(e.target.elements);
        let kikundi = inputs[0].options[inputs[0].options.selectedIndex].value;
        var formData = {
            group_id:kikundi,
            nafasi:inputs[1].value,
            jina:inputs[2].value,
            mwaka:inputs[3].value,
            jinsia:inputs[4].value,
            mkoa:inputs[5].value,
            wilaya:inputs[6].value,
            kata:inputs[7].value,
            mtaa:inputs[8].value,
            simu:inputs[9].value,
            kazi:inputs[10].value,
            idadi_ya_mikopo:inputs[11].value,
            mkopo:inputs[12].value,
            marejesho:inputs[13].value,
            hisa:inputs[14].value,
            thamani_ya_hisa:inputs[15].value,
            kanda:this.state.group.kanda
        }
        this.storedData = JSON.parse(this.storage.getItem("data"));
        this.setState({isLoading:true},()=>{
            this.saveMember(formData).then(result=>{
                this.setState({isLoading:false})
                if(result.status == false){
                    this.setState({hasError:true,errorMsg:result.message})
                }
                else{
                    this.setState({hasError:false,errorMsg:result.message})
                    this.storedData.members = parseInt(this.loginState.ngazi) === Commons.NGAZI_ADMIN ? result.members : result.members.filter(m=>m.wilaya.toLowerCase() == this.loginState.wilaya.toLowerCase());
                    this.storage.setItem("data",JSON.stringify(this.storedData));
                    this.storedData = JSON.parse(this.storage.getItem("data"));
                    this.updateMembers(this.storedData);
                }
            })
            .catch(e=>{
                this.setState({hasError:true,errorMsg:e,isLoading:false})
            }).finally(()=>{
                e.target.reset();
            })
        })
       
        
    }
    populateWilayaFromGroup = (group)=>{
    //     const distEl = document.getElementById("member-district");
    //    Array.from(distEl.children).forEach((c,i)=>{if(i>0)distEl.removeChild(c);});
       let region = group.mkoa;
        // region = (region.includes(" ")) ? region.toLowerCase().replaceAll(" ","_"):region.toLowerCase();
        // console.log("region: "+region,this.regionData[this.state.zone].regions);//.filter(r=>r.key == region));
        let districts = [];
        // let regs = this.regionData[this.state.zone].regions.filter(r=>r.key == region);
        // if(regs.length > 0) districts = regs[0].districts;
        // districts.forEach(d=>{
        //     distEl.options.add(new Option(d.name,d.name.toLowerCase()));
        // })
        // distEl.value = group.wilaya.toLowerCase();
       
        let regs = this.regionData[parseInt(group.kanda)].regions.filter(r=>{
            return r.key == region.toLowerCase().replaceAll(" ","_");
        });
        console.log("regs: "+region,regs);
        if(regs.length > 0) districts = regs[0].districts;
        this.setState({wilaya:districts});
       
    }
    populateWilaya=(region)=>{
    // const distEl = document.getElementById("member-district");
    //    Array.from(distEl.children).forEach((c,i)=>{if(i>0)distEl.removeChild(c);});
    //    if(region != 0){console.log("region: "+region,this.regionData[this.state.zone].regions)
    //     region = (region.includes(" ")) ? region.toLowerCase().replaceAll(" ","_"):region.toLowerCase();
        // console.log("region: "+region,this.regionData[this.state.zone].regions);//.filter(r=>r.key == region));
        let districts = [];
        let regs = this.regionData[this.state.zone].regions.find(r=>r.key == region);
    //     if(regs.length > 0) districts = regs[0].districts;
    //     districts.forEach(d=>{
    //         distEl.options.add(new Option(d.name,d.name.toLowerCase()));
    //     })
    //     distEl.value = districts.length > 0 ? districts[0].name.toLowerCase():"hakuna";
    //    }
    districts = regs.districts;
    this.setState({wilaya:districts});
    }
       
    populateMkoa = (group)=>{
    //     const distEl = document.getElementById("member-region");
    //    Array.from(distEl.children).forEach(c=>distEl.removeChild(c));
       if(group.mkoa != 0){
        
        let mikoa = this.regionData[parseInt(group.kanda)].regions;
        this.setState({mikoa:mikoa});
       }
      
    }
    handleGroupChange = (e)=>{
        // e.preventDefault();
        let val = e.target.value;
        if(val==-1){
            this.handleCancelForm("group_form");
        }
        else{
            let group = this.groups.find(g=>g.group_id == parseInt(val));
            console.log("gG: ",group);
            this.setState({group:group},()=>{
                this.populateMkoa(this.state.group);
                this.populateWilayaFromGroup(this.state.group);
                document.getElementById("member-ward").value = this.state.group.kata;
                document.getElementById("member-street").value = this.state.group.mtaa;
                
                document.getElementById("member-hisa-value").value = this.state.group.thamani_ya_hisa ? this.state.group.thamani_ya_hisa:0;
            })
           
        }
        
    }
    handleRegionChange=(e)=>{
        let region = e.target.value.toLowerCase().replaceAll(" ","_");
        // const distEl = document.getElementById("member-district");
        // Array.from(distEl.children).forEach(c=>distEl.removeChild(c));
        if(region != 0) this.populateWilaya(region);
        
    };
    componentDidMount(){
        this.setState({zone:parseInt(this.loginState.kanda)});
    }
    handleHisaChange=(e)=>{
        let val = e.target.value.length > 0 ? parseInt(e.target.value):0;
        let ht = (this.state.group.thamani_ya_hisa ? this.state.group.thamani_ya_hisa:0) * val;
        document.getElementById("member-hisa-value").value = ht;
    }
    handlePhoneChange = (e)=>{
        let phone = e.target.value;
        if(phone.length > 0){
            let valid = Commons.validatePhone(phone);
            this.setState({validPhone:valid});
        }
        
    }
    render(){
        return(
            <form onSubmit={this.handleSubmit} className="border border-secondary bg-light p-3 my-5 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
                <div className="text-medium py-2">Usajili wa Mwanachama</div>
                {this.state.errorMsg.length >0 ? <div className={"p-2 "+(this.state.hasError ? "alert-danger":"alert-success")}>{this.state.errorMsg}</div>:null}
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-name">Chagua Kikundi</label>
                    <select className="form-control" id="group-name" name="group-name" onChange={this.handleGroupChange}>
                        <option value="0">--chagua kikundi--</option>
                        {this.groups.length > 0 ?(
                            this.groups.filter(g=>g.jina !== null && g.jina !==undefined && g.jina !=="").map(g=>{
                                return (<option value={g.group_id} key={g.group_id}>{g.jina}</option>)
                            })
                        ):(null)}
                        
                    </select>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="member-position">Nafasi</label>
                    <select className="form-control" id="member-position" name="member-position">
                        <option>Mjumbe</option>
                        <option>Mwekahazina</option>
                        <option>Katibu</option>
                        <option>Mwenyekiti</option>
                    </select>
                
                </div>
                <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="member-name">Majina Kamili</label>
                    <input required={true} className="form-control" type="text" id="member-name" name="member-name" placeholder="Majina ya mwanachama"/>
                </div>
                <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="member-dob">Mwaka wa Kuzaliwa</label>
                    <select required={true} className="form-control" id="member-dob" name="member-dob">
                        <option value="0">--chagua mwaka--</option>
                        {Commons.generateYears(1930,2005).map(y=>{
                            return <option key={y}>{y}</option>
                        })}
                    </select>
                </div>
                <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="member-gender">Jinsi</label>
                    <select required={true} className="form-control" id="member-gender" name="member-gender">
                        <option value="0">Ke</option>
                        <option value="1">Me</option>
                    </select>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-region">Mkoa Anapoishi</label>
                    <select disabled={true} className="form-control" id="member-region" name="member-region" value={this.state.group.mkoa ? this.state.group.mkoa.toLowerCase().replaceAll(" ","_"):-1} onChange={this.handleRegionChange}>
                    <option value={-1}>--chagua mkoa--</option>
                        {this.state.mikoa.map(m=>{
                            return (<option key={m.key} value={m.key}>{m.name}</option>)
                        })}
                        
                    </select>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-district">Wilaya</label> 
                    <select disabled={true} required={true} value={this.state.group.wilaya ? this.state.group.wilaya.toLowerCase().replaceAll(" ","_"):-1} className="form-control" id="member-district" name="member-district">
                    <option value={-1}>--chagua wilaya--</option>
                    {this.state.wilaya.map(m=>{
                            return (<option key={m.name.toLowerCase()} value={m.name.toLowerCase().replaceAll(" ","_")}>{m.name}</option>)
                        })}
                    </select>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-ward">Kata/Wadi</label>
                    <input required={true} className="form-control" type="text" id="member-ward" name="member-ward" placeholder="Kata"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-street">Mtaa/Kijiji</label>
                    <input required={true} className="form-control" type="text" id="member-street" name="member-street" placeholder="Mtaa au Kijiji"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-phone">Namba ya Simu</label>
                    <input onChange={this.handlePhoneChange} className={"form-control "+(this.state.validPhone ?"border border-success":"border border-danger" )} type="number" id="member-phone" name="member-phone" placeholder="Na. ya simu ya mwanachama"/>
                </div>
                
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-occupation">Kazi/Shughuli ya Msingi</label>
                    <input type="text" className="form-control" id="member-occupation" name="member-occupation" placeholder="Kazi ya msingi"/>
                      
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-loan-count">Idadi ya Mikopo</label>
                    <input type="number" className="form-control" id="member-loan-count" name="member-loan-count" placeholder="Idadi ya mikopo aliyowahi kuchukua"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-loan">Mkopo</label>
                    <input type="number" className="form-control" id="member-loan" name="member-loan" placeholder="Kiasi cha mkopo"/>
                      
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-loan-rejesho">Marejesho</label>
                    <input type="number" className="form-control" id="member-loan-rejesho" name="member-loan-rejesho" placeholder="Jumla ya marejesho ya mikopo"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-hisa">Idadi ya Hisa</label>
                    <input onChange={this.handleHisaChange} type="number" className="form-control" id="member-hisa" name="member-hisa" placeholder="Idadi ya hisa"/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-hisa-value">Thamani ya Hisa</label>
                    <input type="number" className="form-control" id="member-hisa-value" name="member-hisa-value" placeholder="Thamani ya hisa zote"/>
                </div>     
                <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row">
                    {this.state.isLoading ? <span className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" ><Spinner size="spinner" isShowing={this.state.isLoading}/></span>:<input className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" type="submit" value="HIFADHI"/>}
                    <input onClick={()=>this.handleCancelForm("groups")} className="text-primary col-md-3 col-lg-2 col-sm-6 btn" type="button" value="GHAIRI"/>
                </div>
            </form>
                   
        )
    }
    
}
export default MemberForm;