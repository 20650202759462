import React from "react";
import { Line ,Bar,Pie, Doughnut} from "react-chartjs-2";
import Commons from "../utils/commons";
class MyChart extends React.Component{

    constructor(props){
        super(props);

        this.state = {my_data:null};
        this.prepareData = this.prepareData.bind(this);
        this.makeBarLineData = this.makeBarLineData.bind(this);
        this.makePieData = this.makePieData.bind(this);
    }
    
    prepareData=()=>{
        if(this.props.muundo == 'bar' || this.props.muundo == "line") this.makeBarLineData(this.props.groupBy,this.props.type);
        if(this.props.muundo == "pie") this.makePieData(this.props.groupBy,this.props.type);
       
    }
    makeBarLineData=(group,type)=>{
        var originalLabels = Object.keys(this.props.data[0]).filter(k=>k != "key" && k != "val" && k != "hisa_thamani");
        var keys = this.props.data.map(d=>{
            let key = d.key;
            if(key == 0) key = group == "jinsia" ? "Wanawake" :"Bara";
            if(key == 1) key = group == "jinsia" ? "Wanaume": "Zanzibar";
            return key;
        });
        var finalData = {};
        let labels = originalLabels;
        var datasets = [];
        var myLabels;
        switch(type){
            case "mikopo_wanachama":
                myLabels = Commons.getLoanListLabels(0).filter(l=>originalLabels.includes(l.key));
                this.props.data.forEach((d,i)=>{
                    let data = [];
                    let colors = [];
                    let dataset = {};
                    data = myLabels.map(l=>d[l.key]);
                    labels = myLabels.map(l=>l.label);
                    colors.push("#"+Math.floor(Math.random()*16777215).toString(16));
                    dataset.label = keys[i];
                    dataset.data = data;
                    dataset.backgroundColor = colors;
                    dataset.borderColor = colors;
                    datasets.push(dataset);
                   
                });
                break;
                case "mikopo_vikundi":
                    myLabels = Commons.getLoanListLabels(1).filter(l=>originalLabels.includes(l.key));
                    this.props.data.forEach((d,i)=>{
                        let data = [];
                        let colors = [];
                        let dataset = {};
                        data = myLabels.map(l=>d[l.key]);
                        labels = myLabels.map(l=>l.label);
                        colors.push("#"+Math.floor(Math.random()*16777215).toString(16));
                        dataset.label = keys[i];
                        dataset.data = data;
                        dataset.backgroundColor = colors;
                        dataset.borderColor = colors;
                        datasets.push(dataset);
                       
                    });
                    break;
            case "hisa_mikopo":
                let mLabels = Commons.getHisaMikopoLabels().filter(l=>originalLabels.includes(l.key));
                this.props.data.forEach((d,i)=>{
                    let data = [];
                    let colors = [];
                    let dataset = {};
                    data = mLabels.map(l=>d[l.key]);
                    labels = mLabels.map(l=>l.label);
                    colors.push("#"+Math.floor(Math.random()*16777215).toString(16));
                    dataset.label = keys[i];
                    dataset.data = data;
                    dataset.backgroundColor = colors;
                    dataset.borderColor = colors;
                    datasets.push(dataset);
                   
                });
              
                break;
            case "nafasi_wanachama":
            case "ajira_wanachama":
                let sumMK = 0;
                let sumKT = 0;
                let sumMH = 0;
                let ajiraSum = 0;
                let noAjiraSum = 0;
                let xLabels = (type == "nafasi_wanachama") ? ["Wenyeviti","Makatibu","Wahazini"]:["Wenye Ajira","Wasio na Ajira"];
                let data = [];
                this.props.data.forEach((d,i)=>{
                    sumMK = d.val.filter(v=>v.nafasi.toLowerCase() == "m/kiti" || v.nafasi.toLowerCase() == "mwenyekiti").length;
                    sumKT = d.val.filter(v=>v.nafasi.toLowerCase() == "katibu").length;
                    sumMH = d.val.filter(v=>v.nafasi.toLowerCase() == "muhazini").length;
                    ajiraSum = d.val.filter(v=>v.kazi != "hakuna" && v.kazi != null && v.kazi != undefined).length;
                    noAjiraSum = d.val.filter(v=>v.kazi == "hakuna" || v.kazi == null || v.kazi == undefined).length;
                    let colors = [];
                    let dataset = {};
                    data = type=="nafasi_wanachama" ? [sumMK,sumKT,sumMH]:[ajiraSum,noAjiraSum];
                    labels =  xLabels;
                    colors.push("#"+Math.floor(Math.random()*16777215).toString(16));
                    dataset.label = keys[i];
                    dataset.data = data;
                    dataset.backgroundColor = colors;
                    dataset.borderColor = colors;
                    datasets.push(dataset);
                });
                
                break;
            case "cbt":
                let cbtSum = 0;
                let noCbtSum = 0;
                let zLabels = ["Vyenye Walimu","Visivyo na Walimu"];
                let dt = [];
                this.props.data.forEach((d,i)=>{
                   
                    cbtSum = d.val.filter(v=>v.cbt_mwalimu != "hakuna" && v.cbt_mwalimu != null && v.cbt_mwalimu != undefined).length;
                    noCbtSum = d.val.filter(v=>v.cbt_mwalimu == "hakuna" || v.cbt_mwalimu == null || v.cbt_mwalimu == undefined).length;
                    let colorSet = [];
                    let dSet = {};
                    dt = [cbtSum,noCbtSum];
                    labels =  zLabels;
                    colorSet.push("#"+Math.floor(Math.random()*16777215).toString(16));
                    dSet.label = keys[i];
                    dSet.data = dt;
                    dSet.backgroundColor = colorSet;
                    dSet.borderColor = colorSet;
                    datasets.push(dSet);
                });
                    
                break;
                case "akaunti_benki":
                case "no_akaunti_benki":
                let accSum = 0;
                let noAccSum = 0;
                let accLabels = (type == "akaunti_benki" ? ["Vyenye Akaunti","Visivyo na Akaunti"]:["Visivyo na Akaunti","Vyenye Akaunti"]);
                let dta = [];
                this.props.data.forEach((d,i)=>{
                   
                    accSum = d.val.filter(v=>v.akaunti_benki != "hakuna" && v.akaunti_benki != null && v.akaunti_benki != undefined).length;
                    noAccSum = d.val.filter(v=>v.akaunti_benki == "hakuna" || v.akaunti_benki == null || v.akaunti_benki == undefined).length;
                    let colorSet = [];
                    let dSet = {};
                    dta = [accSum,noAccSum];
                    labels =  accLabels;
                    colorSet.push("#"+Math.floor(Math.random()*16777215).toString(16));
                    dSet.label = keys[i];
                    dSet.data = dta;
                    dSet.backgroundColor = colorSet;
                    dSet.borderColor = colorSet;
                    datasets.push(dSet);
                });
                    
                break;
                case "vsla":
                case "ushirika":
                let vslaSum = 0;
                let ushirikaSum = 0;
                let vslaLabels = [type.toUpperCase()];
                let vdata = [];
                this.props.data.forEach((d,i)=>{
                   
                    vslaSum = d.val.filter(v=>v.aina_ya_ushirika && v.aina_ya_ushirika.toLowerCase() == "vsla").length;
                    ushirikaSum = d.val.filter(v=>v.aina_ya_ushirika && v.aina_ya_ushirika.toLowerCase() == "ushirika").length;
                    let colorSet = [];
                    let dSet = {};
                    vdata = [type == "vsla" ? vslaSum:ushirikaSum];
                    labels = vslaLabels;
                    colorSet.push("#"+Math.floor(Math.random()*16777215).toString(16));
                    dSet.label = keys[i];
                    dSet.data = vdata;
                    dSet.backgroundColor = colorSet;
                    dSet.borderColor = colorSet;
                    datasets.push(dSet);
                });
                    
                break;
                
                default:
                    break;

        }
      
        finalData.labels = labels;
        finalData.datasets=datasets;
        this.setState({my_data:finalData},()=>{
           
        });
        
    }
    makePieData=()=>{

    }
    componentDidMount(){
        this.prepareData();
    }
   render(){
    return(<>
        {this.props.muundo == "line" && this.state.my_data != null  ? (<Line data={this.state.my_data} options={{scales: {yAxes: [{ticks: {beginAtZero: false}}]}}}/>):null}
        {this.props.muundo == "pie" && this.state.my_data != null ? (<Doughnut data={this.state.my_data} options={{responsive:true,datalabels:{display:true,anchor:'end'},maintainAspecRatio:true}}/>):null}
        {this.props.muundo == "bar" && this.state.my_data != null  ? (<Bar data={this.state.my_data} options={{scales: {yAxes: [{ticks: {beginAtZero: false}}]}}}/>):null}
        </>
    )
   }
}
export default MyChart;