import React, { useEffect, useState } from "react";
import postcodes from 'postcodes-tz';
import Commons from "../utils/commons";
import Spinner from "./spinner";

class MemberEditForm extends React.Component{
      constructor(props){
    super(props);
    this.state={
        hasError:false,errorMsg:"",isLoading:false,group:props.data.kikundi,member:props.data,mikoa:[],wilaya:[]
    }
    this.storage = window.localStorage;
    this.storedData = JSON.parse(this.storage.getItem("data"));
    this.loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    this.groups = this.props.groups;
    
    this.detail = this.props.data;
    
    this.regionData = Commons.getRegionalData();
    this.handleCancelForm = this.handleCancelForm.bind(this);
    this.updateMembers=this.updateMembers.bind(this);
    this.handleSubmit =this.handleSubmit.bind(this);
    this.populateMkoa = this.populateMkoa.bind(this);
    this.populateWilaya = this.populateWilaya.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.saveMember = this.saveMember.bind(this);
    this.handleHisaChange = this.handleHisaChange.bind(this);
}
saveMember = (member)=>{
    return new Promise((resolve,reject)=>{
        let url = Commons.getURL("members")+"/update.php";
        let body = {data:member,auth:this.loginState};
        let options = {
            method:"POST",body:JSON.stringify(body),
            headers:{'Content-type':'application/json'}
        }
        fetch(url,options)
        .then(res=>res.json())
        .then(result=>{
            resolve(result);
        })
        .catch(e=>{
            console.log("error: ",e);
            reject("Pole! Kuna tatizo limetokea");
        })
    })
}
handleCancelForm = (target)=>{
    this.props.onCancel(target);
}
updateMembers=(members)=>{
    this.props.onSave(members);
    // this.handleCancelForm();
}
handleSubmit = (e)=>{
    e.preventDefault();
    var inputs = Array.from(e.target.elements);
    let kikundi = inputs[0].options[inputs[0].options.selectedIndex].value;
    var formData = {
        member_id:this.detail.member_id,
        group_id:kikundi,
        nafasi:inputs[1].value,
        jina:inputs[2].value,
        mwaka:inputs[3].value,
        jinsia:inputs[4].value,
        mkoa:inputs[5].value,
        wilaya:inputs[6].value,
        kata:inputs[7].value,
        mtaa:inputs[8].value,
        simu:inputs[9].value,
        kazi:inputs[10].value,
        idadi_ya_mikopo:inputs[11].value,
        mkopo:inputs[12].value,
        marejesho:inputs[13].value,
        hisa:inputs[14].value,
        thamani_ya_hisa:inputs[15].value,
        kanda:this.state.group.kanda
    }
    
    this.setState({isLoading:true},()=>{
        this.saveMember(formData).then(result=>{
            console.log("result: ",result);
            this.setState({isLoading:false})
            if(result.status == false){  
                this.setState({hasError:true,errorMsg:result.message})
            }
            else{
                this.setState({hasError:false,errorMsg:result.message})
                this.groups = result.groups;
                this.updateMembers(result.members);
            }
        })
        .catch(e=>{
            this.setState({isLoading:false,hasError:true,errorMsg:e})
        })
    })
   
    
}
populateWilayaFromGroup = (group)=>{
    const distEl = document.getElementById("member-district");
   let region = group.mkoa;
    region = (region.includes(" ")) ? region.toLowerCase().replaceAll(" ","_"):region.toLowerCase();
   let districts = [];
    let regs = this.regionData[group.kanda].regions.filter(r=>r.key == region);
    if(regs.length > 0) districts = regs[0].districts;
    this.setState({wilaya:districts})
    distEl.value = group.wilaya.toLowerCase();
   
   
}
populateWilaya=(region)=>{
const distEl = document.getElementById("member-district");
   if(region != 0){console.log("region: "+region,this.regionData[this.state.zone].regions)
    region = (region.includes(" ")) ? region.toLowerCase().replaceAll(" ","_"):region.toLowerCase();
    let districts = [];
    let regs = this.regionData[this.state.zone].regions.filter(r=>r.key == region);
    if(regs.length > 0) districts = regs[0].districts;
    this.setState({wilaya:districts})
    distEl.value = districts.length > 0 ? districts[0].name.toLowerCase():"hakuna";
   }
}
   
populateMkoa = (kanda)=>{
    const distEl = document.getElementById("member-region");
   if(kanda == 1 || kanda == 0){
    let region = this.detail.mkoa.toLowerCase().replaceAll(" ","_");
    this.setState({zone:parseInt(kanda)},()=>{
        let regions = this.regionData[this.state.zone].regions;
        this.setState({mikoa:regions});
        distEl.value = region;
    })
    
   }
  
}
handleGroupChange = (e)=>{
    // e.preventDefault();
    let val = e.target.value;
    if(val=="0"){
        this.handleCancelForm("group_form");
    }
    else{
        let group = this.groups.find(g=>g.group_id == parseInt(val));
        console.log("gc: ",group);
        var mk = document.getElementById("member-region");
        this.setState({group:group},()=>{
            mk.value = group.mkoa.toLowerCase().replaceAll(" ","_");
            this.populateMkoa(group.kanda);
            this.populateWilayaFromGroup(group);
            document.getElementById("member-ward").value = this.state.group.kata;
            document.getElementById("member-street").value = this.state.group.mtaa;
            
            document.getElementById("member-hisa-value").value = this.state.group.thamani_ya_hisa ? this.state.group.thamani_ya_hisa:0;
        })
       
    }
    
}
handleRegionChange=(e)=>{
    let region = e.target.value.toLowerCase();
    
    if(region != 0) this.populateWilaya(region);
    
};
componentDidMount(){
    console.log("xx: ",this.props.data);
    if(this.props.data){
        this.detail = this.props.data;
        this.setState({member:this.detail,group:this.detail.kikundi,zone:this.detail.kanda},()=>{
            
            this.populateMkoa(this.detail.kanda);
            this.populateWilaya(this.detail.mkoa);
        });
    }
    
    
}
handleHisaChange=(e)=>{
    let val = e.target.value.length > 0 ? parseInt(e.target.value):0;
    let ht = (this.state.group.thamani_ya_hisa ? this.state.group.thamani_ya_hisa:0) * val;
    document.getElementById("member-hisa-value").value = ht;
}

render(){
    return(
        <form onSubmit={this.handleSubmit} className="border border-secondary bg-light p-3 my-5 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
            <div className="text-medium py-2">Hariri Taarifa za Mwanachama</div>
            {this.state.errorMsg.length > 0 ? <div className={"p-2 "+(this.state.hasError ? "alert-danger":"alert-success")}>{this.state.errorMsg}</div>:null}
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-name">Chagua Kikundi</label>
                <select className="form-control" id="group-name" name="group-name" onChange={this.handleGroupChange} defaultValue={this.detail.kikundi.group_id}>
                    <option value="0">--chagua kikundi</option>
                    {this.groups.length > 0 ?(
                        this.groups.filter(g=>g.jina !== null && g.jina !==undefined && g.jina !=="").map(g=>{
                            return (<option value={g.group_id} key={g.group_id}>{g.jina}</option>)
                        })
                    ):(null)}
                </select>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="member-position">Nafasi</label>
                <select className="form-control" id="member-position" name="member-position" defaultValue={this.detail.nafasi}>
                    <option>Mjumbe</option>
                    <option>Mwekahazina</option>
                    <option>Katibu</option>
                    <option>Mwenyekiti</option>
                </select>
            
            </div>
            <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="member-name">Majina Kamili</label>
                <input required={true} className="form-control" type="text" id="member-name" name="member-name" defaultValue={this.detail.jina}/>
            </div>
            <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="member-dob">Mwaka wa Kuzaliwa</label>
                <select required={true} className="form-control" id="member-dob" name="member-dob" defaultValue={this.detail.mwaka}>
                    <option value="0">--chagua mwaka--</option>
                    {Commons.generateYears(1930).map(y=>{
                        return <option key={y}>{y}</option>
                    })}
                </select>
            </div>
            <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="member-gender">Jinsi</label>
                <select required={true} className="form-control" id="member-gender" name="member-gender" defaultValue={this.detail.jinsia == "Me"?1:0}>
                    <option value="0">Ke</option>
                    <option value="1">Me</option>
                </select>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                <label htmlFor="member-region">Mkoa Anapoishi </label>
                <select disabled={true} className="form-control" id="member-region" name="member-region" onChange={this.handleRegionChange}  value={this.state.group.mkoa.toLowerCase().replaceAll(" ","_")}>
                   
                    {this.state.mikoa.map(m=>{
                        return <option key={Commons.randomKey()} value={m.key}>{m.name.toUpperCase()}</option>
                    })}
                </select>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                <label htmlFor="member-district">Wilaya</label>
                <select disabled={true} className="form-control" id="member-district" name="member-district" defaultValue={this.detail.wilaya}>
                
                {this.state.wilaya.map(w=>{
                    return <option key={Commons.randomKey()} value={w.name.toLowerCase().replaceAll(" ","_")}>{w.name.toUpperCase()}</option>
                })}
                </select>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                <label htmlFor="member-ward">Kata/Wadi</label>
                <input className="form-control" type="text" id="member-ward" name="member-ward" placeholder="Kata" defaultValue={this.detail.kata}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                <label htmlFor="member-street">Mtaa/Kijiji/Shehia</label>
                <input required={true} className="form-control" type="text" id="member-street" name="member-street" placeholder="Mtaa, Kijiji au Shehia" defaultValue={this.detail.mtaa}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                <label htmlFor="member-phone">Namba ya Simu</label>
                <input className="form-control" type="number" id="member-phone" name="member-phone" placeholder="Na. ya simu ya mwanachama" defaultValue={this.detail.simu}/>
            </div>
            
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                <label htmlFor="member-occupation">Kazi/Shughuli ya Msingi</label>
                <input type="text" className="form-control" id="member-occupation" name="member-occupation" placeholder="Kazi ya msingi" defaultValue={this.detail.kazi}/>
                  
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                <label htmlFor="member-loan-count">Idadi ya Mikopo</label>
                <input type="number" className="form-control" id="member-loan-count" name="member-loan-count" placeholder="Idadi ya mikopo aliyowahi kuchukua" defaultValue={this.detail.idadi_ya_mikopo}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                <label htmlFor="member-loan">Mkopo</label>
                <input type="number" className="form-control" id="member-loan" name="member-loan" placeholder="Kiasi cha mkopo" defaultValue={this.detail.mkopo}/>
                  
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                <label htmlFor="member-loan-rejesho">Marejesho</label>
                <input type="number" className="form-control" id="member-loan-rejesho" name="member-loan-rejesho" placeholder="Jumla ya marejesho ya mikopo" defaultValue={this.detail.marejesho}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                <label htmlFor="member-hisa">Idadi ya Hisa</label>
                <input min={0} onChange={this.handleHisaChange} type="number" className="form-control" id="member-hisa" name="member-hisa" placeholder="Idadi ya hisa" defaultValue={this.detail.hisa}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                <label htmlFor="member-hisa-value">Thamani ya Hisa</label>
                <input type="number" className="form-control" id="member-hisa-value" name="member-hisa-value" placeholder="Thamani ya hisa zote" defaultValue={this.detail.thamani_ya_hisa}/>
            </div>     
            <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row">
                {this.state.isLoading ? <span className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" ><Spinner size="spinner" isShowing={this.state.isLoading}/></span>:<input className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" type="submit" value="HIFADHI"/>}
                <input onClick={()=>this.handleCancelForm("groups")} className="text-primary col-md-3 col-lg-2 col-sm-6 btn" type="button" value="GHAIRI"/>
            </div>
        </form>
               
    )
}

}
export default MemberEditForm;