import React from "react";
import { useState } from "react/cjs/react.development";
import commons from "../utils/commons";

const UserDetail=(props)=>{
    var storage = window.localStorage;
    var user = props.user;
    var storedData = JSON.parse(storage.getItem("data"));
    const regionData = commons.getRegionalData();
    var regions = regionData[parseInt(user.kanda)].regions.filter(r=>r.key == user.mkoa);

    var wilayas = (regions.length > 0) ? regions[0].districts:[];
    const handleCancelForm=(tag)=>{
        props.onCancel(tag)
    }
   
    return(<form className="border border-secondary bg-light p-3 my-5 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
    <div className="text-medium py-2">Taarifa za Mtumiaji</div>
    
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="user-position">Ngazi ya Utawala</label>
        <select readOnly={true} disabled={true} value={parseInt(user.ngazi)} className="form-control" id="user-position" name="user-position">
           {storedData.roles.map(r=>{
               return <option key={r.role_id} value={r.level}>{r.role_name}</option>
           })}
        </select>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="user-zone">Kanda</label>
        <select readOnly={true} disabled={true} value={user.kanda} className="form-control" id="user-zone" name="user-zone" >
            <option value="0">Bara</option>
            <option value="1">Zanzibar</option>
        </select>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="user-region">Mkoa</label>
        <select readOnly={true} disabled={true} value={user.mkoa} className="form-control" id="user-region" name="user-region" >
            <option value="0">--chagua mkoa--</option>
        {regionData[parseInt(user.kanda)].regions.map(city=>{
                return(<option key={city.key} value={city.key}>{city.name}</option>)
            })}
        </select>
    </div>
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="user-district">Wilaya</label>
        <select readOnly={true} value={user.wilaya} className="form-control" id="user-district" name="user-district">
        <option value="0">--chagua wilaya--</option>
        {wilayas.map(city=>{
                return(<option key={city.name} value={city.name}>{city.name}</option>)
            })}
        </select>
    </div>
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="user-name">Majina Kamili</label>
        <input readOnly={true} value={user.jina}className="form-control" type="text" id="user-name" name="user-name" placeholder="Majina ya mtumiaji"/>
    </div>
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="user-email">Barua-pepe</label>
        <input readOnly={true} disabled={true} value={user.email} className="form-control" type="email" id="user-email" name="user-email" placeholder="Barua pepe"/>
    </div>
    
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="user-phone">Namba ya Simu</label>
        <input readOnly={true} disabled={true} value={user.simu} className="form-control" type="number" id="user-phone" name="user-phone" placeholder="Na. ya simu ya mtumiaji"/>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="user-password">Neno la siri</label>
        <input readOnly={true} disabled={true} value={user.password} className="form-control" type="password" id="user-password" name="user-password" placeholder="Neno la siri"/>
    </div>
    
    <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row">
        <input onClick={()=>handleCancelForm("user_edit")}  className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" type="button" value="HARIRI"/>
        <input onClick={()=>handleCancelForm("users")} className="text-primary col-md-3 col-lg-2 col-sm-6 btn" type="button" value="GHAIRI"/>
    </div>
</form>
)
}
export default UserDetail;