import React from "react";
import MemberImport from "./member_bulk_import";
import MemberForm from "./member_form";
import MemberListItem from "./member_list_item";
import MemberView from "./member_detail";
import ListHeadRow from "./header_row";
import Commons from "../utils/commons";
import MemberEditForm from "./member_edit_form";
import LoginForm from "./login_form";
import Header from "./header";
import SideBar from "./sidebar";

class Members extends React.Component{
    constructor(props){
        super(props);
        this.storage = window.localStorage;
        this.session = window.sessionStorage;
        this.loginState = JSON.parse(this.session.getItem("loginState"));
        this.memberLabels = Commons.getMemberListLabels();
        this.groups = [];
        this.state={
        cpage:0,pageCount:[],showMemberDetail:false,showMemberEdit:false,showMemberForm:false,showMemberImport:false,showMembersList:true,selectedMember:null,members:[],source:[]
        }
        this.loadData = this.loadData.bind(this);
        this.prepareData = this.prepareData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.updateMembers = this.updateMembers.bind(this);
        this.handleComponentVisibility = this.handleComponentVisibility.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.handleAuth = this.handleAuth.bind(this);
        this.search = this.search.bind(this);
        this.goNextPage = this.goNextPage.bind(this);

        if(window.performance){
            if(performance.navigation.type === 1){
                this.setState({pageCount:[],members:[]},()=>{
                this.loadData()
                .then(result=>{
                    this.setState({source:result.members});
                    this.prepareData(result.members);
                }).catch(e=>{
                    console.log(e);
                })
                })
            }
        }
    }
    goNextPage=(dir)=>{
        let p = parseInt(this.state.cpage);
        if(dir === 0) p--;
        else p++;
        this.setState({cpage:p},()=>{
            this.prepareData(this.state.source);
        })
    }
    handlePageChange=(e)=>{
        let p = parseInt(e.target.value);
        this.setState({cpage:p},()=>{
            this.prepareData(this.state.source);
        });
    }
    componentDidMount(){
        this.loadData().then(result=>{
            this.setState({source:result.members});
            if(this.loginState.ngazi == Commons.NGAZI_ADMIN){
                this.groups = result.groups.filter(g=>g.kanda == this.loginState.kanda);
            }
            else if(this.loginState.ngazi == Commons.NGAZI_MANAGER){
                this.groups = result.groups.filter(g=>g.mkoa.toLowerCase().replaceAll(" ","_") == this.loginState.mkoa.toLowerCase());

            }
            else if(this.loginState.ngazi == Commons.NGAZI_EVAL || this.loginState.ngazi == Commons.NGAZI_DATA){

                this.groups = result.groups.filter(g=>g.wilaya.toLowerCase() == this.loginState.wilaya.toLowerCase());
            }
            else this.groups = result.groups;
            this.prepareData(result.members);
        })
        .catch(e=>{
            console.log(e);
        })
    }
    prepareData=(source)=>{
        let members = [];
        if(source && source.length > 0){
            let sourceData =  source.map(s=>{
                if(Array.isArray(s)){
                    return s.map(m=>{
                    let nm = m;
                    var kikundi = this.groups.find(g=>parseInt(g.group_id) == parseInt(m.group_id));
                    nm.kikundi = (m.kikundi) ? m.kikundi:kikundi;
                    if(m.kanda == null || m.kanda == "") m.kanda = (kikundi.length > 0)?parseInt(kikundi[0].kanda):0;
                    return nm;
                })
                }
                else{
                    let nm = s;
                    var kikundi = this.groups.find(g=>parseInt(g.group_id) == parseInt(s.group_id));
                    nm.kikundi = (s.kikundi) ? s.kikundi:kikundi;
                    if(s.kanda == null || s.kanda == "") s.kanda = (kikundi.length > 0)?parseInt(kikundi[0].kanda):0;
                    return nm;
                }
                
            })
            
            if(this.loginState.ngazi == Commons.NGAZI_ADMIN){
                members = sourceData[this.state.cpage].filter(g=>g.kanda == this.loginState.kanda);
            
            }
            else if(this.loginState.ngazi == Commons.NGAZI_MANAGER){
                members = sourceData[this.state.cpage].filter(g=>g.mkoa && g.mkoa.toLowerCase().replaceAll(" ","_")== this.loginState.mkoa.toLowerCase().replaceAll(" ","_"));
                
            }
            else if(this.loginState.ngazi == Commons.NGAZI_SUPER) {
                // this.storedData.users = source.users;
                members = sourceData[this.state.cpage];
                // this.storedData.members = source.members;
            
            }
            else{
                members = sourceData[this.state.cpage].filter(g=>g.wilaya && g.wilaya.toLowerCase() == this.loginState.wilaya.toLowerCase());
                // this.storedData.members = source.members.filter(m=>m.wilaya && m.wilaya.toLowerCase() == this.loginState.wilaya.toLowerCase());
            
            }
            let pages = sourceData.map((g,i)=>{
                return i+1;
            })
        
            this.setState({members:members,pageCount:pages});
        }
        else{
            this.setState({members:members,pageCount:[]});
        }
        
    }
   
    updateMembers=(data)=>{
        console.log("onHifadh; ",data);
        this.setState({source:data},()=>{
            console.log("xxx: ",this.state.source);
        });
        this.prepareData(data);
        this.handleComponentVisibility("members");
    }
    handleComponentVisibility = (target=null)=>{
        switch(target){
            case null:
            case "members":
            default:
                this.setState({showMemberDetail:false,showMembersList:true,showMemberForm:false,showMemberImport:false,showMemberEdit:false});
                break;
            
            case "member_form":
                this.setState({showMemberDetail:false,showMembersList:false,showMemberForm:true,showMemberImport:false,showMemberEdit:false});
                break;
            case "member_detail":
                this.setState({showMemberDetail:true,showMembersList:false,showMemberForm:false,showMemberImport:false,showMemberEdit:false});
            break;
            
            case "member_edit":
                this.setState({showMemberDetail:false,showMembersList:false,showMemberForm:false,showMemberImport:false,showMemberEdit:true});
            break;  
            case "member_import":
                this.setState({showMemberDetail:false,showMembersList:false,showMemberForm:false,showMemberImport:true,showMemberEdit:false});
            break;   
           
        }
        
        
    }
    
    loadData=()=>{  
        return new Promise((resolve,reject)=>{
           
            if(this.loginState){
                let user_url = Commons.getURL("members")+"/index.php";
                var options={
                    method:"POST",
                    body:JSON.stringify({auth:this.loginState,page:this.state.cpage}),
                    headers:{'Content-type':'application/json'}
                }
                fetch(user_url,options)
                .then(res=>res.json())
                .then(result=>{
                    console.log("gp3: ",result);
                    let pageCount = [];
                    for(let i=1; i<=result.members.length;i++) {
                        pageCount.push(i);
                    }                    
                    resolve(result);
                })
                .catch(e=>{
                    console.log(e);
                    reject("Pole... kuna tatizo limetokea");
                })
                
            }
            
        })
    }
    
    showDetails=(data)=>{
        this.setState({selectedMember:data,showMemberDetail:true,showMembersList:false,showMemberForm:false});
    }
    handleAuth=(u)=>{
        this.session.setItem("loginState",JSON.stringify(u));
        this.loginState = u;
        this.storedData = JSON.parse(this.storage.getItem("data"));
    }
   search=(searchKey)=>{
       if(searchKey.length > 0){
        let mysource = [];
        this.state.source.forEach(s=>{
            s.forEach(g=>mysource.push(g));
        })
            var mbs = mysource.filter(m=>{
                return m.jina.toLowerCase().includes(searchKey)|| m.nafasi.toLowerCase().includes(searchKey) 
            });
            this.setState({members:mbs});
       }
       else{
        this.setState({members:this.state.source[0]});
       }
   }
   render(){
   if(this.loginState){
        return(
        <div>
        <Header onSearch={this.search} auth={this.loginState}/>
        <div className="container-fluid">
            <div className="row">
            
            <SideBar selected="wanachama" auth={this.loginState} />
                <main className="col-md-10 ms-sm-auto col-lg-10  px-md-4 mx-auto my-4">
                {this.state.showMembersList ?  
                <>
                    <div className="col-lg-12 col-md-12 my-4 d-flex flex-row justify-content-end justify-content-sm-center align-items-center row">
                        <h3 className="col-lg-6 col-md-6 col-sm-12 my-4 text-left d-flex align-items-center justify-content-md-start justify-content-lg-start justify-content-sm-center">Taarifa za Wanachama</h3>
                      
                        <div className="col-lg-6 col-md-6 my-4 d-flex flex-row justify-content-sm-center justify-content-lg-end justify-content-md-end align-items-center"> 
                            <span onClick={()=>this.handleComponentVisibility("member_form")} className="col-md-4 col-lg-4 mx-1 btn btn-secondary d-flex flex-row justify-content-center align-items-center"><span className="material-icons">add</span>&nbsp;Mwanachama</span>
                            <span onClick={()=>this.handleComponentVisibility("member_import")} className="col-md-4 col-lg-4 btn mx-1 btn-secondary d-flex flex-row justify-content-center align-items-center"><span className="material-icons">south_east</span>&nbsp;Ingiza</span>
                        </div>
                    </div>
                    <div className="text-right">
                    {this.state.pageCount.length >1 ?<><span>Ukurasa: </span>
                    {parseInt(this.state.cpage) > 0  ? <span className="material-icons btn" onClick={()=>this.goNextPage(0)}>skip_previous</span>:null}
                        <select className="col-lg-2 col-md-2" onChange={(e)=>this.handlePageChange(e)} value={this.state.cpage} >
                        {this.state.pageCount.map(p=>{
                            return <option key={Commons.randomKey()} value={p-1}>{p}</option>
                        })
                        }</select>
                        {parseInt(this.state.cpage) < this.state.pageCount.length -1 ? <span className="material-icons btn" onClick={()=>this.goNextPage(1)}>skip_next</span>:null}
                        </>:null}
                    </div>
                    <ListHeadRow data={this.memberLabels}/>
                         {this.state.members.length > 0 ?
                         this.state.members.map((d,i)=>{
                            return <MemberListItem key={Commons.randomKey()} position={i+1} id={d.member_id} onClick={()=>this.showDetails(d)} data={d}/>
                        }):<MemberListItem position={0} id={null} />
                        
                        }
                </>:
                (
                    this.state.selectedMember != null && this.state.showMemberEdit ? 
                        <MemberEditForm id={this.state.selectedMember.member_id} onCancel={(tg)=>this.handleComponentVisibility(tg)} onSave={(mbrs)=>this.updateMembers(mbrs)} data={this.state.selectedMember} groups={this.groups}/> : 
                        (this.state.showMemberImport ? 
                            <MemberImport onCancel={(target)=>this.handleComponentVisibility(target)} onHifadhi={(gps)=>this.updateMembers(gps)} groups={this.groups}/>:
                            (this.state.showMemberForm || this.state.members.length == 0 ? 
                                <MemberForm onCancel={(target)=>this.handleComponentVisibility(target)} onSave={(mbrs)=>this.updateMembers(mbrs)} groups={this.groups}/> :
                                (this.state.showMemberDetail ? 
                                    <MemberView onEditClick={(tg)=>this.handleComponentVisibility(tg)} id={this.state.selectedMember.member_id} onCancel={(target)=>this.handleComponentVisibility(target)} data={this.state.selectedMember}/> :
                                    null
                                )
                            )
                        )
                    )
                }
                 </main>
            </div>
        </div>
    </div>
    )
   }
   else return (<LoginForm target="/wanachama" onAuth={(user=>this.handleAuth(user))} />)
}
}
export default Members;