import React from "react";
import XLSX from "xlsx";
import { useState,useEffect } from "react";
import Commons from "../utils/commons";
import ListHeadRow from "./header_row";
import GroupListItem from "./group_list_item";
import commons from "../utils/commons";
import Spinner from "./spinner";

const GroupImport = (props)=>{

    var importedData=[];
    const storage = window.localStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(window.sessionStorage.getItem("loginState"));
    const [complete,setComplete] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [isLoadingFile,setIsLoadingFile] = useState(false);
    const [hasError,setHasError] = useState(false);
    const [errorMsg,setErrorMsg] = useState("");
    const [groups,setGroups] = useState([]);
    var iMembers = [];
    var iGroups = [];
    const findMkoa=(wilaya,kanda)=>{
        var regions = Commons.getRegionalData()[kanda].regions;
        let mkoa = regions.filter(r=>{
            return r.districts.map(d=>d.name.toLowerCase()).includes(wilaya.toLowerCase())
        });
        if(mkoa.length > 0) return mkoa[0].name;
        return "hakuna"

    }
    const checkExist=(group,dataset=null)=>{
        let index = -1;
        let jina = group.jina ? group.jina.toLowerCase():"";
        let wilaya = group.wilaya? group.wilaya.toLowerCase():"";
        let kata = group.kata ? group.kata.toLowerCase():"";
        let mtaa = group.mtaa ? group.mtaa.toLowerCase():"";

        let grps = (dataset != null) ? dataset: groups;
        let mg = grps.filter((g,i)=>{
            if(g.jina.toLowerCase() == jina && g.wilaya.toLowerCase() == wilaya && g.kata.toLowerCase() == kata && g.mtaa.toLowerCase() == mtaa){
                index = i;
                return true;
            }
            
        });
        return index;
    }
    const openFileDialog = ()=>{
        setIsLoadingFile(true);
        var fileInput = document.getElementById("group-import-file");
        fileInput.click();
        
        fileInput.addEventListener("change",(e)=>{
            var reader = new FileReader();
            
            reader.addEventListener("load",()=>{
                
                var wb = XLSX.read(reader.result,{type:'array'});
                let wSheet = wb.Sheets["vikundi"];

                importedData = XLSX.utils.sheet_to_json(wSheet,{header:1});;
                var columns = importedData[0].map(c=>c.toLowerCase()).map(c=>c.replaceAll(" ","_"));
                var gNames = importedData.slice(1).map(r=>r[0]).filter((n,i,arr)=>arr.indexOf(n) === i);
                importedData = gNames.map(g=>{
                        return importedData.slice(1).find(d=>d[0] == g);
                    });
                columns = columns.map(col=>{
                    let c = col;
                    if(col == "time") c = "muda_wa_mikutano";
                    if(col == "meeting") c = "siku_ya_mikutano";
                    if(col == "wanachama") c = "idadi_ya_wanachama";
                    if(col == "cbt_mwalimu") c = "mwalimu_cbt";
                    if(col == "cbt_simu") c = "simu_cbt";
                    if(col == "cbt_jinsia") c = "jinsia_cbt";
                    if(col == "cbt_anuani") c = "anuani_cbt";
                    if(col.includes("kata") || col.includes("wadi")) c = "kata";
                    if(col == "mtaa" || col =="kijiji" || col == "shehia") c = "mtaa"
                    return c;
                })
                importedData = importedData.map((d,k)=>{
                    let id = k == 0 ? groups.length :groups.length +k;
                    var data={group_id:id};
                    if(!columns.includes("mkoa")) columns.push("mkoa");
                    var val;
                    columns.forEach((c,i)=>{
                        if(c == "kanda") val = (d[i] && d[i].toLowerCase() == "zanzibar") ? 1 : 0;
                        else if(c == "mwenyekiti") {
                            let mb =iMembers.filter(m=>d[i] && m.jina.toLowerCase() == d[i].toLowerCase() && (m.nafasi.toLowerCase() == "m/kiti" || m.nafasi.toLowerCase() == "mwenyekiti"));
                            val=mb.length > 0 ? mb[0]: d[i];
                        }
                        else if(c == "katibu"){
                            let mb= iMembers.filter(m=>d[i] && m.jina.toLowerCase() == d[i].toLowerCase() && m.nafasi.toLowerCase() == "katibu");
                            val=mb.length > 0 ? mb[0]:d[i];
                        }
                        else if(c == "mkoa"){
                            if(d[i] == null || d[i] == undefined){
                                let k = (d[columns.indexOf('kanda')].toLowerCase() == "zanzibar") ? 1:0;
                                val = findMkoa(d[columns.indexOf('wilaya')],k);
                            }
                            else val = d[i];
                        }
                        else if(c == "idadi_ya_wanachama"){
                            val = (d[i]) ? d[i]:0;
                        }
                        else val = (d[i]) ? d[i]:"hakuna";
                        data[c] = val;
                    });
                   
                    return data;
                });
               
                iGroups = importedData.filter(g=> g.jina != null && g.jina !=undefined && g.jina !="").map(g=>{
                    
                    g.is_duplicate = checkExist(g) === 1;
                    return g;
                });
                setGroups(iGroups);
                setComplete(true);
                setIsLoadingFile(false);
                const hifadhBut = document.getElementById("btn-hifadhi");
                hifadhBut.classList.remove("hidden");
                hifadhBut.addEventListener("click",(e)=>{
                    setIsLoading(true);
                    hifadhi()
                    .then(result=>{
                        setIsLoading(false);
                        if(result.status){
                            setGroups(result.groups);
                            iGroups = [];
                            // storage.setItem("data",JSON.stringify(storedData));
                            props.onHifadhi(result.groups);
                            props.onCancel("groups");
                        }
                        else{
                            setHasError(true);
                            setErrorMsg(result.message);
                        }
                    })
                    .catch(e=>{
                        setIsLoading(false);
                        setHasError(true);
                        setErrorMsg(e);
                    })
                });
                const sitishaBut = document.getElementById("btn-sitisha");
                sitishaBut.classList.remove("hidden");
                sitishaBut.addEventListener("click",(e)=>{
                    iGroups = [];
                    props.onCancel("groups");
                    setComplete(false);
                })
            },false);
            
            reader.readAsArrayBuffer(fileInput.files[0])
        })
    }
  
    const hifadhi=()=>{
        return new Promise((resolve,reject)=>{
            var data = iGroups;
           
            let columns = Object.keys(data[0]).filter(k=>k!="group_id");
            data = data.map(d=>{
                let nd = d;
                if(!d.is_duplicate) delete nd.group_id;
                if(typeof(d.mwenyekiti) == "object") nd.mwenyekiti = d.mwenyekiti.jina;
                if(typeof(d.katibu) == "object") nd.katibu = d.katibu.jina;
                return nd;
            })
            let body = {data:data,auth:loginState,columns:columns};
            let url = commons.getURL("groups")+"/batch.php";
            let options = {
                method:"POST",
                body:JSON.stringify(body),
                headers:{'Content-type':'application/json'}
            }
            fetch(url,options)
            .then(res=>res.json())
            .then(result=>{
                resolve(result);
            })
            .catch(e=>{
                reject("Pole... Kuna tatizo limejitokeza");
            })
        })
        
    }
    useEffect(()=>{
        setComplete(iGroups.length > 0);
    },[complete])
    return(
        <>
            <h3>Tafadhali pakia faili la Excel (.xls/.xlsx) lenye safu zifuatazo: </h3>
           
            <span className="bold">{storedData.group_fields.filter(gf=>gf != "group_id" && gf != "date_created" && gf != "date_updated" && gf !="is_deleted").map(ml=>{
                return <span key={ml}>{ml+", "}</span>
            })}</span>
           
            <div className="my-5 d-flex justify-content-between">
                <span className="btn btn-primary" onClick={openFileDialog}>{isLoadingFile ? <Spinner size="spinner" isShowing={isLoadingFile}/>: "Pakia Faili"}</span>
                <input type="file" name="group-import-file" id="group-import-file" className="hidden" />
                <div>
                    <div className="btn bg-success hidden text-white" id="btn-hifadhi">{isLoading ? <Spinner size="spinner" isShowing={isLoading}/>: "SASISHA"}</div>
                    <div className="btn bg-danger text-white mx-2 hidden" id="btn-sitisha">SITISHA</div>
                </div>
            </div>
           
            {!complete ?
            <>
            {hasError ? <div className={"my-2 "+(hasError ? "alert-danger" : "alert-success")}>{errorMsg}</div>:null}
    
            <ListHeadRow data={Commons.getGroupListLabels()}/>
            {iGroups.map((d,i)=>{
                return <GroupListItem key={Commons.randomKey()} position={i+1} id={d.group_id} imported={true} data={d}/>
            })
            }
            </>:null}
        </>
    )
}
export default GroupImport;
