import React from "react";
import { useState } from "react";
import {saveAs} from 'file-saver';
import { Modal,Button } from "react-bootstrap";

const Popup=(props)=> {
    const [show, setShow] = useState(true);
  
    const handleClose = () => setShow(false);
    const downloadApk=()=>{
        saveAs("/android/app.apk","fgms.apk");
        handleClose();
    }
    const myAction = ()=>{
      switch(props.type){
        case "download":
          downloadApk();
        break;
        case "logout":
          signout();
        break;
        case "space":
          window.localStorage.setItem("online","yes");
          handleClose();
        break;
        default:
          handleClose();
          break;
      }
    }
    const signout=(e)=>{
      // e.preventDefault();
      window.sessionStorage.clear();
      window.localStorage.clear();
      window.location.href = "/";
  }
    return (
      <>
       
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{props.msg}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              GHAIRI
            </Button>
            <Button variant="primary" onClick={myAction}>
              {props.action.toUpperCase()}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
export default Popup;
