import React, { useState } from "react";
import Commons from "../utils/commons";

const SideBar=(props)=>{
    const [selected,setSelected] = useState(props.selected);
    const activate = (e)=>{
        if(e.target){
            setSelected(e.target.id);
        }
    }

    return(
        <nav id="sidebarMenu" className="col-md-2 col-lg-2 d-md-block bg-theme sidebar collapse">
        <div className="position-sticky pt-3">
            <ul className="nav flex-column">
                <li className="nav-item">
                    <a id="mwanzo" className={"nav-link "+(selected=="mwanzo"?"active":"")} href="/mwanzo" onClick={(e)=>activate(e)}>
                        <span className="material-icons">home</span>
                        <span>Dashibodi</span>
                    </a>
                </li>
                <li className="nav-item">
                    <a id="vikundi" className={"nav-link "+(selected=="vikundi"?"active":"")} href="/vikundi" onClick={(e)=>activate(e)}>
                        <span className="material-icons">groups</span>
                        Vikundi
                    </a>
                </li>
                <li className="nav-item">
                    <a id="wanachama" className={"nav-link "+(selected=="wanachama"?"active":"")} href="/wanachama" onClick={(e)=>activate(e)}>
                        <span className="material-icons">people</span>
                        Wanachama
                    </a>
                </li>
                <li className="nav-item">
                    <a id="mikopo" className={"nav-link "+(selected=="mikopo"?"active":"")} href="/mikopo" onClick={(e)=>activate(e)}>
                        <span className="material-icons">account_balance</span>
                        <span>Mikopo</span>
                    </a>
                </li>
                
                <li className="nav-item">
                    <a id="ripoti" className={"nav-link "+(selected=="ripoti"?"active":"")} href="/ripoti" onClick={(e)=>activate(e)}>
                        <span className="material-icons">assessment</span>
                        Ripoti
                    </a>
                </li>
                {props.auth.ngazi == Commons.NGAZI_ADMIN || props.auth.ngazi == Commons.NGAZI_SUPER?
                <li className="nav-item">
                    <a id="watumiaji" className={"nav-link " +(selected=="watumiaji"?"active":"")} href="/watumiaji" onClick={(e)=>activate(e)}>
                        <span className="material-icons">manage_accounts</span>
                        Watumiaji
                    </a>
                </li>:null}
                <li className="nav-item">
                    <a id="mipangilio" className={"nav-link "+(selected=="mipangilio"?"active":"")} href="/mipangilio" onClick={(e)=>activate(e)}>
                        <span className="material-icons">settings</span>
                        Mipangilio
                    </a>
                </li>
            </ul>
        </div>
    </nav>
    )
}

export default SideBar;