import React from 'react';
import Commons from '../utils/commons';
const MemberListItem = (props)=>{
    
    var position = props.position;
    var data= props.data;
    var groups = props.groups;
    var memberLabels = Commons.getMemberListLabels();

    const handleClick =()=>{
        if(!props.imported) props.onClick(data);
    }
    return(
        <div onClick={handleClick} className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-center align-items-center text-left list-item "+((position%2==0) ? "list-item-shade":"bg-light")}>
            {data ? (
            memberLabels.map((d,i)=>{
                let align = "text-left";
                let val = typeof(data[d.key]) == "object" ? data[d.key].jina : data[d.key];
                if(data[d.key] && d.key == "wilaya" || d.key == "mkoa") val = data[d.key].toUpperCase().replaceAll("_"," ");
                if(data[d.key] && d.key == "simu"){
                    align = "text-right";
                }
                return(<div key={Commons.randomKey()} className={"col-lg-2 col-md-2 "+align }>{val}</div>)
             
            })  
            ):(
                <div className="text-center col-lg-12 col-md-12">Hakuna taarifa</div>
            )
            }
        </div>
                    
    )
}
export default MemberListItem;