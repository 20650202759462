import React from "react";
import RegisterGroupForm from "./group_reg_form";
import GroupListItem from "./group_list_item";
import ListHeadRow from "./header_row";
import Commons from "../utils/commons";
import GroupView from "./group_detail";
import GroupEditForm from "./group_edit_form";
import GroupImport from "./group_import";
import LoginForm from "./login_form";
import Header from "./header";
import SideBar from "./sidebar";
class Groups extends React.Component{
    constructor(props){
        super(props);
        this.state={
            showGroupEditForm:false,
            showGroupForm:false,
            showGroupsList:true,
            showGroupDetail:false,
            selectedGroup:null,
            groupImportForm:false,
            source:[],
            groups:[],
            pageCount:[],
            cpage:0
        }
    
        this.storage = window.localStorage;
        this.session = window.sessionStorage;
        this.storedData = JSON.parse(this.storage.getItem("data"));
        this.hasMore = JSON.parse(this.session.getItem("more"));
        this.loginState = JSON.parse(this.session.getItem("loginState"));
        this.groupLabels = Commons.getGroupListLabels();
       
        this.handleSignout = this.handleSignout.bind(this);
        this.handleComponentVisibility = this.handleComponentVisibility.bind(this);
        this.showDetails = this.showDetails.bind(this);
        this.updateGroups = this.updateGroups.bind(this);
        this.search = this.search.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.goNextPage = this.goNextPage.bind(this);
        // this.getPage = this.getPage.bind(this);
    
        if(window.performance){
            if(performance.navigation.type === 1){
                this.setState({cpage:0 ,pageCount:[],groups:[]},()=>{
                this.loadData()
                .then(result=>{
                    this.setState({source:result.groups});
                    this.prepareData(result.groups);
                }).catch(e=>{
                    console.log(e);
                })
                })
            }
        }
    }
    goNextPage=(dir)=>{
        let p = parseInt(this.state.cpage);
        if(dir === 0) p--;
        else p++;
        this.setState({cpage:p},()=>{
            this.prepareData(this.state.source);
        })
    }
    handlePageChange=(e)=>{
        let p = parseInt(e.target.value);
        this.setState({cpage:p},()=>{
            this.prepareData(this.state.source);
        });
    }
    handleAuth=(u)=>{
        this.session.setItem("loginState",JSON.stringify(u));
        this.loginState = JSON.parse(this.session.getItem("loginState"));
        this.storedData = JSON.parse(this.storage.getItem("data"));
    }
    componentDidMount=()=>{
        
            this.loadData().then(result=>{
                this.setState({source:result.groups})
                this.prepareData(result.groups);
            })
            .catch(e=>{
                console.log("error: ",e);
            })
        
    }
    prepareData=(source)=>{
        let groups = [];
        if(source && source.length > 0){
        if(this.loginState.ngazi == Commons.NGAZI_ADMIN){
            groups = source[this.state.cpage].filter(g=>g.kanda == this.loginState.kanda);
        
        }
        else if(this.loginState.ngazi == Commons.NGAZI_MANAGER){
            groups = source[this.state.cpage].filter(g=>g.mkoa && g.mkoa.toLowerCase().replaceAll(" ","_")== this.loginState.mkoa.toLowerCase().replaceAll(" ","_"));
            
        }
        else if(this.loginState.ngazi == Commons.NGAZI_SUPER) {
            // this.storedData.users = source.users;
            groups = source[this.state.cpage];
            // this.storedData.members = source.members;
        
        }
        else{
            groups = source[this.state.cpage].filter(g=>g.wilaya && g.wilaya.toLowerCase() == this.loginState.wilaya.toLowerCase());
            // this.storedData.members = source.members.filter(m=>m.wilaya && m.wilaya.toLowerCase() == this.loginState.wilaya.toLowerCase());
        
        }
        let pages = source.map((g,i)=>{
            return i+1;
        })
        this.setState({groups:groups,pageCount:pages});
    }
    else{
        this.setState({groups:groups,pageCount:[]});
    }
    }
   
    loadData=()=>{  
        return new Promise((resolve,reject)=>{
           
            if(this.loginState){
                let user_url = Commons.getURL("groups")+"/index.php";
                var options={
                    method:"POST",
                    body:JSON.stringify({auth:this.loginState,page:this.state.cpage}),
                    headers:{'Content-type':'application/json'}
                }
                fetch(user_url,options)
                .then(res=>res.json())
                .then(result=>{
                    if(this.hasMore.groups > 0){
                        let count = this.hasMore.groups % Commons.PAGE_LIMIT == 0 ? this.hasMore.groups.length / Commons.PAGE_LIMIT: (result.groups.length / Commons.PAGE_LIMIT + 1) ;
                        let pageCount = [];
                        for(let i=1; i<=count;i++) {
                            pageCount.push(i);
                        }
                        // this.setState({pageCount:pageCount},()=>{
                        //     this.prepareData(result)
                        // })
                    }
                    resolve(result);
                })
                .catch(e=>{
                    reject("Pole... kuna tatizo limetokea");
                })
                
            }
            
        })
    }
    handleSignout=(e)=>{
        e.preventDefault();
        window.localStorage.clear();
        window.sessionStorage.clear();
        window.location.href = "/";
    }
    
    updateGroups=(data)=>{
        this.setState({source:data});
        this.prepareData(data);
        this.handleComponentVisibility("groups");
    }
  
    handleComponentVisibility = (target=null)=>{
        switch(target){
            case null:
            case "groups":
                this.setState({showGroupsList:true,showGroupForm:false,groupImportForm:false,showGroupDetail:false,showGroupEditForm:false})
                break;
            case "group_form":
                this.setState({showGroupsList:false,showGroupForm:true,groupImportForm:false,showGroupDetail:false,showGroupEditForm:false})
               
                break;
            case "group_edit":
                this.setState({showGroupsList:false,showGroupForm:false,groupImportForm:false,showGroupDetail:false,showGroupEditForm:true})
               
                break;
            case "group_import":
                this.setState({showGroupsList:false,showGroupForm:false,groupImportForm:true,showGroupDetail:false,showGroupEditForm:false})
                break;
            case "group_detail":
                this.setState({showGroupsList:false,showGroupForm:false,groupImportForm:false,showGroupDetail:true,showGroupEditForm:false})
                break;
            default:
                this.setState({showGroupsList:true,showGroupForm:false,groupImportForm:false,showGroupDetail:false,showGroupEditForm:false})
            break;
        }
        
        
    }
    showDetails=(data)=>{
        this.setState({selectedGroup:data},()=>{
            this.handleComponentVisibility("group_detail");
        });
        
    }
    
    search=(searchKey)=>{
        if(searchKey.length > 0){
             var gps = [];
             this.state.source.forEach(gr=>{
                gr.forEach(g=>gps.push(g));
             });
             gps = gps.filter(m=>{
                 return m.jina.toLowerCase().includes(searchKey)||m.wilaya.toLowerCase().includes(searchKey)
             });
             this.setState({groups:gps})
        }
        else{
            this.setState({groups:this.state.source[this.state.page]});
        }
    }
   
    render(){
        return(
            this.loginState ?
            <div>
                <Header onSearch={this.search} auth={this.loginState}/>
                <div className="container-fluid">
                    <div className="row">
                    
                    <SideBar selected="vikundi" auth={this.loginState} />
                    <main className="col-md-10 ms-sm-auto col-lg-10 px-md-4 mx-auto my-4">
                        
                            {this.state.showGroupsList ?
                            <div className="col-lg-12 col-md-12 my-4 d-flex flex-row flex-wrap justify-content-between align-items-center">
                                <h3 className="col-lg-6 col-md-6 col-sm-12 my-4 text-left d-flex align-items-center justify-content-md-start justify-content-lg-start justify-content-sm-center">
                                    Taarifa za Vikundi</h3>
                                
                                <div className="col-lg-6 col-md-6 my-4 d-flex flex-row justify-content-end align-items-center"> 
                                    <span onClick={()=>this.handleComponentVisibility("group_form")} className="col-md-4 col-lg-4 mx-1 btn btn-secondary d-flex flex-row justify-content-center align-items-center"><span className="material-icons">add</span>&nbsp;Kikundi</span>
                                    <span onClick={()=>this.handleComponentVisibility("group_import")} className="col-md-4 col-lg-4 mx-1 btn btn-secondary d-flex flex-row justify-content-center align-items-center"><span className="material-icons">south_east</span>&nbsp;Ingiza</span>
                                </div>
                            </div>:null}
                                {
                                (this.state.showGroupEditForm ? <GroupEditForm onCancel={(t)=>this.handleComponentVisibility(t)} onSave={(gps)=>this.updateGroups(gps)} data={this.state.selectedGroup} id={this.state.selectedGroup.group_id}/>:(
                                (this.state.showGroupForm ? <RegisterGroupForm onCancel={(target)=>this.handleComponentVisibility(target)} onSave={(gps)=>this.updateGroups(gps)}/>:
                                (this.state.groupImportForm ? <GroupImport onCancel={(target)=>this.handleComponentVisibility(target)} onHifadhi={g=>this.updateGroups(g)} /> :(
                                this.state.showGroupDetail ? (
                                    <GroupView onEditClick={(tg)=>this.handleComponentVisibility(tg)} id={this.state.selectedGroup.group_id} data={this.state.selectedGroup} onCancel={(tar=>this.handleComponentVisibility(tar))}/>
                                ):
                                (<div className="text-right">
                                {this.state.pageCount.length >1 ?<><span>Ukurasa: </span>
                                {parseInt(this.state.cpage) > 0  ? <span className="material-icons btn" onClick={()=>this.goNextPage(0)}>skip_previous</span>:null}
                                    <select className="col-lg-2 col-md-2" onChange={(e)=>this.handlePageChange(e)} value={this.state.cpage} >
                                    {this.state.pageCount.map(p=>{
                                        return <option key={Commons.randomKey()} value={p-1}>{p}</option>
                                    })
                                    }</select>
                                    {parseInt(this.state.cpage) < this.state.pageCount.length -1 ? <span className="material-icons btn" onClick={()=>this.goNextPage(1)}>skip_next</span>:null}
                                    </>:null}
                
                                <ListHeadRow data={this.groupLabels}/>
                                {this.state.groups.length > 0 ?
                                this.state.groups.map((d,i)=>{
                                    return <GroupListItem key={Commons.randomKey()} position={i+1} data={d} id={d.group_id} onClick={()=>this.showDetails(d)}/>
                                }):<GroupListItem position={0} id={null} />
                                }
                            </div>)
                            ))
                                
                            )
                                )) 
                            }
                        </main>
                    </div>
                </div>
            </div>:
            <LoginForm  onAuth={(user=>this.handleAuth(user))} target={"/vikundi"}/>
        )
       
    }
    
}
export default Groups;