import React, { useEffect, useState } from "react";
import Commons from "../utils/commons";
const GroupView =(props)=>{
    
    const getSwahiliTime = (time)=>{
        var result = {saa:1,dkk:0,wkt:"Usiku"};
        if(time && time.length > 4){
            var h = time.split(":")[0];
            var m = time.split(":")[1];
            var am = time.split(" ")[1];
            var wkt = "Usiku";
            let saa = parseInt(h);
            if(h <= 6 ) saa += 6;
            else saa -= 6;
            if(h < 4 && am == "PM") wkt = "Usiku";
            else if(h >= 4 && h < 12 && am =="AM") wkt = "Asubuhi";
            else if((h ==12 || h <4 )&& am == "PM" ) wkt = "Mchana";
            else if(h >= 4 && h<7 && am == "PM") wkt = "Jioni";
            else if(h >= 7 && am == "PM") wkt = "Usiku";
            result.saa = saa;
            result.dkk = parseInt(m);
            result.wkt = wkt;
        }
        return result;
    }
    var detail = props.data;
    detail.muda = getSwahiliTime(detail.muda_wa_mikutano);
    console.log("muda: ",detail);
    const regionData = Commons.getRegionalData();
    detail.zone = regionData[detail.kanda];
    if(detail.mkoa == "hakuna" || detail.mkoa == undefined){
        let mkoa = detail.zone.regions.filter(r=>{
            return r.districts.map(d=>d.name.toLowerCase()).includes(detail.wilaya.toLowerCase())
        })[0];
        detail.mkoa = mkoa ? mkoa.name:"";
    }
    else{
        let mkoa = detail.zone.regions.filter(r=>r.key == detail.mkoa.toLowerCase().replaceAll(" ","_") || r.name == detail.mkoa.toLowerCase());
        if(mkoa.length > 0) detail.mkoa = mkoa[0].name;
      
    }
    const handleCancelForm = (target)=>{
        props.onCancel(target);
    }
  
    return(
        <form className="border border-secondary bg-light my-4 col-md-10 col-lg-10 col-sm-10 offset-md-1 mx-auto offset-lg-1 offset-sm-1 row">
        <div className="text-medium">Taarifa za Kikundi</div>
        <div className="col-sm-10 col-md-10 col-lg-10 text-white bg-secondary bg-gradient offset-1 mx-auto mt-2 mb-0 ">1. Mahali</div>
            
        <fieldset className="col-md-10 col-lg-10 offset-1 mx-auto border m-2 mt-0 row">
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-zone">Kanda</label>
                <input type="text" className="form-control" id="group-zone" name="group-zone" defaultValue={detail.zone.name} readOnly={true}/>
                
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-region">Mkoa</label>
                <input className="form-control" id="group-region" name="group-region" defaultValue={detail.mkoa} readOnly={true}/>
                   
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-district">Wilaya</label>
                <input readOnly={true} className="form-control" id="group-district" name="group-district" defaultValue={detail.wilaya.toUpperCase()}/>
                
            </div>
            
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-kata">Kata/Wadi</label>
                <input readOnly={true} className="form-control" type="text" id="group-kata" name="group-kata" defaultValue={detail.kata}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-mtaa">Mtaa/Kijiji/Shehia</label>
                <input readOnly={true} className="form-control" type="text" id="group-mtaa" name="group-mtaa" defaultValue={detail.mtaa}/>
            </div>
            
        </fieldset>
        <div className="col-sm-10 col-md-10 col-lg-10 text-white bg-secondary bg-gradient offset-1 mx-auto mt-2 mb-0 ">2. Uthibitisho</div>
            
            <fieldset className="col-md-10 col-lg-10 offset-1 mx-auto border m-2 mt-0 row">
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-name">Jina la Kikundi</label>
                <input readOnly={true} className="form-control" type="text" id="group-name" name="group-name" defaultValue={detail.jina}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-type">Aina ya Ushirika</label>
                <input  readOnly={true} className="form-control" type="text" id="group-type" name="group-type" defaultValue={detail.aina_ya_ushirika} disabled={true}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-registration">Aina ya Usajili</label>
                <input readOnly={true} disabled={true} className="form-control" type="text" id="group-registration" name="group-registration" defaultValue={detail.aina_ya_usajili}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-regnumber">Namba ya Usajili</label>
                <input readOnly={true} className="form-control" type="text" id="group-regnumber" name="group-regnumber" defaultValue={detail.namba_ya_usajili}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="">Kikundi kina katiba/Mwongozo?</label>
                <div className="col-lg-10 col-md-10 col-sm-12 row">
                    <div className="d-flex flex-row align-items-center col-lg-6 col-md-6 col-sm-6">
                        <input readOnly={true} className="mx-2" type="radio" readOnly={true} id="group-katiba-yes" name="group-katiba" checked={detail.katiba_ya_chama == 1} disabled={true}/>
                        <label htmlFor="group-katiba-yes">Ndiyo</label>
                    </div>
                    <div className="d-fleex flex-row align-items-center col-lg-6 col-md-6 col-sm-6">
                        <input readOnly={true} className="mx-2" type="radio" readOnly={true}  id="group-katiba-no" name="group-katiba"  checked={detail.katiba_ya_chama == 0 || detail.katiba_ya_chama == null} disabled={true}/>
                        <label htmlFor="group-katiba-no">Hapana</label>
                    </div>
                </div>
                
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-regyear">Mwaka wa Usajili</label>
                <input type="number" id="group-regyear" name="group-regyear" className="form-control" defaultValue={detail.mwaka_wa_usajili} disabled={true}/>
             </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-12 col-lg-12">
                <label htmlFor="group-kazi">Kazi za Msingi</label>
                <textarea  readOnly={true} className="form-control" rows="5" id="group-kazi" name="group-kazi" disabled={true} defaultValue={detail.kazi_za_msingi}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-business">Aina ya Biashara</label>
                <input className="form-control" type="text" id="group-business" name="group-business" defaultValue={detail.aina_ya_biashara} readOnly={true} disabled={true}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-idadi">Idadi ya Wanachama</label>
                <input readOnly={true} className="form-control" type="text" id="group-idadi" name="group-idadi" defaultValue={detail.idadi_ya_wanachama} disabled={true}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-ukaguzi">Tarehe ya Ukaguzi wa Mwisho</label>
                <input className="form-control" type="text" id="group-ukaguzi" name="group-ukaguzi" defaultValue={detail.tarehe_ukaguzi ? detail.tarehe_ukaguzi.split(" ")[0]:""} readOnly={true} disabled={true}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
               
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-benki">Namba ya Akaunti ya Benki</label>
                <input className="form-control" type="text" id="group-benki" name="group-benki" defaultValue={detail.akaunti_benki} readOnly={true} disabled={true} />
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-balance">Pesa Iliopo Benki</label>
                <input className="form-control" type="number" id="group-balance" name="group-balance" defaultValue={detail.pesa_benki} readOnly={true} disabled={true} />
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="">Kikundi kina mkopo benki?</label>
                <div className="col-lg-10 col-md-10 col-sm-12 row">
                    <div className="d-flex flex-row align-items-center col-lg-6 col-md-6 col-sm-6">
                        <input className="mx-2" type="radio" readOnly={true} id="group-mkopo-yes" name="group-mkopo" checked={detail.mkopo ==1} disabled={true} />
                        <label htmlFor="group-mkopo-yes">Ndiyo</label>
                    </div>
                    <div className="d-fleex flex-row align-items-center col-lg-6 col-md-6 col-sm-6">
                        <input className="mx-2" type="radio" readOnly={true}  id="group-mkopo-no" name="group-mkopo" checked={detail.mkopo ==0} disabled={true} />
                        <label htmlFor="group-mkopo-no">Hapana</label>
                    </div>
                </div>
                
            </div>
            {
                detail.mkopo == 1 ? 
                <>
                    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                        <label htmlFor="group-mkopo-amount">Kiasi cha Mkopo</label>
                        <input readOnly={true} className="form-control" type="number" id="group-mkopo-amount" name="group-mkopo-amount" defaultValue={detail.mkopo_kiasi} disabled={true}/>
                    </div>
                    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                        <label htmlFor="group-mkopo-mwisho">Mkopo unaisha lini?</label>
                        <input readOnly={true} className="form-control" type="text" id="group-mkopo-mwisho" name="group-mkopo-mwisho" defaultValue={detail.mkopo_tarehe.split(" ")[0]} disabled={true}/>
                    </div>
                    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                        <label htmlFor="group-lender">Benki/Taasisi Iliyokopesha</label>
                        <input readOnly={true} className="form-control" type="text" id="group-lender" name="group-lender" defaultValue={detail.mkopo_benki} disabled={true}/>
                    </div>
                </>:null
            }
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-hisa">Idadi ya Hisa za Kikundi</label>
                <input readOnly={true} disabled={true} className="form-control" type="number" id="group-hisa" name="group-hisa" defaultValue={detail.idadi_ya_hisa}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-hisa-value">Thamani ya kila hisa</label>
                <input readOnly={true} className="form-control" type="number" id="group-hisa-value" name="group-hisa-value" defaultValue={detail.thamani_ya_hisa} disabled={true}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-uchaguzi">Tarehe ya Uchaguzi wa Viongozi</label>
                <input readOnly={true} className="form-control" type="text" id="group-uchaguzi" name="group-uchaguzi" defaultValue={detail.tarehe_ukaguzi ? detail.tarehe_ukaguzi.split(" ")[0]:""} disabled={true}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-agm">Tarehe ya Mwisho ya Mkutano Mkuu</label>
                <input readOnly={true} className="form-control" type="text" id="group-agm" name="group-agm" defaultValue={detail.tarehe_mkutano ? detail.tarehe_mkutano.split(" ")[0]:""} disabled={true}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-meeting-day">Siku ya Mikutano</label>
                <input className="form-control" id="group-meeting-day" name="group-meeting-day" defaultValue={detail.siku_ya_mikutano} readOnly={true} disabled={true}/>
                
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-meeting-time">Muda wa Mikutano</label>
                <input readOnly={true} className="form-control" type="text" id="group-meeting-time" name="group-meeting-time" defaultValue={detail.muda.saa+":"+detail.muda.dkk+":"+detail.muda.wkt} disabled={true}/>
            </div>
        </fieldset>
        <div className="col-sm-10 col-md-10 col-lg-10 text-white bg-secondary bg-gradient offset-1 mx-auto mt-2 mb-0 ">3. Mengineyo</div>
            
        <fieldset className="col-md-10 col-lg-10 offset-1 mx-auto border m-2 mt-0 row">
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-12 col-lg-12">
                <label htmlFor="group-changamoto">Maoni ya Kikundi</label>
                <textarea readOnly={true} className="form-control" rows="5" id="group-changamoto" name="group-changamoto" defaultValue={detail.changamoto} disabled={true}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-chairperson">Jina la Mwenyekiti</label>
                <input readOnly={true} className="form-control" id="group-chairperson" name="group-chairperson" defaultValue={detail.mwenyekiti} disabled={true}/>
                 
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-katibu">Jina la Katibu</label>
                <input readOnly={true} className="form-control" id="group-katibu" name="group-katibu" defaultValue={detail.katibu} disabled={true}/>
                 
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-phone">Simu ya Mwenyekiti</label>
                <input readOnly={true} className="form-control" type="number" id="group-phone" name="group-phone" defaultValue={detail.simu_mwenyekiti} disabled={true}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-phone2">Simu ya Katibu</label>
                <input readOnly={true} className="form-control" type="number" id="group-phone2" name="group-phone2" defaultValue={detail.simu_katibu} disabled={true}/>
            </div>
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
            <label htmlFor="group-cbt-name">Jina la Mwalimu CBT</label>
                <input readOnly={true} className="form-control" type="text" id="group-cbt-name" name="group-cbt-name" defaultValue={detail.mwalimu_cbt} disabled={true}/>
           
            </div>   
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
            <label htmlFor="group-cbt-jinsia">Jinsi</label>
                <input readOnly={true} className="form-control" id="group-cbt-jinsia" name="group-cbt-jinsia" defaultValue={detail.jinsia_cbt == 0 ? "Ke":"Me"} disabled={true}/>
                
            </div> 
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-cbt-phone">Namba ya Simu</label>
                <input readOnly={true} className="form-control" type="number" id="group-cbt-phone" name="group-cbt-phone" defaultValue={detail.simu_cbt} disabled={true}/>
           
            </div> 
            <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                <label htmlFor="group-cbt-location">Anuani</label>
                <input readOnly={true} className="form-control" type="text" id="group-cbt-location" name="group-cbt-location" defaultValue={detail.anuani_cbt} disabled={true}/>
           
            </div> 
                    
            <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row">
                <input onClick={()=>handleCancelForm("group_edit")} className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" type="button" defaultValue="HARIRI"/>
                <input onClick={()=>handleCancelForm(null)} className="col-md-3 col-lg-2 col-sm-6 btn text-primary" type="button" value="GHAIRI"/>
            </div>
        </fieldset>
    </form>
          
    )
}
export default GroupView;