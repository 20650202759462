import React, { useEffect, useState } from "react";
import Commons from "../utils/commons";
const MemberView =(props)=>{
    
    var detail = props.data;
    const regionData = Commons.getRegionalData();
    detail.zone = regionData[detail.kanda];
      
    if(detail.mkoa != "hakuna" && detail.mkoa !== null && detail.mkoa !== undefined){
        let reg = detail.zone.regions.find(r=>r.key == detail.mkoa.toLowerCase().replaceAll(" ","_"));
        if(reg) detail.mkoa = reg.name.replaceAll("_",' ').toUpperCase();
    }
    else{
        var mkoa = detail.zone.regions.find(r=>{
            return r.districts.map(d=>d.name.toLowerCase()).includes(detail.wilaya.toLowerCase().replaceAll("_",' '))
        });
        detail.mkoa = (mkoa) ? mkoa.name.replaceAll("_",' ').toUpperCase():"hakuna";
    }
    detail.jinsia = (parseInt(detail.jinsia) === 0) ? "Ke":"Me";
    const handleCancelForm = (target)=>{
        props.onCancel(target);
    }
   const handleEditButton=()=>{
       props.onEditClick("member_edit");
   }
    return(
        <form className="border border-secondary bg-light p-3 my-5 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
                <div className="text-medium py-2">Taarifa za Mwanachama</div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="group-name">Chagua Kikundi</label>
                    <input disabled={true} className="form-control" id="group-name" name="group-name" value={detail.kikundi.jina} readOnly={true}/>
                       
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="member-position">Nafasi</label>
                    <input disabled={true} value={detail.nafasi} className="form-control" id="member-position" name="member-position" readOnly={true}/>
                        
                
                </div>
                <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="member-name">Majina Kamili</label>
                    <input disabled={true} value={detail.jina} className="form-control" type="text" id="member-name" name="member-name" readOnly={true} />
                </div>
                <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="member-dob">Mwaka wa Kuzaliwa</label>
                    <input disabled={true} value={detail.mwaka} required={true} className="form-control" id="member-dob" name="member-dob" readOnly={true}/>
                        
                </div>
                <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
                    <label htmlFor="member-gender">Jinsi</label>
                    <input disabled={true} value={detail.jinsia} required={true} className="form-control" id="member-gender" name="member-gender" readOnly={true}/>
                     
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-region">Mkoa Anapoishi</label>
                    <input disabled={true} value={detail.mkoa.replaceAll("_"," ").toUpperCase()} className="form-control" id="member-region" name="member-region" readOnly={true} />
                    
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-district">Wilaya</label>
                    <input disabled={true} value={detail.wilaya.replaceAll("_"," ").toUpperCase()} required={true} className="form-control" id="member-district" name="member-district" readOnly={true}/>
                   
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-ward">Kata/Wadi</label>
                    <input disabled={true}  value={detail.kata} className="form-control" type="text" id="member-ward" name="member-ward" readOnly={true}/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-street">Mtaa/Kijiji</label>
                    <input disabled={true}  value={detail.mtaa} className="form-control" type="text" id="member-street" name="member-street" readOnly={true}/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-phone">Namba ya Simu</label>
                    <input disabled={true}  value={detail.simu} className="form-control" id="member-phone" name="member-phone" readOnly={true}/>
                </div>
                
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-occupation">Kazi/Shughuli ya Msingi</label>
                    <input disabled={true}  value={detail.kazi} className="form-control" id="member-occupation" name="member-occupation" readOnly={true}/>
                      
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-loan-count">Idadi ya Mikopo</label>
                    <input disabled={true}  value={detail.idadi_ya_mikopo} className="form-control" id="member-loan-count" name="member-loan-count" readOnly={true}/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-loan">Mkopo</label>
                    <input disabled={true}  value={detail.mkopo} className="form-control" id="member-loan" name="member-loan" readOnly={true}/>
                      
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-loan-rejesho">Marejesho</label>
                    <input disabled={true}  value={detail.marejesho} className="form-control" id="member-loan-rejesho" name="member-loan-rejesho"  readOnly={true}/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-hisa">Idadi ya Hisa</label>
                    <input disabled={true}  value={detail.hisa} className="form-control" id="member-hisa" name="member-hisa" readOnly={true}/>
                </div>
                <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
                    <label htmlFor="member-hisa-value">Thamani ya Hisa</label>
                    <input disabled={true} value={detail.thamani_ya_hisa} className="form-control" id="member-hisa-value" name="member-hisa-value" readOnly={true} />
                </div>     
                <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row">
                    <input onClick={handleEditButton} className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" type="button" value="HARIRI"/>
                    <input onClick={()=>handleCancelForm("groups")} className="text-primary col-md-3 col-lg-2 col-sm-6 btn" type="button" value="GHAIRI"/>
                </div>
            </form>
               
    )
}
export default MemberView;