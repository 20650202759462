
import { thisExpression } from '@babel/types';
import React from 'react';
import logo from "../logo.png";
import Error from './error_fallback';
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.error(error, errorInfo);
    }
  
    render() {
      if(this.state.hasError){
        return (<Error/>)
            
      }
      return this.props.children
    }
  }
  
  export default ErrorBoundary;