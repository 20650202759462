
import './App.css';
import React from 'react';
import CustomRouter from './components/router';
import ErrorBoundary from './components/error';
import PrintProvider,{Print,NoPrint} from 'react-easy-print';

class App extends React.Component {
  constructor(props){
    super(props);
   
  }
  
  render(){
   
    return (
      <div className="App">

          <ErrorBoundary >
            <CustomRouter />
          </ErrorBoundary>
       
      </div>
    );
  }
  
}

export default App;
