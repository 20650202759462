import React, { useEffect,useState } from "react";
import commons from "../utils/commons";
import passwordValidator from "password-validator";
import Spinner from "./spinner";
import AlertDialog from "./AlertDialog";

const UserEditForm=(props)=>{
    var storage = window.localStorage;
    var session = window.sessionStorage;
    var storedData = JSON.parse(storage.getItem("data"));
    var loginState = JSON.parse(session.getItem("loginState"));
    const regionData = commons.getRegionalData();
    const [newPwd,setNewPwd] = useState("");
    const [pwdChanged,setPwdChanged] = useState(false);
    const [pwdError,setPwdError] = useState(null);
    const [hasError,setHasError] = useState(false);
    const [errorMsg,setErrorMsg] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [showDialog,setShowDialog] = useState(false);
    const [showRestoreDialog,setShowRestoreDialog] = useState(false);
    const [feedback,setFeedback]= useState({status:false,msg:""});
    const [validPhone,setValidPhone] = useState()
    var user = props.user;
    const [zone,setZone] = useState(parseInt(user.kanda));
    var wilayas = regionData[zone].regions.filter(r=>r.key == user.mkoa)[0].districts;
    var passwordScheme = new passwordValidator();
    passwordScheme
    .is().min(8)
    .is().max(16)
    .has().uppercase()
    .has().lowercase()
    .has().digits()
    .has().symbols();
    const handleSubmit=(e)=>{
        e.preventDefault();
        setIsLoading(true);
        var inputs = Array.from(e.target.elements);
        var fd = {};
        fd.user_id = user.user_id;
        fd.ngazi = inputs[0].value;
        fd.kanda = inputs[1].value;
        fd.mkoa = inputs[2].value;
        fd.wilaya = inputs[3].value;
        fd.jina = inputs[4].value.trim();
        fd.email = inputs[5].value.trim();
        fd.simu = inputs[6].value.trim();
        fd.password = pwdChanged ? newPwd : undefined;
        
        
        console.log("fd1: ",fd);
        saveUser(fd).then(result=>{
            setIsLoading(false);
            console.log("result: ",result);
            if(result.status){
                let ids = storedData.users.map(u=>u.user_id);
                let users = storedData.users;
                users[ids.indexOf(user.user_id)] = fd;
                storedData.users = users;
                storage.setItem("data",JSON.stringify(storedData));
                storedData = JSON.parse(storage.getItem("data"));
                props.onSave(storedData.users);
                handleCancelForm("users")
            }
            else{
                setHasError(!result.status);
                setErrorMsg(result.message);
            }
            
        }).catch(e=>{
            setIsLoading(false);
            setHasError(true);
            setErrorMsg(e.toString());
        })

        
    }
    const handlePhoneChange = (e)=>{
        let phone = e.target.value;
        if(phone.length > 0){
            let valid = commons.validatePhone(phone);
            setValidPhone(valid);
        }
        
    }
    const saveUser=(user)=>{
        return new Promise((resolve,reject)=>{
            var url = commons.getURL("users")+"/update.php";
            var options = {
                body:JSON.stringify({user:user,auth:loginState,source:"users"}),
                method:"POST",
                headers:{'Content-type':'application/json'}
            }

            fetch(url,options)
            .then(res=>res.json())
            .then(result=>{
                resolve(result);
            })
            .catch(e=>{
                console.log("err: ",e);
                reject("Pole! Haikuwezekana kuhifadhi taarifa mpya za mtumiaji");
            });
        });
        
    }
    const handleCancelForm=(tag)=>{
        props.onCancel(tag);
    }
    const handleZoneChange = (e)=>{

        let zId = parseInt(e.target.value);
        let zone = regionData[zId];
        const regionEl = document.getElementById("user-region");
        Array.from(regionEl.children).forEach((c,i)=>{
            if(i>0) regionEl.removeChild(c);
        });
        zone.regions.forEach(r=>{
            let reg = r.name;
            regionEl.options.add(new Option(reg,r.key));
        })
        setZone(zId);
    }
    const populateWilaya = (region)=>{
        const distEl = document.getElementById("user-district");
       Array.from(distEl.children).forEach((c,i)=>{if(i>0)distEl.removeChild(c);});
       if(region != 0){
        region = (region.includes(" ")) ? region.toLowerCase().replaceAll(" ","_"):region.toLowerCase();
        console.log("region: "+region,regionData[zone].regions.filter(r=>r.key == region));//.filter(r=>r.key == region));
        let districts = regionData[zone].regions.filter(r=>r.key == region)[0].districts;
        districts.forEach(d=>{
            distEl.options.add(new Option(d.name));
        });
       }
       
    }
    const checkPassword=(e)=>{
        let pwd = e.target.value;
        if(pwd.length >0){
            if(passwordScheme.validate(pwd)){
                setNewPwd(pwd);
                setPwdChanged(true);
                setPwdError(null);
            }
            else{
                setPwdError("Neno la siri halikidhi viwango");
            }
        }
        else{
            setNewPwd("");
            setPwdChanged(false);
            setPwdError(null);
        }
    }
    const handleRegionChange=(e)=>{
        let region = e.target.value.toLowerCase();
        const distEl = document.getElementById("user-district");
        Array.from(distEl.children).forEach(c=>distEl.removeChild(c));
        if(region != 0) populateWilaya(region);
    }
    const restoreUser=()=>{
        let url = commons.getURL("users")+"/restore.php";
        let options = {
            method:"POST",
            body:JSON.stringify({user:{user_id:user.user_id},auth:loginState}),
            headers:{
                'Content-type':'application/json'
            }
        }
        let fb = {};
        fetch(url,options)
        .then(res=>res.json())
        .then(result=>{
            console.log("result: ",result);
            fb.status=result.status;
            fb.msg=result.message;
            if(result.status){
                storedData.users = result.users;
                storage.setItem("data",JSON.stringify(storedData));
                storedData = JSON.parse(storage.getItem("data"));
                props.onSave(storedData.users);   
                handleCancelForm("users");
            }
            
        })
        .catch(e=>{
            fb.status = false;
            fb.msg="Pole! Kuna tatizo limetokea";
        }).finally(()=>{
            setFeedback(fb);
        });
    }
    const deleteUser=()=>{
        let url = commons.getURL("users")+"/delete.php";
        let options = {
            method:"POST",
            body:JSON.stringify({user:{user_id:user.user_id},auth:loginState}),
            headers:{
                'Content-type':'application/json'
            }
        }
        let fb = {};
        fetch(url,options)
        .then(res=>res.json())
        .then(result=>{
            console.log("result: ",result);
            fb.status=result.status;
            fb.msg=result.message;
            if(result.status){
                storedData.users = result.users;
                storage.setItem("data",JSON.stringify(storedData));
                storedData = JSON.parse(storage.getItem("data"));
                props.onSave(storedData.users);   
                handleCancelForm("users")
            }
            
        })
        .catch(e=>{
            fb.status = false;
            fb.msg="Pole! Kuna tatizo limetokea";
        }).finally(()=>{
            setFeedback(fb);
        })
    }
    const toggleVisibility=(e)=>{
        if(e.target.id == "tg1"){
            if(e.target.textContent == "visibility"){
                e.target.textContent = "visibility_off";
                document.querySelector("#user-password").type = "text";
            }
            else{
                e.target.textContent = "visibility";
                document.querySelector("#user-password").type = "password";
            }
        }
        else{
            if(e.target.textContent == "visibility"){
                e.target.textContent = "visibility_off";
                document.querySelector("#settings-password1").type = "text";
            }
            else{
                e.target.textContent = "visibility";
                document.querySelector("#settings-password1").type = "password";
            }
        }
    }
    useEffect(()=>{
        setValidPhone(user.simu);
    },[]);
    return(<>{feedback.msg.length > 0 ? <div className={"col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 my-2 "+(!feedback.status ? "alert-danger" : "alert-success")}>{feedback.msg}</div> : null}
        <form encType="multipart/form-data" onSubmit={handleSubmit} className="border border-secondary bg-light p-3 my-2 col-md-8 col-lg-8 col-sm-10 offset-md-2 offset-lg-2 offset-sm-1 row">
    <div className="text-medium py-2">Hariri Taarifa za Mtumiaji</div>
    {errorMsg.length > 0 ? <div className={"my-2 "+(hasError ? "alert-danger" : "alert-success")}>{errorMsg}</div>:null}
    
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="user-position">Ngazi ya Utawala</label>
        <select defaultValue={user.ngazi} className="form-control" id="user-position" name="user-position">
            {storedData.roles.map(r=>{
                return <option key={r.role_id} value={r.level}>{r.role_name}</option>
            })}
        </select>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="user-zone">Kanda</label>
        <select onChange={handleZoneChange} defaultValue={user.kanda} className="form-control" id="user-zone" name="user-zone" >
            <option value="0">Bara</option>
            <option value="1">Zanzibar</option>
        </select>
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="user-region">Mkoa</label>
        <select onChange={handleRegionChange} defaultValue={user.mkoa} className="form-control" id="user-region" name="user-region" >
            <option value="0">--chagua mkoa--</option>
        {regionData[parseInt(user.kanda)].regions.map(city=>{
                return(<option key={city.key} value={city.key}>{city.name}</option>)
            })}
        </select>
    </div>
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="user-district">Wilaya</label>
        <select defaultValue={user.wilaya} className="form-control" id="user-district" name="user-district">
        <option value="0">--chagua wilaya--</option>
        {wilayas.map(city=>{
                return(<option key={city.name} value={city.name}>{city.name}</option>)
            })}
        </select>
    </div>
    <div className="my-2 d-flex flex-column align-items-start justify-content-start col-md-6 col-lg-6">
        <label htmlFor="user-name">Majina Kamili</label>
        <input defaultValue={user.jina} className="form-control" type="text" id="user-name" name="user-name" placeholder="Majina ya mtumiaji"/>
    </div>
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="user-email">Barua-pepe</label>
        <input  readOnly={true} disabled={true} value={user.email} className="form-control" type="email" id="user-email" name="user-email" placeholder="Barua pepe"/>
    </div>
    
   
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="user-phone">Namba ya Simu</label>
        <input onInput={handlePhoneChange} defaultValue={user.simu} className={"form-control "+(validPhone ? "border border-success" : "border border-danger")} type="number" id="user-phone" name="user-phone" placeholder="Na. ya simu ya mtumiaji"/>
        {validPhone ? null :<small className="text-danger">Namba ya simu si sahihi</small>}
    </div>
    <div className="my-2 form-group d-flex flex-column align-items-start justify-content-start col-lg-6 col-md-6">
        <label htmlFor="user-password">Neno la siri</label>
        <div className="form-control d-flex flex-row flex-justify-content-start flex-align-items-center">
            <input onChange={(event)=>checkPassword(event)} defaultValue={user.password} className={"form-control border border-white"+(pwdError == null ? "" :"border border-danger")} type="password" id="user-password" name="user-password" placeholder="Neno la siri"/>
            <span id="tg1" onClick={toggleVisibility} className="material-icons btn text-success">visibility</span>
        </div>
        {pwdError ? <small className="text-left font-italic text-danger">Urefu usipungue 8, Angalau herufi 1 kubwa, 1 ndogo, tarakimu 1 na alama 1</small>:null}
                               
    </div>
    
    <div className="my-2 form-group d-flex align-items-center justify-content-between flex-row">
        {pwdError == null ? (isLoading ? <Spinner isShowing={true} /> : <input disabled={pwdError != null || !validPhone} className="col-md-3 col-lg-2 col-sm-6 btn bg-primary text-white" type="submit" value="HIFADHI"/>):null}
       {hasError ? null:(user.is_deleted == 0 ? <input className="col-md-3 col-lg-2 col-sm-6 btn bg-danger text-white" type="button" value="ONDOA" onClick={()=>setShowDialog(true)}/>:<input className="col-md-3 col-lg-2 col-sm-6 btn bg-success text-white" type="button" value="REJESHA" onClick={()=>setShowRestoreDialog(true)}/>) }
        <input onClick={()=>handleCancelForm("users")} className="text-primary col-md-3 col-lg-2 col-sm-6 btn" type="button" value="GHAIRI"/>
    </div>
</form>
        {showDialog ?  <AlertDialog  onCancel={()=>setShowDialog(false)} onConfirm={deleteUser} open={showDialog} positive="ONDOA" negative="SITISHA" title="Ondoa Mtumiaji" description={"Mtumiaji "+user.jina+" hataweza tena kuingia na kutumia mfumo huu"}/>:null}

        {showRestoreDialog ?  <AlertDialog  onCancel={()=>setShowRestoreDialog(false)} onConfirm={restoreUser} open={showRestoreDialog} positive="REJESHA" negative="SITISHA" title="Rejesha Mtumiaji" description={"Mtumiaji "+user.jina+" ataweza tena kuingia na kutumia mfumo huu"}/>:null}
        
</>);
}
export default UserEditForm;