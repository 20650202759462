
import React from "react";
import Commons from "../utils/commons";

class UserItem extends React.Component{
    constructor(props){
        super(props);
        this.storage = window.localStorage;
        this.storedData = JSON.parse(this.storage.getItem("data"));
        this.handleClick = this.handleClick.bind(this);
        this.userLabels = Commons.getUserListLabels();
        this.state={user:props.user};
        this.width = "col-lg-2 col-md-2";
        if(this.userLabels.length > 6) this.width = "col-lg-1 col-md-1";
        if(this.userLabels.length == 4) this.width = "col-lg-3 col-md-3";
        if(this.userLabels.length == 3) this.width = "col-lg-4 col-md-4";
        this.align = "text-left";
    }

    handleClick=()=>{
        this.props.onClick(this.state.user);
    }
    componentDidMount(){
        if(this.state.user){
            let u = this.state.user;
            let myrole = this.storedData.roles.filter(r=>parseInt(r.level) === parseInt(this.state.user.ngazi));
           
            if(myrole.length > 0){
                u.level = myrole[0].role_name;
                this.setState({user:u});
            }
        }
    }
    render(){
        return(
            this.state.user ? (
                <div onClick={this.handleClick} className={"mx-auto row col-md-12 col-lg-12 d-flex flex-row justify-content-start align-items-center text-left list-item "+((this.state.user.is_deleted == 1) ? " text-danger" : (this.props.position%2==0 ? " list-item-shade":"bg-light"))}>
                
                {this.userLabels.map((d,i)=>{
                    if(d.key == "ngazi") return (<div key={Commons.randomKey()} className={this.width+ " "+this.align }>{this.state.user['level']}</div>)
                    if(d.key == "email") return(<div key={Commons.randomKey()} className={this.width+ " "+this.align }>{this.state.user[d.key]}</div>)
                    if(d.key == "mkoa") return(<div key={Commons.randomKey()} className={this.width+ " "+this.align }>{this.state.user[d.key].replaceAll("_"," ").toUpperCase()}</div>)
                    else return(<div key={Commons.randomKey()} className={"col-lg-2 col-md-2 text-left" }>{this.state.user[d.key]}</div>)
                
                })}
                </div>)
                :(
                    <div className="text-center col-lg-12 col-md-12">Hakuna taarifa</div>
                )
                
            
        )
    }
}
export default UserItem;